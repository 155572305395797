<template>
     <section class="main-content">
        <div class="cms-content">
            <div class="container">
                <div class="row justify-content-center">
                    <!-- smartwizard -->
                    <bank-account-wizard :wizardTimeLines="wizardTimeLines"/>

                    <div class="col-xl-8 col-lg-10 col-md-12">
                        <ul class="wizard-mobile list-unstyled">
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLines" :key="'active_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link" :class="{'filled':timeline.filledClass,'active':timeline.activeClass}">
                                    <span>{{ timeline.name }}</span>
                                    <i :class="timeline.classList"></i>
                                </router-link>
                            </li>
                            <li class="main-form-area">
                                <ValidationObserver v-slot="{ passes }">
                                    <form class="declaration" @submit.prevent="passes(declarationsSubmit)" autocomplete="off">
                                        <div class="card card-top-line">
                                            <div class="card-body">
                                                <h2 class="page-title">Declaration</h2>
                                                <p>I/We hereby apply for the opening of account(s) with <strong>{{selectedBankName}}</strong>. I understand that the information given here in, and the documents supplied are the basis for opening such account(s) and I/We therefore warrant that such information is correct.</p>
                                                <p>I/We further undertake to indemnify the Bank for any loss suffered as a result of any false information or error in the information provided to the Bank.</p>
                                                <p>By signing below, I/We agree that I/We have read, understood and accept to be bound by the 
                                                    <ul>
                                                        <li><a href="javascript:void(0)" @click="termsCondition()"><strong>Terms and Conditions of the Account</strong></a></li>
                                                        <li><a href="javascript:void(0)" @click="termsCondition()"><strong>Terms and Conditions of MasterCard</strong></a></li>
                                                    </ul>
                                                </p>
                                                <p>which I/We find reasonable, fair and necessary to acquire the service(s).</p>
                                                <div class="d-flex justify-content-between align-items-end declaration-end">
                                                    <div class="chek-group pr-2">
                                                        <div class="form-group mb-0">
                                                            <label class="fancy-checkbox new-fancy animate-error">
                                                                <input type="checkbox" @click="confirmBoxChecked($event)" :checked="declarations.confirmChecked">
                                                                <span :class="{'text-danger' : declarations.errorMsg && !declarations.confirmChecked}">Please check this box to confirm that you have read and understand the declaration</span>
                                                            </label>
                                                        </div>
                                                        <div class="form-group mb-0">
                                                            <label class="fancy-checkbox new-fancy animate-error">
                                                                <input type="checkbox" @click="agreementBoxChecked($event)" :checked="declarations.agreement">
                                                                <span :class="{'text-danger' : declarations.errorMsg && !declarations.agreement}">Please check this box to confirm your agreement to our <strong><a :class="{'text-danger' : declarations.errorMsg && !declarations.agreement, 'font-color':!(declarations.errorMsg && !declarations.agreement)}" href="/terms-and-conditions" target="_blank">Terms and Conditions</a></strong></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="input-end flex-grow-1">
                                                        <ValidationProvider name="full_name" rules="required" v-slot="{ errors}">
                                                            <div class="form-group mb-0" :class="{'errors': errors.length && !declarations.full_name,'success':declarations.full_name}">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="declarations.full_name" class="form-control" id="full_name" placeholder="Sign application by entering your full name*" @input="capitalizeFirstLetter($event,'full_name')">
                                                                    <label for="full_name" class="control-label">Sign application by entering your full name*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !declarations.full_name"></i>
                                                                    <i class="fa fa-check" v-if="declarations.full_name"></i>
                                                                </span>
                                                                <span class="text-danger error-msg" v-if="fieldName == 'full_name'">{{fieldErrorMsg}}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-end flex-column flex-sm-row mt-5">
                                                    <router-link to="/open-bank-account/summary" class="btn btn-outline-danger btn-mw mr-sm-1 mr-0 my-sm-0 my-2 btn-lg" v-if="!btnDesabled">Back</router-link>
                                                    <b-button class="btn-mw" type="submit" variant="primary" size="lg" :disabled="btnDesabled">Apply</b-button>
                                                </div>
                                            </div>  
                                        </div>    
                                    </form>
                                </ValidationObserver>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- logo footer -->
                <bank-account-logo-footer/>
                <!-- Expiry Token Modal -->
                <expiry-token-modal></expiry-token-modal>
                <!-- Terms condition -->
                <terms-condition></terms-condition>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </section>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const BankAccountLogoFooter = require('@/components/admin/open_bank_account_v1/BankAccountLogoFooter.vue').default
const BankAccountFormWizard = require('@/components/admin/open_bank_account_v2/BankAccountFormWizard.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
const ExpiryTokenModal = require('@/components/admin/open_bank_modal/ExpiryTokenModal.vue').default
import '@/plugins/sweetalert2'
const TermsConditionModal = require('@/components/admin/commonmodal/TermsConditionModal.vue').default

export default {
    name:'DeclarationsV2Component',
    components: {
        ValidationObserver,
        ValidationProvider,
        'bank-account-logo-footer':BankAccountLogoFooter,
        'bank-account-wizard':BankAccountFormWizard,
        'main-loader':LoaderComponent,
        'expiry-token-modal':ExpiryTokenModal,
        'terms-condition': TermsConditionModal
    },
    data(){
        return{
            declarations:{
                confirmChecked: false,
                agreement: false,
                full_name:'',
                errorMsg:false,
            },
            btnDesabled:false,
            timeoutModalShow:false,
            
            selectedBankName:'',

            wizardTimeLines:[
                {
                    name:'Account Specification',
                    links:'/open-bank-account/account-specification',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Personal Information',
                    links:'/open-bank-account/personal-information',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Next of Kin',
                    links:'/open-bank-account/your-next-of-kin',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Required Documents',
                    links:'/open-bank-account/upload-passport-size-photo',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Confirm and Sign',
                    links:'/open-bank-account/declarations-and-signatures',
                    filledClass:false,
                    activeClass:true
                }
            ],

            mobileViewTimeLines:[
                {
                    name: 'Account Specification',
                    link: '/open-bank-account/account-specification',
                    classList: 'fa fa-chevron-right',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name: 'Personal Information',
                    link: '/open-bank-account/personal-information',
                    classList: 'fa fa-chevron-right',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name: 'Next of Kin',
                    link: '/open-bank-account/your-next-of-kin',
                    classList: 'fa fa-chevron-right',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name: 'Required Documents',
                    link:'/open-bank-account/upload-passport-size-photo',
                    classList: 'fa fa-chevron-right',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name: 'Confirm and Sign',
                    link: '/open-bank-account/declarations-and-signatures',
                    classList: 'fa fa-chevron-down',
                    filledClass:false,
                    activeClass:true
                }
            ]
        }
    },methods:{
       //check Arrow Certification checkbox cheked
        confirmBoxChecked(event){
            this.declarations.confirmChecked = event.target.checked
        }, 

        agreementBoxChecked(event){
            this.declarations.agreement = event.target.checked
        }, 
        
        declarationsSubmit(){
            if(this.declarations.confirmChecked && this.declarations.agreement){
                this.$refs.loader.show();
                // this.btnDesabled = true;
                let signatureDetails = {
                    application_id:sessionStorage.getItem("applicationId"),
                    signature:this.declarations.full_name
                }
                this.$http.post(this.$baseurl+'bankAccount/documents/', signatureDetails, {
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                    },
                })
                .then(async(res) => {
                    //Perform Success Action
                    if(res.data.code == '0057-000') {
                        //here send email API
                        let generatepdfdata = '';
                        if(sessionStorage.getItem("accountType") == 'joint'){
                            generatepdfdata = {
                                is_joint : true,
                                application_id : sessionStorage.getItem("applicationId")
                            }
                        } else {
                            generatepdfdata = {
                                application_id : sessionStorage.getItem("applicationId")
                            }
                        }
                        this.$http.post(this.$baseurl+'bankAccount/generate-pdf/',generatepdfdata,{
                            headers: {
                                'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                            }
                        }).then((res) => {
                            // Perform Success Action
                            if(res.data.code == '0062-000') {
                                sessionStorage.removeItem('applicationId');
                                sessionStorage.removeItem('jwtAccessToken');
                                sessionStorage.removeItem('refreshToken');
                                sessionStorage.removeItem('tokenExpiryTime');
                                sessionStorage.removeItem('customerDetail');
                                sessionStorage.removeItem('applicantType');
                                sessionStorage.removeItem('secondApplicantEmail');
                                sessionStorage.removeItem("pagename");
                                sessionStorage.removeItem('accountType');
                                this.$refs.loader.hide();
                                const swalWithBootstrapButtons = this.$swal.mixin({
                                    customClass: {
                                        confirmButton: 'btn btn-primary btn-lg my-3',
                                        cancelButton: 'btn btn-outline-danger btn-lg'
                                    },
                                    buttonsStyling: false
                                })
                                swalWithBootstrapButtons.fire({
                                    width:600,
                                    title: 'Your application has been submitted successfully. Check your email of spam folder for confirmation.',
                                    icon:'success',
                                    showCancelButton: false,
                                    confirmButtonText: 'Got it',
                                    allowOutsideClick: false
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.$router.push({ path: '/open-bank-account' })
                                    } 
                                    // else {
                                    //     this.$router.push({ path: '/open-bank-account' })
                                    // }
                                }) 
                            } else {
                                this.btnDesabled = false;
                                this.$refs.loader.hide();
                                this.$swal('Error!', res.data.message, 'error', 'Close');
                            }
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            // console.log('afsdas',error.response.data);
                            this.btnDesabled = false;
                            this.$refs.loader.hide();
                            this.$swal('Error!', error.response.data.message, 'error', 'Close');
                        });
                    } else {
                        this.btnDesabled = false;
                        this.$refs.loader.hide();
                        this.$swal('Error!', res.message, 'error', 'Close');
                    }
                })
                .catch((error) => {
                    // error.response.status Check status code
                    this.btnDesabled = false;
                    this.$refs.loader.hide();
                    console.log(error);
                });                
            }else{
                this.btnDesabled = false;
                this.$refs.loader.hide();
                return this.declarations.errorMsg = true
            }
        },

        async getAllData(){
            this.$http.get(this.$baseurl+'bankAccount/bank-user-information/', { 
                params: {application_id: sessionStorage.getItem("applicationId")}, 
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                }
            }).then((res) => {
                // console.log(res.data);
                this.selectedBankName = res.data.bank_account_with;
                if(res.data.code == '0059-000' && res.data.documents.user_proof_id != ''){
                    if(res.data.documents.profile_image == '' && res.data.documents.goverment_id == ''){
                        this.$swal('Warning!', 'Please provide passport photo first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/upload-passport-size-photo'});
                    } else if(res.data.documents.goverment_id == ''){
                        this.$swal('Warning!', 'Please provide id details first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/your-id'});
                    } else if(res.data.documents.signature != null) {
                        this.declarations.confirmChecked = true;
                        this.declarations.agreement = true;
                        this.declarations.full_name = res.data.documents.signature;
                        // console.log(res.data.documents.signature);
                    }
                } else {
                    if(res.data.specification_id == '' && res.data.personal_information.user_info_id == '' && res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '' && res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please complete account specification first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/account-specification'});
                    } else if(res.data.personal_information.user_info_id == '' && res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '' && res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please complete personal information first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/personal-information'});
                    } else if(res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '' && res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please complete employment details first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/employment'});
                    } else if(res.data.contact_details.contact_id == '' && res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please complete contact details first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/contact-details'});
                    } else if(res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please provide passport photo first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/upload-passport-size-photo'});
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
        },

        tokenExpired: function() {
            if(sessionStorage.getItem('tokenExpiryTime')){
                if(!this.timeoutModalShow) {
                    const expiryTime = sessionStorage.getItem('tokenExpiryTime').split('.');
                    const currentDateTime = new Date().toISOString().split('.');
                    if(expiryTime[0] == currentDateTime[0]){
                        this.$bvModal.show('expiry_token');
                        this.timeoutModalShow = true;
                    }
                }   
            }        
        },

        termsCondition(){
            this.$bvModal.show('terms_condition')
        },

        capitalizeFirstLetter(event,fieldName){
            var splitStr = event.target.value.split(' ');
            if(splitStr[0] == ''){
                this.fieldName = fieldName;
                this.fieldErrorMsg = 'Please provide minimum two characters.';
            } else {
                this.fieldErrorMsg = '';
                this.fieldName = '';
                for (var i = 0; i < splitStr.length; i++) {
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                }
                // Directly return the joined string
                this.declarations[fieldName] = splitStr.join(' ');
            }
        },
    },created() {
        setInterval(this.tokenExpired, 1000);
        
        if(sessionStorage.getItem("applicationId")){
            this.getAllData();
        } else {
            this.$swal('Warning!', 'Please start application here', 'warning', 'Close');
            this.$router.push({path:'/'});
        }
    }
}
</script>

