<template>
    <section class="main-content">
        <!-- <main-loader ref="loader"></main-loader> -->
    </section>
</template>
<script>
export default {
    name:'ApplicationIdComponent',
    components:{},
    created() {
        let applicationId = this.$route.params.applicationId;
        if(applicationId){
            this.$router.push({ path: '/open-bank-account/account-specification' });
        }
    }
}
</script>

