<template> 
  <div id="wrapper" v-bind:class="{ 
    'wrapper landing-page': landingClassListV2,
    'auth':authClassListV2,
    'wrapper landing-page standapp-main': standappClassListV2,
    'auth standapp-main':  authStandappClassListV2,
    'auth-wrapper landing-page auth':authClassList || authStandappClassList,
    'standapp':authStandappClassList}">
    <div class="page-loader-wrapper" v-if="isLoading">
      <div class="loader">
          <div>
            <img src="./assets/main_logo.svg" width="200" alt="PayAngel">
            <p>Please wait...</p>        
          </div>
      </div>
    </div> 
    <ModalIdle v-if="isIdle" />
    <component :is="layout">
        <router-view></router-view>
    </component>

  </div>
</template>
<script>
// import ModalIdle from "@/components/ModalIdle";
const ModalIdle = require("@/components/ModalIdle").default
const default_layout = "default";
export default {
  name: 'App',
  components: {
    ModalIdle
  },watch: {
    $route: {
      immediate: true, // also trigger handler on initial value
      handler() {
        if (this.$route.name === 'maintanance') {
          document.body.className = '';
        }else{
          document.body.className = 'theme-turquoise';
        }
      }
    }   
  },methods:{
    
  },data (){
      return{
        isLoading: true,
      }
  },created(){
        this.interval = setInterval(() => {
          this.isLoading = false
      }, 3000);
  },computed:{
      layout(){
          return (this.$route.meta.layout || default_layout)+"-layout"
      },

      landingClassListV2(){
        let landingV2Class = this.layout == 'auth-v2-layout'
        return landingV2Class
      },
      
      authClassListV2(){
        let authV2Class = ['login_v2','register_v2','fpwd_v2','otp_v2','passwords_v2','contact_v2','pwd_v2','pdetail_v2','resetpwd_v2','passcode_v2','recoverpwd'].includes(this.$route.name)
        return authV2Class
      },

      authClassList(){
        let authClass = ['login','register','pdetail','pwd','resetpwd','otp','passcode','fpwd','recoverpwd'].includes(this.$route.name)
        return authClass
      },

      standappClassListV2(){
        let standappClassV2 = ['shome','slogin-v2'].includes(this.$route.name)
        return standappClassV2
      },

      authStandappClassListV2(){
        let authStandappClassV2 = ['slogin-v2'].includes(this.$route.name)
        return authStandappClassV2
      },

      authStandappClassList(){
        let authStandappClass = ['slogin'].includes(this.$route.name)
        return authStandappClass
      },

      isIdle() {
        return this.$store.state.idleVue.isIdle;
      }
  },mounted(){
    if(this.layout == 'customer-layout'){
        const head = document.body.parentElement.firstElementChild;
        const link = document.createElement('link');
        link.setAttribute('href', require('@/assets/scss/customer_home.css'));
        link.setAttribute('rel', 'stylesheet'); 
        head.appendChild(link);
    }
  }
}
</script>
<style lang="scss">
  @import '../src/assets/vendor/bootstrap/css/bootstrap.min.css';
  @import '../src/assets/vendor/font-awesome/css/font-awesome.min.css';
  @import '../src/assets/scss/main.scss';
  @import '../src/assets/scss/color_skins.scss';
  @import '../node_modules/flag-icon-css/css/flag-icon.min.css';
</style>
