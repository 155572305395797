<template>
    <section class="main-content">
        <div class="cms-content">
            <div class="container">
                <div class="row justify-content-center">
                    <!-- smartwizard -->
                    <bank-account-wizard :wizardTimeLines="wizardTimeLines"/>

                    <div class="col-lg-8 col-md-10 col-sm-12">
                        <ul class="wizard-mobile list-unstyled">
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.activeTimeLines" :key="'active_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link" :class="{'filled':timeline.filledClass,'active':timeline.activeClass}">
                                    <span>{{ timeline.name }}</span>
                                    <i :class="timeline.classList"></i>
                                </router-link>
                            </li>
                            <li class="main-form-area">
                                <ValidationObserver v-slot="{ passes }">
                                    <form class="profile-photo" @submit.prevent="passes(profilePhotoSubmit)">
                                        <div class="card card-top-line">
                                            <div class="card-body">
                                                <h2 class="page-title">Profile Picture</h2>
                                                <div class="img-or-selfie">
                                                    <!-- Image Upload-->
                                                    <div class="img-upload-area">
                                                        <ValidationProvider name="profile_picture" :rules="!accountDetails.takeSelfie ? 'required' : ''" v-slot="{ errors}">
                                                            <div class="form-group plus-sign upload-Photograph-box">
                                                                <div class="custom-file">
                                                                    <label class="custom-file-label d-flex flex-column py-5" for="passportPhoto">
                                                                        <input type="hidden" v-model="accountDetails.passportPhotos" accept="image/*" />
                                                                        <input type="file" id="passportPhoto" ref="passportPhoto" class="form-control custom-file-input" placeholder="" accept="image/*" @change="passportPhotoChange" />
                                                                        <i class="fa fa-plus fa-2x" v-if="!accountDetails.passportPhotos"></i>
                                                                        <div class="img-uploaded" v-if="accountDetails.passportPhotos">
                                                                            <img class="img-fluid" :src="accountDetails.passportPhotos"/>
                                                                        </div>
                                                                        <p class="mb-0" v-if="!accountDetails.passportPhotos">Upload Passport Size Photo</p>
                                                                    </label>
                                                                    <span class="text-danger" v-if="accountDetails.passportPhotos"> Click on old image to choose new image</span>
                                                                </div>
                                                                <div class="web-camera-container" :class="{'mt-3': accountDetails.passportPhotos}">
                                                                    <div class="camera-shoot" v-if="accountDetails.passportPhotos">
                                                                        <button type="button" class="btn btn-outline-primary btn-mw btn-lg mt-3" @click="removeImageUploadPhoto">
                                                                            Remove Photograph
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p class="text-danger error-msg-upload" v-if="errors.length && !accountDetails.passportPhotos && !isCameraOpen"> Please upload Passport size photograph</p>
                                                        </ValidationProvider>
                                                    </div>

                                                    <!-- Or -->
                                                    <div class="or-line" v-if="!accountDetails.passportPhotos">
                                                        <span class="line-text">OR</span>
                                                        <span class="line-draw"></span>
                                                    </div>

                                                    <!-- Selfie Upload-->
                                                    <div class="selfie-area">
                                                        <div class="form-group plus-sign upload-Photograph-box" v-if="!accountDetails.passportPhotos">
                                                            <div class="custom-file"  v-if="accountDetails.takeSelfie">
                                                                <label class="custom-file-label py-5" for="takeSelfie">
                                                                    <input type="file" id="takeSelfie" ref="takeSelfie" class="form-control custom-file-input" placeholder="" accept="image/*"/>
                                                                    <div class="img-uploaded">
                                                                        <img class="img-fluid" :src="accountDetails.takeSelfie"/>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div class="camera-shoot mt-3" v-if="accountDetails.takeSelfie">
                                                                <button type="button" class="btn btn-outline-primary btn-mw btn-lg" @click="removeSelfieUploadPhoto">
                                                                    Remove Selfie Photograph
                                                                </button>
                                                            </div>
                                                            <div class="capture-selfie" v-if="!accountDetails.takeSelfie">
                                                                <div class="camera-button custom-file-label py-5" :class="{ '' : !isCameraOpen, 'border-danger py-3' : isCameraOpen}" @click="toggleCamera">
                                                                    <p class="mb-0 d-flex flex-column">
                                                                        <i class="fa fa-camera fa-2x"></i>
                                                                        <span v-if="!isCameraOpen"> Take a Selfie </span>
                                                                        <span v-else class="text-danger"> Close Camera </span>
                                                                    </p>
                                                                </div>
                                                                <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
                                                                    <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
                                                                    <video :width="150" :height="112.5" v-show="!isPhotoTaken" ref="camera" autoplay></video>
                                                                    <canvas :width="600" :height="450" v-show="isPhotoTaken" id="photoTaken" ref="canvas"></canvas>
                                                                </div>
                                                                <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
                                                                    <button type="button" class="btn btn-primary btn-mw btn-lg" @click="takePhoto">
                                                                        <i class="fa fa-camera"></i> Capture Photograph
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Image Guidelines-->
                                                        <div class="image-guidelines" v-if="accountDetails.passportPhotos">
                                                            <div class="border p-3 rounded">
                                                                <label>Image Guidelines</label>
                                                                <div class="row">
                                                                    <div class="col-lg-6 col-sl-6">
                                                                        <ul class="list-unstyled">
                                                                            <li class="font-11 line-height-17 mb-0">- Not Blurred</li>
                                                                            <li class="font-11 line-height-17 mb-0">- Colour Image White Background</li>
                                                                            <li class="font-11 line-height-17 mb-0">- JPG or PNG file type</li>
                                                                            <li class="font-11 line-height-17 mb-0">- Clear edges</li>
                                                                            <li class="font-11 line-height-17 mb-0">- Size between 102k and 3M</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="col-lg-6 col-sl-6">
                                                                        <img src="@/assets/open-account/image-guidelines.jpg" class="img-fluid">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-end flex-column flex-lg-row mt-5">
                                                    <div class="d-flex align-items-center justify-content-between flex-column flex-sm-row">
                                                        <router-link to="/open-bank-account/your-next-of-kin" class="btn btn-outline-danger btn-mw mr-sm-1 mr-0 my-sm-0 my-2 btn-lg" @click.native="removesummary()">Back</router-link>
                                                        <b-button class="btn-mw" type="submit" variant="primary" size="lg">Next</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </li>
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.inActiveTimeLines" :key="'inActive_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link"><span>{{ timeline.name }}</span>
                                <i :class="timeline.classList"></i></router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- logo footer -->
                <bank-account-logo-footer/>
                <!-- Expiry Token Modal -->
                <expiry-token-modal></expiry-token-modal>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </section>
</template>
<script>
const { ValidationObserver,ValidationProvider } = require("vee-validate")
const BankAccountLogoFooter = require('@/components/admin/open_bank_account_v1/BankAccountLogoFooter.vue').default
const BankAccountFormWizard = require('@/components/admin/open_bank_account_v2/BankAccountFormWizard.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
const ExpiryTokenModal = require('@/components/admin/open_bank_modal/ExpiryTokenModal.vue').default
import moment from 'moment'
var currentDate = new Date();
var currentDatetime = moment(currentDate).format("YYYYMMDDHHmmss");
export default {
    name:'PassportPhotoV2Component',
    components: {
        ValidationObserver,
        ValidationProvider,
        'bank-account-logo-footer':BankAccountLogoFooter,
        'bank-account-wizard':BankAccountFormWizard,
        'main-loader':LoaderComponent,
        'expiry-token-modal':ExpiryTokenModal
    },
    data(){
        return{
            accountDetails:{
                passportPhotos: null,
                takeSelfie:null
            },
            base64PassportPhoto: '',
            base64Selfie: '',
            timeoutModalShow:false,
           
            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            isLoading: false,
            uploadPhotograph: false,
            wizardTimeLines:[
                {
                    name:'Account Specification',
                    links:'/open-bank-account/account-specification',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Personal Information',
                    links:'/open-bank-account/personal-information',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Next of Kin',
                    links:'/open-bank-account/your-next-of-kin',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Required Documents',
                    links:'/open-bank-account/upload-passport-size-photo',
                    filledClass:false,
                    activeClass:true
                },
                {
                    name:'Confirm and Sign',
                    links:'/open-bank-account/declarations-and-signatures',
                    filledClass:false,
                    activeClass:false
                }
            ],

            mobileViewTimeLine:{
                activeTimeLines:[
                    {
                        name: 'Account Specification',
                        link: '/open-bank-account/account-specification',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Personal Information',
                        link: '/open-bank-account/personal-information',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Next of Kin',
                        link: '/open-bank-account/your-next-of-kin',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Required Documents',
                        link:'/open-bank-account/upload-passport-size-photo',
                        classList: 'fa fa-chevron-down',
                        filledClass:false,
                        activeClass:true
                    },
                ],

                inActiveTimeLines:[
                    {
                        name: 'Confirm and Sign',
                        link: '/open-bank-account/declarations-and-signatures',
                        classList: 'fa fa-chevron-right'
                    }
                ]
            }
        }
    },methods:{
        //manually uplaod image
        passportPhotoChange(e) {
            if(e.target.files.length > 0){
                let file = e.target.files[0];
                let fileType = file.type;
                if(fileType == 'image/png' || fileType == 'image/jpg' || fileType == 'image/jpeg' || fileType == 'image/JPEG'){
                    // Start For convert and set base64
                    const reader = new FileReader()
                    let rawImg;
                    reader.onloadend = () => {
                        rawImg = reader.result;
                        this.base64PassportPhoto = rawImg;
                    }
                    reader.readAsDataURL(file);
                    // End For convert and set base64

                    this.accountDetails.passportPhotos = URL.createObjectURL(file);
                } else {
                    this.$swal('Error!', 'Please provide image file', 'error', 'Close');
                }
            }
        },

        toggleCamera() {
            if(this.isCameraOpen) {
                this.isCameraOpen = false;
                this.isPhotoTaken = false;
                this.isShotPhoto = false;
                this.uploadPhotograph = false;
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.uploadPhotograph = false;
                this.createCameraElement();
            }
        },
    
        createCameraElement() {
            this.isLoading = true;
            const constraints = (window.constraints = {
                audio: false,
                video: true
            });

            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => {
                    this.isLoading = false;
                    this.$refs.camera.srcObject = stream;
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    alert("May the browser didn't support or there is some errors.");
            });
        },
    
        stopCameraStream() {
            let tracks = this.$refs.camera.srcObject.getTracks();
            tracks.forEach(track => {
                track.stop();
            });
        },

        //using webcam uplaod image
        takePhoto() {
            if(!this.isPhotoTaken) {
                this.isShotPhoto = true;

                const FLASH_TIMEOUT = 50;

                setTimeout(() => {
                    this.isShotPhoto = false;
                }, FLASH_TIMEOUT);
            }
        
            this.isPhotoTaken = !this.isPhotoTaken;
        
            const context = this.$refs.canvas.getContext('2d');
            context.drawImage(this.$refs.camera, 0, 0, 600, 450);

            const canvas = document.getElementById("photoTaken");

            // Start For set base64
            this.base64Selfie = canvas.toDataURL();
            // End For set base64

            this.isPhotoTaken = false;
            const selfieFile = this.dataURLtoBlob( canvas.toDataURL() );
            this.accountDetails.takeSelfie = URL.createObjectURL(selfieFile);
            this.stopCameraStream();
            this.isCameraOpen = false;
            this.uploadPhotograph = false;
        },
        
        dataURLtoBlob(dataURL) {
            let array, binary, i, len;
            binary = atob(dataURL.split(',')[1]);
            array = [];
            i = 0;
            len = binary.length;
            while (i < len) {
                array.push(binary.charCodeAt(i));
                i++;
            }
            return new Blob([new Uint8Array(array)], {
                type: 'image/png'
            });
        },

        removeImageUploadPhoto(){
            this.$refs.passportPhoto.value = null;
            this.accountDetails.passportPhotos = null;
            this.isCameraOpen = false
            //selfie remove upload value
            setTimeout(() => {
                if(this.accountDetails.takeSelfie){
                    this.$refs.takeSelfie.value = null;
                    this.accountDetails.takeSelfie = null;
                }
            }, 100);
        },

        removeSelfieUploadPhoto(){
            this.$refs.takeSelfie.value = null;
            this.accountDetails.takeSelfie = null;
            this.isCameraOpen = false 
            //image remove upload value
            setTimeout(() => {
                if(this.accountDetails.passportPhotos){
                    this.$refs.passportPhoto.value = null;
                    this.accountDetails.passportPhotos = null;
                }
            }, 100);
        },

        profilePhotoSubmit(){
            this.$refs.loader.show();
            let photo = '';
            if(this.base64PassportPhoto != '' && this.base64Selfie != ''){
                photo = this.base64PassportPhoto;
            } else if(this.base64PassportPhoto != ''){
                photo = this.base64PassportPhoto;
            } else if(this.base64Selfie != ''){
                photo = this.base64Selfie;
            }
            // console.log(photo);
            if(photo == '' && this.accountDetails.passportPhotos != ''){
                this.$refs.loader.hide();
                if(sessionStorage.getItem("pagename") && sessionStorage.getItem("pagename") == 'Summary'){
                    sessionStorage.removeItem("pagename")
                    this.$router.push({path:'/open-bank-account/summary'});
                } else {
                    this.$router.push({path:'/open-bank-account/your-id'})
                }
            } else {
                let photoSplit = photo.split('base64,')
                let passportDetails = {
                    application_id:sessionStorage.getItem("applicationId"),
                    profile_image_name:currentDatetime+'.jpg',
                    profile_image:photoSplit[1]
                }
                // console.log(passportDetails);
                this.$http.post(this.$baseurl+'bankAccount/documents/', passportDetails, {
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                    },
                })
                .then((res) => {
                    //Perform Success Action
                    // console.log('success',res.data);
                    if(res.data.code == '0057-000') {
                        this.$refs.loader.hide();
                        if(sessionStorage.getItem("pagename") && sessionStorage.getItem("pagename") == 'Summary'){
                            sessionStorage.removeItem("pagename")
                            this.$router.push({path:'/open-bank-account/summary'});
                        } else {
                            this.$router.push({path:'/open-bank-account/your-id'})
                        }
                    } else {
                        this.$refs.loader.hide();
                        this.$swal('Error!', res.message, 'error', 'Close');
                    }
                })
                .catch((error) => {
                    // error.response.status Check status code
                    this.$refs.loader.hide();
                    console.log('error',error);
                }); 
            }
        },

        async getPassportData() {
            this.$http.get(this.$baseurl+'bankAccount/bank-user-information/', { 
                params: {application_id: sessionStorage.getItem("applicationId")}, 
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                }
            }).then((res) => {
                // console.log(res.data);
                if(res.data.code == '0059-000' && res.data.documents.user_proof_id != ''){
                    this.accountDetails.passportPhotos = 'https://nrba.payangel.com'+res.data.documents.profile_image
                } else {
                    if(res.data.specification_id == '' && res.data.personal_information.user_info_id == '' && res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '') {
                        this.$swal('Warning!', 'Please complete account specification first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/account-specification'});
                    } else if(res.data.personal_information.user_info_id == '' && res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '') {
                        this.$swal('Warning!', 'Please complete personal information first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/personal-information'});
                    } else if(res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '') {
                        this.$swal('Warning!', 'Please complete employment details first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/employment'});
                    } else if(res.data.contact_details.contact_id == '') {
                        this.$swal('Warning!', 'Please complete contact details first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/contact-details'});
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
        },

        tokenExpired: function() {
            if(sessionStorage.getItem('tokenExpiryTime')){
                if(!this.timeoutModalShow) {
                    const expiryTime = sessionStorage.getItem('tokenExpiryTime').split('.');
                    const currentDateTime = new Date().toISOString().split('.');
                    if(expiryTime[0] == currentDateTime[0]){
                        this.$bvModal.show('expiry_token');
                        this.timeoutModalShow = true;
                    }
                }   
            }              
        },

        removesummary(){
            if(sessionStorage.getItem("pagename")){
                sessionStorage.removeItem("pagename")
            }
        }
    },beforeDestroy () {
        this.stopCameraStream();
    },created() {   
        setInterval(this.tokenExpired, 1000);
             
        if(sessionStorage.getItem("applicationId")){
            this.getPassportData();
        } else {
            this.$swal('Warning!', 'Please start application here', 'warning', 'Close');
            this.$router.push({path:'/'});
        }
    }
}
</script>
