<template>
     <section class="main-content">
        <div class="cms-content">
            <div class="container">
                <div class="row justify-content-center">
                    <!-- smartwizard -->
                    <bank-account-wizard :wizardTimeLines="wizardTimeLines"/>

                    <div class="col-lg-8 col-md-10 col-sm-12">
                        <ul class="wizard-mobile list-unstyled">
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.activeTimeLines" :key="'active_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link" :class="{'filled':timeline.filledClass,'active':timeline.activeClass}">
                                    <span>{{ timeline.name }}</span>
                                    <i :class="timeline.classList"></i>
                                </router-link>
                            </li>
                            <li class="main-form-area">
                                <ValidationObserver v-slot="{ passes }" ref="form">
                                    <form class="next-kin" @submit.prevent="passes(nextKinSubmit)" autocomplete="off">
                                        <div class="card card-top-line">
                                            <div class="card-body">
                                                <h2 class="page-title">Your Next of Kin</h2>
                                                <div class="row">
                                                    <div class="col-md-6 col-12">
                                                        <ValidationProvider name="firstName" rules="required" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.firstName,'success':accountDetails.firstName, 'mb-0':apiErrorCode == 'V0056-003'}">
                                                                <span class="float-label">
                                                                    <input type="text" ref="firstName" v-model="accountDetails.firstName" class="form-control" id="firstName" placeholder="First Name*"  @input="validateName($event,'First Name','firstName')">
                                                                    <label for="firstName" class="control-label">First Name*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.firstName"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.firstName"></i>
                                                                </span>
                                                                <span class="text-danger error-msg" v-if="fieldName == 'First Name'">{{fieldErrorMsg}}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0056-003'">{{apiErrorMessage}}</span>
                                                        <ValidationProvider name="lastName" rules="required" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.lastName,'success':accountDetails.lastName, 'mb-0':apiErrorCode == 'V0056-004'}">
                                                                <span class="float-label">
                                                                    <input type="text" ref="lastName" v-model="accountDetails.lastName" class="form-control" id="lastName" placeholder="Last Name*" @input="validateName($event,'Last Name','lastName')">
                                                                    <label for="lastName" class="control-label">Last Name*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.lastName"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.lastName"></i>
                                                                </span>
                                                                <span class="text-danger error-msg" v-if="fieldName == 'Last Name'">{{fieldErrorMsg}}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0056-004'">{{apiErrorMessage}}</span>
                                                        <ValidationProvider name="email" rules="required|email" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.email || errors[0],'success':accountDetails.email && !errors[0], 'mb-0':apiErrorCode == 'V0056-000'}">
                                                                <span class="float-label">
                                                                    <input type="email" ref="email" v-model="accountDetails.email" class="form-control" name="email1" id="email" placeholder="Email*">
                                                                    <label for="email" class="control-label">Email*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.email || errors[0] "></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.email && !errors[0]"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0056-000'">{{apiErrorMessage}}</span>
                                                        <ValidationProvider name="relationship" rules="required" v-slot="{ errors }">
                                                            <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':accountDetails.relationship != null  && !errors[0], 'mb-0':apiErrorCode == 'V0056-009'}">
                                                                <span class="float-label">
                                                                    <label for="relationship">Select Relationship*</label>
                                                                    <multiselect
                                                                        ref="relationship"
                                                                        placeholder=""
                                                                        v-model="accountDetails.relationship" 
                                                                        :show-labels="false" 
                                                                        :allow-empty="false"
                                                                        :options="relationship_options" 
                                                                        :searchable="false">
                                                                    </multiselect>  
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.relationship"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.relationship"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0056-009'">{{apiErrorMessage}}</span>
                                                    </div>
                                                    <div class="col-md-6 col-12">
                                                        <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                                                            <div class="country-dropdown">
                                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountDetails.country != null  && !errors[0], 'mb-0':apiErrorCode == 'V0056-005'}">
                                                                    <span class="float-label">
                                                                        <label for="country">Select Country*</label>
                                                                        <multiselect
                                                                            ref="country"
                                                                            placeholder=""
                                                                            v-model="accountDetails.country" 
                                                                            :show-labels="false" 
                                                                            :options="countrys_options" 
                                                                            :searchable="true" 
                                                                            :allow-empty="false"
                                                                            label="name" 
                                                                            track-by="name">
                                                                            <template slot="option" slot-scope="props">
                                                                                <span :class="props.option.flagClass"></span>
                                                                                <div class="option__desc">
                                                                                    <span class="option__title">{{ props.option.name }}</span>
                                                                                </div>
                                                                            </template>
                                                                        </multiselect>  
                                                                        <i class="fa fa-times" v-if="errors.length && !accountDetails.country"></i>
                                                                        <i class="fa fa-check" v-if="accountDetails.country"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0056-005'">{{apiErrorMessage}}</span>
                                                        <ValidationProvider name="address" rules="required" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.address,'success':accountDetails.address, 'mb-0':apiErrorCode == 'V0056-007'}">
                                                                <span class="float-label">
                                                                    <input type="text" ref="address" v-model="accountDetails.address" class="form-control" id="address" placeholder="Address*" @input="capitalizeFirstLetter($event,'address')">
                                                                    <label for="address" class="control-label">Address*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.address"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.address"></i>
                                                                </span>
                                                                <span class="text-danger error-msg" v-if="fieldName == 'address'">{{fieldErrorMsg}}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0056-007'">{{apiErrorMessage}}</span>
                                                        <ValidationProvider name="city" rules="required" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.city,'success':accountDetails.city, 'mb-0':apiErrorCode == 'V0056-006'}">
                                                                <span class="float-label">
                                                                    <input type="text" ref="city" v-model="accountDetails.city" class="form-control" id="city" placeholder="City*" @input="capitalizeFirstLetter($event,'city')">
                                                                    <label for="city" class="control-label">City*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.city"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.city"></i>
                                                                </span>
                                                                <span class="text-danger error-msg" v-if="fieldName == 'city'">{{fieldErrorMsg}}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0056-006'">{{apiErrorMessage}}</span>
                                                        <div class="form-group">
                                                            <!--  :class="{'errors': errors.length && !accountDetails.postCode,'success':accountDetails.postCode, 'mb-0':apiErrorCode == 'V0056-008'}" -->
                                                            <span class="float-label">
                                                                <input type="text" ref="postCode" v-model="accountDetails.postCode" class="form-control" id="postCode" placeholder="Post Code">
                                                                <label for="postCode" class="control-label">Post Code</label>
                                                                <div class="line"></div>
                                                                <!-- <i class="fa fa-times" v-if="errors.length && !accountDetails.postCode"></i>
                                                                <i class="fa fa-check" v-if="accountDetails.postCode"></i> -->
                                                            </span>
                                                        </div>
                                                        <!-- <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0056-008'">{{apiErrorMessage}}</span> -->
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-end flex-column flex-lg-row">
                                                    <div class="d-flex align-items-center justify-content-between flex-column flex-sm-row">
                                                        <router-link to="/open-bank-account/contact-details" class="btn btn-outline-danger btn-mw mr-sm-1 mr-0 my-sm-0 my-2 btn-lg" @click.native="removesummary()">Back</router-link>
                                                        <b-button class="btn-mw btn-mw mr-sm-1 mr-0 my-sm-0 my-2" variant="outline-secondary" size="lg" @click="nextKinSkip()">Skip</b-button>
                                                        <b-button class="btn-mw" type="submit" variant="primary" size="lg" @click="focusOnFirstError()">Next</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </li>
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.inActiveTimeLines" :key="'inActive_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link"><span>{{ timeline.name }}</span>
                                <i :class="timeline.classList"></i></router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- logo footer -->
                <bank-account-logo-footer/>
                <!-- Expiry Token Modal -->
                <expiry-token-modal></expiry-token-modal>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </section>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
const BankAccountLogoFooter = require('@/components/admin/open_bank_account_v1/BankAccountLogoFooter.vue').default
const BankAccountFormWizard = require('@/components/admin/open_bank_account_v2/BankAccountFormWizard.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
const ExpiryTokenModal = require('@/components/admin/open_bank_modal/ExpiryTokenModal.vue').default
export default {
    name:'NextofKinV2Component',
    components:{
        Multiselect,
        ValidationObserver,
        ValidationProvider,
        'bank-account-logo-footer':BankAccountLogoFooter,
        'bank-account-wizard':BankAccountFormWizard,
        'main-loader':LoaderComponent,
        'expiry-token-modal':ExpiryTokenModal
    },
    data(){
        return{
            accountDetails:{
                firstName:'',
                lastName:'',
                email:'',
                relationship:null,
                country:null,
                city:'',
                address:'',
                postCode:'',
            },
            apiErrorCode:'',
            apiErrorMessage:'',
            fieldName:'',
            fieldErrorMsg:'',
            timeoutModalShow:false,
            relationship_options:['Son','Daughter','Brother','Sister','Parents','Spouse','Family - Other','Friend','Colleague','Business Partner','Other - please specify'],
            countrys_options: [],
            // countrys_options: [
            //     { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
            //     { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
            //     { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
            //     { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            // ],

            wizardTimeLines:[
                {
                    name:'Account Specification',
                    links:'/open-bank-account/account-specification',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Personal Information',
                    links:'/open-bank-account/personal-information',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Next of Kin',
                    links:'/open-bank-account/your-next-of-kin',
                    filledClass:false,
                    activeClass:true
                },
                {
                    name:'Required Documents',
                    links:'/open-bank-account/upload-passport-size-photo',
                    filledClass:false,
                    activeClass:false
                },
                {
                    name:'Confirm and Sign',
                    links:'/open-bank-account/declarations-and-signatures',
                    filledClass:false,
                    activeClass:false
                }
            ],

            mobileViewTimeLine:{
                activeTimeLines:[
                    {
                        name: 'Account Specification',
                        link: '/open-bank-account/account-specification',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Personal Information',
                        link: '/open-bank-account/personal-information',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Next of Kin',
                        link: '/open-bank-account/your-next-of-kin',
                        classList: 'fa fa-chevron-down',
                        filledClass:false,
                        activeClass:true
                    }
                ],

                inActiveTimeLines:[
                    {
                        name: 'Required Documents',
                        link:'/open-bank-account/upload-passport-size-photo',
                        classList: 'fa fa-chevron-right'
                    },
                    {
                        name: 'Confirm and Sign',
                        link: '/open-bank-account/declarations-and-signatures',
                        classList: 'fa fa-chevron-right'
                    }
                ]
            }
        }
    },methods:{
        nextKinSkip(){
            if(sessionStorage.getItem("pagename") && sessionStorage.getItem("pagename") == 'Summary'){
                sessionStorage.removeItem("pagename")
                this.$router.push({path:'/open-bank-account/summary'});
            } else {
                this.$router.push({path:'/open-bank-account/upload-passport-size-photo'})
            }
        },
        async focusOnFirstError(){
            const success = await this.$refs.form.validate();
            if (!success) {
                var errorsName = Object.keys(this.$refs.form.errors);
                var firstError = ''
                errorsName.map((item) => {
                    if(this.$refs.form.errors[item].length > 0 && !firstError){
                        firstError = item
                    }
                })
                
                if(firstError == 'country' || firstError == 'relationship'){
                    this.$refs[firstError].$el.focus();
                } else{
                    this.$refs[firstError].focus();
                }
            }
        },
        nextKinSubmit(){
            this.$refs.loader.show();
            let nextofkinDetails = {
                application_id:sessionStorage.getItem("applicationId"),
                first_name:this.accountDetails.firstName,
                last_name:this.accountDetails.lastName,
                email:this.accountDetails.email,
                relationship:this.accountDetails.relationship,
                country:this.accountDetails.country.name,
                city:this.accountDetails.city,
                address:this.accountDetails.address,
                post_code:this.accountDetails.postCode
            }
            // console.log(nextofkinDetails);
            this.$http.post(this.$baseurl+'bankAccount/next-of-kin/', nextofkinDetails, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                },
            })
            .then((res) => {
                //Perform Success Action
                // console.log(res.data);
                
                if(res.data.code == '0056-000') {
                    this.$refs.loader.hide();
                    if(sessionStorage.getItem("pagename") && sessionStorage.getItem("pagename") == 'Summary'){
                        sessionStorage.removeItem("pagename")
                        this.$router.push({path:'/open-bank-account/summary'});
                    } else {
                        this.$router.push({path:'/open-bank-account/upload-passport-size-photo'})
                    }
                } else {
                    this.$refs.loader.hide();
                    this.$swal('Error!', res.message, 'error', 'Close');
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$refs.loader.hide();
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMessage = error.response.data.message;
            }); 
            
        },

        validateName(e,fieldType,fieldName) {
            let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
            let charLength = e.target.value.length;
            this.accountDetails[fieldName] = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1); //Capitalize first character
            if(/^[A-Za-z'-]+$/.test(char)) {
                if(charLength >= 2 && charLength <= 35) {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
                    return true; // Match with regex 
                } else {
                    this.fieldName = fieldType;
                    this.fieldErrorMsg = 'Please provide minimum two characters.';
                }
            } else {
                this.fieldName = fieldType;
                this.fieldErrorMsg = 'Please provide valid '+fieldType;
                e.preventDefault(); // If not match, don't add to input text
            }
        },
        
        async getNextofkindata() {
            this.$http.get(this.$baseurl+'bankAccount/bank-user-information/', { 
                params: {application_id: sessionStorage.getItem("applicationId")}, 
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                }
            }).then((res) => {
                //Perform Success Action
                // console.log(res.data);
                if(res.data.code == '0059-000' && res.data.next_of_kin.next_of_kin_id != ''){
                    let nextofKinData = res.data.next_of_kin;
                    this.accountDetails.firstName = nextofKinData.first_name;
                    this.accountDetails.lastName = nextofKinData.last_name;
                    this.accountDetails.email = nextofKinData.email;
                    this.accountDetails.relationship = nextofKinData.relationship;  
                    this.accountDetails.country = { name: nextofKinData.country};
                    this.accountDetails.city = nextofKinData.city;
                    this.accountDetails.address = nextofKinData.address;
                    this.accountDetails.postCode = nextofKinData.post_code;   
                } else {
                    if(res.data.specification_id == '' && res.data.personal_information.user_info_id == '' && res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '') {
                        this.$swal('Warning!', 'Please complete account specification first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/account-specification'});
                    } else if(res.data.personal_information.user_info_id == '' && res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '') {
                        this.$swal('Warning!', 'Please complete personal information first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/personal-information'});
                    } else if(res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '') {
                        this.$swal('Warning!', 'Please complete employment details first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/employment'});
                    } else if(res.data.contact_details.contact_id == '') {
                        this.$swal('Warning!', 'Please complete contact details first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/contact-details'});
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                // this.apiErrorCode = error.response.data.code;
            })
                   
        },

        capitalizeFirstLetter(event,fieldName){
            var splitStr = event.target.value.split(' ');
            if(splitStr[0] == ''){
                this.fieldName = fieldName;
                this.fieldErrorMsg = 'Please provide minimum two characters.';
            } else {
                this.fieldErrorMsg = '';
                this.fieldName = '';
                for (var i = 0; i < splitStr.length; i++) {
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                }
                // Directly return the joined string
                this.accountDetails[fieldName] = splitStr.join(' ');
            }
        },

        async getNationalityData() {
            try {
                const response = await this.$http.get("https://stand-app.com/api/v1/countries");
                // JSON responses are automatically parsed.
                response.data.map((item) => {
                    let countryData = {
                        name: item.countryname, 
                        flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round"
                    }
                    this.countrys_options.push(countryData);
                })
            } catch (error) {
                console.log(error);
            }
        }, 

        tokenExpired: function() {
            if(sessionStorage.getItem('tokenExpiryTime')){
                if(!this.timeoutModalShow) {
                    const expiryTime = sessionStorage.getItem('tokenExpiryTime').split('.');
                    const currentDateTime = new Date().toISOString().split('.');
                    if(expiryTime[0] == currentDateTime[0]){
                        this.$bvModal.show('expiry_token');
                        this.timeoutModalShow = true;
                    }
                }   
            }            
        },
        removesummary(){
            if(sessionStorage.getItem("pagename")){
                sessionStorage.removeItem("pagename")
            }
        }
    },created() {
        setInterval(this.tokenExpired, 1000);
        this.getNationalityData();

        if(sessionStorage.getItem("applicationId")){
            this.getNextofkindata();
        } else {
            this.$swal('Warning!', 'Please start application here', 'warning', 'Close');
            this.$router.push({path:'/'});
        }
        // console.log(sessionStorage.getItem("jwtAccessToken"));
    }
}
</script>
