<template>
    <b-modal id="second_applicant_mobile_number_varify" title="Verify Mobile Number" hide-footer modal-class="modal-center customer-home-modal" no-stacking no-close-on-backdrop @shown="resetTimer">
        <div class="modal-body">
            <div class="header pb-3 text-center">
                <p class="lead px-3" style="color: green; font-weight: 500;">Ring Ring...</p>
            </div>
            <div class="body">
                <p class="text-center mb-3">Enter the verification code we just sent via text to your mobile phone 
                    <strong>{{mobileNumber}}</strong>
                    <!-- <strong>+44 233 2342 3432</strong> -->
                </p>
                <div class="otp-form">
                    <div id="otp" class="d-flex justify-content-center">
                        <div class="form-group">
                            <v-otp-input
                                ref="otpInput"
                                input-classes="otp-input form-control m-2"
                                separator=" "
                                :num-inputs="5"
                                :should-auto-focus="true"
                                :is-input-num="false"
                                @on-complete="handleOnComplete"
                            />
                        </div>
                    </div>
                </div>
                <div class="otp-timer text-center" id="otp_timer">
                    <span v-if="showResendOtp">
                        <p>Code hasn't arrived? 
                            <a class="text-danger" href="javascript:void(0)" @click="resendOtp()">Resend OTP</a>
                        </p>
                        <p class="mt-3 mb-0"> 
                            You may be experiencing mobile network issues, check network settings on your phone
                        </p>
                    </span>
                    <p>
                        <span class="text-danger">{{ wrongOtpMsg }}</span>
                    </p>
                    <p class="mt-3 mb-0" v-if="!showResendOtp"> 
                        You can request a new code if it does not arrive in: 
                        <label class="timer-text text-danger" id="timer_countdown">02:00</label>
                        <!-- <label class="timer-text text-danger" id="timer_countdown"></label> -->
                    </p>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
const OtpInput = require("@bachdgvn/vue-otp-input").default
export default {
    name:'SecondApplicantOtpModal',
    components:{
        'v-otp-input':OtpInput
    },data(){
        return{
            clearTimer:0,
            wrongOtpMsg:'',
            apiErrorCode:'',
            showResendOtp:false,
            applicationId:'',
            mobileNumber: '',
            firstName:''
        }
    },methods: {
        //timer counter
        countDownTimer() {
            var duration = 2 * 60
            var timer = duration, minutes, seconds;
            var that = this;
            this.clearTimer = setInterval(function () {
                if(timer > 0) {
                    if(document.getElementById("timer_countdown")){
                        minutes = parseInt((timer / 60)%60, 10)
                        seconds = parseInt(timer % 60, 10);

                        minutes = minutes < 10 ? "0" + minutes : minutes;
                        seconds = seconds < 10 ? "0" + seconds : seconds;
                        document.getElementById("timer_countdown").innerHTML = minutes + ":" + seconds;
                    }
                    --timer;
                    // if(timer <= 0){
                    //     that.showResendOtp = true;
                    // } else {
                    //     that.showResendOtp = false;
                    // }
                } else {
                    document.getElementById("timer_countdown").innerHTML = "00:00";
                    clearInterval(this.clearTimer);
                    this.clearTimer = 0;
                    if(timer <= 0){
                        that.showResendOtp = true;
                    } else {
                        that.showResendOtp = false;
                    }
                }
            }, 1000);
        },
        
        //reset timer 
        resetTimer() {
            clearInterval(this.clearTimer)
            this.countDownTimer();
            this.$http.post(this.$baseurl+'user/hash-auth/', {hash:this.applicationId}).then((res) => {
                // console.log(res.data);
                //Perform Success Action
                if(res.data.code == '0068-000') {
                    this.firstName = res.data.first_name;
                    this.mobileNumber = res.data.phone_number;
                    sessionStorage.setItem("jwtAccessToken", res.data.access);
                    sessionStorage.setItem("refreshToken", res.data.refresh);
                    sessionStorage.setItem("applicationId", res.data.application_id);
                    sessionStorage.setItem("tokenExpiryTime", res.data.expiry_time);
                    this.resendOtp();
                } else {
                    this.$swal('Error!', res.data.message, 'error', 'Close');
                }
            })
            .catch((error) => {
                this.$swal('Error!', error.response.data.message, 'error', 'Close');
            });
        },

        resendOtp(){
            let resendOtpData = {
                phone_number:this.mobileNumber,
                first_name:this.firstName
            }
            this.$http.post(this.$baseurl+'user/send-otp/', resendOtpData).then((res) => {
                //Perform Success Action
                if(res.data.code == '0061-000') {
                    this.showResendOtp = false;
                    clearInterval(this.clearTimer);
                    return this.countDownTimer();
                } else {
                    this.$swal('Error!', res.data.message, 'error', 'Close');
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$swal('Error!', error.response.data.message, 'error', 'Close');
            });
        },

        handleOnComplete(value) {
            if(value == '11111'){
                this.wrongOtpMsg = 'Please Enter Valid OTP'
            } else{
                this.mobileVarificationFormSubmit(value);
            }
        },
        
        mobileVarificationFormSubmit: function(value) {
            let verifyData = {
                phone_number: this.mobileNumber, 
                otp: value 
            }
            // console.log(verifyData);
            this.$http.post(this.$baseurl+'user/verify-otp/', verifyData).then((res) => {
                // Perform Success Action
                if(res.data.code == '0060-000') {
                    sessionStorage.setItem("applicantType", 'second');
                    sessionStorage.setItem("accountType", 'joint');
                    this.$router.push({ path: '/open-bank-account/personal-information' })
                } else {
                    this.$swal('Error!', res.data.message, 'error', 'Close');
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                // console.log(error.response);
                this.$swal('Error!', error.response.data.message, 'error', 'Close');
            });
        },

    },created() {
        clearInterval(this.clearTimer)
        this.applicationId = this.$route.params.applicationID;
    },beforeDestroy(){
        clearInterval(this.clearTimer)
    },mounted() {
        this.$bvModal.show('second_applicant_mobile_number_varify')
    }
}
</script>