<template>
    <div>
        <StandappHeaderV2Component/>
        <slot></slot>
        <AuthFooterV2Component v-if="['shome'].includes($route.name)"/>
    </div>
</template>
<script>
import StandappHeaderV2Component from '@/components/admin/layouts/standapp_header_v2/StandappHeaderV2Component.vue'
import AuthFooterV2Component from '@/components/admin/layouts/auth_footer/AuthFooterV2Component.vue'

export default {
    name:'StandappV2',
    components: {
        StandappHeaderV2Component,
        AuthFooterV2Component
    }
}
</script>
<style lang="scss">
    @import './src/assets/scss/pages/standapp_styles.scss';
</style>