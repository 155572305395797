<template>
    <div class="auth-main">
        <AuthLeftSideComponent/>
        <div class="auth-right">
            <div class="right-main d-flex flex-column h-100">
                <!-- auth header -->
                <RightHeaderComponent/>

                <!-- dynamic content section -->
                <slot></slot>
                
                <!-- right footer -->
                <RightFooterComponent/>
            </div>
        </div> 
    </div>
</template>
<script>
import AuthLeftSideComponent from '@/components/admin/layouts/auth_left/AuthLeftSideComponent.vue'
import RightHeaderComponent from '@/components/admin/layouts/auth_right_header/RightHeaderComponent.vue'
import RightFooterComponent from '@/components/admin/layouts/auth_right_footer/RightFooterComponent.vue'
export default {
    name:'AuthLayout',
    components: {
        AuthLeftSideComponent,
        RightHeaderComponent,
        RightFooterComponent
    },data() {
        return {
           
        }
    },methods:{
        
    }
}
</script>
