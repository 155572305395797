<template>
     <div class="row"> 
         <div class="col-12">
             <div class="d-flex align-items-center justify-content-between top-all-filter">
                <!-- Breadcrumbs -->
                <div class="breadcrumbs-custom flex-grow-1">
                    <h2><a href="javascript:void(0);" class="btn btn-xs btn-link btn-toggle-fullwidth"><i v-bind:class="{ 'fa fa-arrow-right': show, 'fa fa-arrow-left': !show }" class="fa fa-arrow-left" v-on:click="layoutFullwidth()"></i></a> {{ meta }}</h2>
                    <ul class="breadcrumb" >
                        <li class="breadcrumb-item"><router-link to="/dashboard-v1"><i class="icon-home"></i></router-link></li>    
                        <template v-if="!breadCrumbIteams">
                            <li class="breadcrumb-item" v-for="item in crumbs" :key="item.id">{{ item.text }}</li>    
                        </template>                        
                        <template v-if="breadCrumbIteams">
                            <li class="breadcrumb-item" v-for="(item,index) in breadCrumbIteams" :key="index">
                                <router-link :to="item.links" v-if="item.links">{{ item.title }}</router-link>
                                <a v-if="!item.links">{{ item.title }}</a>
                            </li>
                        </template>
                    </ul>
                </div>
                
                <!-- Filters -->
                <div class="chart-top-action d-md-flex position-static" v-if="showFilter || showProfile">
                    <!-- Filters Input Button-->
                    <div class="fliter-main mt-3 mt-md-0" v-if="showInputFilter">
                        <b-button class="fliter-btn btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                        <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                            <div class="d-flex justify-content-between p-3">
                                <div class="form-group">
                                    <input class="form-control" type="text" placeholder="Input One"/>
                                    <div class="line"></div>
                                </div>
                                <div class="form-group">
                                    <input class="form-control" type="text" placeholder="Input Two"/>
                                    <div class="line"></div>
                                </div>
                                <div class="form-group">
                                    <input class="form-control" type="text" placeholder="Input Three"/>
                                    <div class="line"></div>
                                </div>
                                <div class="form-group">
                                    <b-button variant="primary h-100" @click="isVisibleFilter = false">Apply</b-button>
                                </div>
                                <div class="form-group">
                                    <b-button variant="danger h-100" @click="isVisibleFilter = false">Cancel</b-button>
                                </div>
                            </div>
                        </b-collapse>
                    </div>

                    <!-- Date Filter-->
                    <div class="date-range-main mx-3" v-if="showDateFilter">
                        <template>
                            <v-md-date-range-picker
                                :opens="opens"
                                :presets="presets"
                                :autoApply="autoApplyDateRange"
                            ></v-md-date-range-picker>
                        </template>
                    </div>

                    <!-- Country Filters -->
                    <div v-if="showCountry">
                        <div class="country-dropdown mb-0">
                            <div class="form-group form-select multiselect-grp mb-0">
                                <span class="float-label">
                                    <label for="roles">Select Country</label>
                                    <multiselect
                                        v-model="country" 
                                        :show-labels="false" 
                                        :options="countrys_options" 
                                        :searchable="false"
                                        :multiple="true" 
                                        :close-on-select="true"
                                        :clear-on-select="false" 
                                        label="name" 
                                        track-by="name"  
                                        :limit="0"
                                        :limit-text="displayText"
                                        @select="isCheckedInput"
                                        @remove="isCheckedInput">
                                        <template slot="beforeList">
                                            <span class="multiselect__option" @click="selectAllChanged">
                                                <div class="option__desc">
                                                    <span class="option__title">All</span>
                                                </div>
                                                <div class="multiselect__element multiselect-all multi-drop">
                                                    <div class="control-inline fancy-checkbox" for="isChecked">
                                                        <input type="checkbox" id="checkbox2" name="checkbox2" :checked="isAllChecked">
                                                        <span for="checkbox2"></span>
                                                    </div>
                                                </div>
                                            </span>
                                        </template>
                                        <template slot="option" slot-scope="props">
                                            <span :class="props.option.flagClass"></span>
                                            <div class="option__desc">
                                                <span class="option__title">{{ props.option.name }}</span>
                                            </div>
                                            <div class="multiselect__element multiselect-all multi-drop">
                                                <div class="control-inline fancy-checkbox" for="isChecked">
                                                    <input type="checkbox" id="isChecked" name="isChecked" :checked="props.option.isChecked == true">
                                                    <span for="isChecked"></span>
                                                </div>
                                            </div>
                                        </template>
                                    </multiselect>   
                                </span>
                            </div>
                        </div>
                    </div>

                    <!-- Profile Selection -->
                    <div class="ml-2" v-if="showProfile">
                        <div class="country-dropdown mb-0">
                            <div class="form-group form-select multiselect-grp mb-0">
                                <span class="float-label">
                                    <label for="roles">Select Profile</label>
                                    <multiselect
                                        placeholder="Select Profile"
                                        v-model="profile" 
                                        :show-labels="false" 
                                        :options="profile_options" 
                                        :searchable="false">
                                    </multiselect>  
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Add View Single Button -->
                <router-link to="/crowdfunding/list" class="btn btn-primary btn-lg ml-2 ml-md-0 mt-3 mt-lg-0" v-if="displayViewAllCampaigns">View All Campaigns</router-link>
                <router-link to="/pay-transfer/beneficiaries" class="btn btn-primary btn-lg ml-2 ml-md-0 mt-3 mt-lg-0" v-if="displaybeneficiaries">View Beneficiaries</router-link>
                <pay-or-transfer-model-button class="ml-2 ml-md-0 mt-3 mt-lg-0" v-if="showPayorTransferButton"></pay-or-transfer-model-button>
             </div>
         </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import PayorTransferModel from '@/components/core/PayorTransferModel.vue'
export default {
    components: {
        Multiselect,
        'pay-or-transfer-model-button': PayorTransferModel
    },computed: {
        crumbs: function() {
            let pathArray = this.$route.path.split("/")
            pathArray.shift()
            let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
                breadcrumbArray.push({
                path: path,
                to: breadcrumbArray[idx - 1]
                    ? "/" + breadcrumbArray[idx - 1].path + "/" + path
                    : "/" + path,
                text: this.$route.matched[idx].meta.breadCrumb || path,
                });
                return breadcrumbArray;
        }, [])
        return breadcrumbs;
        },

        isAllSelected: function() {
            return  (this.country.length === this.countrys_options.length)
        }
    },props: {
        showFilter : Boolean,
        showCountry : Boolean,
        showDateFilter: Boolean,
        showInputFilter:Boolean,
        showProfile:Boolean,
        showPayorTransferButton: Boolean,
        displayViewAllCampaigns: Boolean,
        displaybeneficiaries: Boolean,
        breadCrumbIteams:Array
    },methods:{
        layoutFullwidth(){
            this.show = !this.show;
            let sitebody = document.body;
            this.show ? sitebody.classList.add("layout-fullwidth") : sitebody.classList.remove("layout-fullwidth");
        },

        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.country[0].name
            }else if(count == this.countrys_options.length){
                this.isAllChecked = true;
                return count + ' Countrys Selected.'
              
            }else {
                this.isAllChecked = false;
                return  count + ' Countrys Selected.'
            }
        },

        selectAllChanged() {
            if (this.isAllSelected) {
                this.isAllChecked = false;
                this.country = [];
                this.countrys_options.map((data)=>{
                    data.isChecked = false
                });
            }else{
                this.isAllChecked = true;
                this.country = this.countrys_options.slice();
                this.countrys_options.map((data)=>{
                    data.isChecked = true
                });
            }
        },

        isCheckedInput (actionName) {
            this.countrys_options[actionName.id].isChecked = !this.countrys_options[actionName.id].isChecked
        }
    },data (){
        return {
            isAllChecked:false,
            show:false,
            meta:'',
            country: [{name: 'India'}],
            countrys_options: [
                { id:0,name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round",isChecked: true},
                { id:1,name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",isChecked: false},
                { id:2,name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round",isChecked: false},
                { id:3,name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round",isChecked: false},
            ],
            profile: 'PayAngel',
            profile_options: ['PayAngel', 'StandApp', 'Thememakker'],
            autoApplyDateRange: false,
            isVisibleFilter: false,
            opens: 'right',
            presets: [
                {
                    label: 'Today',
                    range: [
                        moment().startOf('day'),
                        moment().endOf('day')
                    ]
                },
                {
                    label: 'Yesterday',
                    range: [
                        moment().subtract(1, 'day').startOf('day'),
                        moment().subtract(1, 'day').endOf('day')
                    ]
                },
                {
                    label: 'Last 7 Days',
                    range: [
                        moment().subtract(7, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Week',
                    range: [
                        moment().startOf('week'),
                        moment().endOf('week')
                    ]
                },
                {
                    label: 'Last Week',
                    range: [
                        moment().subtract(1, 'week').startOf('week'),
                        moment().subtract(1, 'week').endOf('week'),
                    ]
                },
                {
                    label: 'Last 30 Days',
                    range: [
                        moment().subtract(30, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Month',
                    range: [
                        moment().startOf('month'),
                        moment().endOf('month')
                    ]
                },
                {
                    label: 'Last Month',
                    range: [
                        moment().subtract(1,'months').startOf('month'),
                        moment().subtract(1,'months').endOf('month')
                    ]
                },
                {
                    label: 'This Year',
                    range: [
                        moment().startOf('year'),
                        moment().endOf('year')
                    ]
                },
                {
                    label: 'Last Year',
                    range: [
                        moment().subtract(1,'year').startOf('year'),
                        moment().subtract(1,'year').endOf('year'),
                    ]
                }
            ],
        }
    },created() {
        this.meta = this.$route.meta.title;
    }
};
</script>
<style scoped>
.echart-line-chart{
    height: 20px;
    width: 112px;
}
</style>