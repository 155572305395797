<template>
     <section class="main-content">
        <div class="cms-content">
            <div class="container">
                <div class="row justify-content-center">
                    <!-- smartwizard -->
                    <bank-account-wizard :wizardTimeLines="wizardTimeLines"/>

                    <div class="col-lg-8 col-md-10 col-sm-12">
                        <ul class="wizard-mobile list-unstyled">
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.activeTimeLines" :key="'active_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link" :class="{'filled':timeline.filledClass,'active':timeline.activeClass}">
                                    <span>{{ timeline.name }}</span>
                                    <i :class="timeline.classList"></i>
                                </router-link>
                            </li>
                            <li class="main-form-area">
                                <ValidationObserver v-slot="{ passes }">
                                    <form class="contact-detail" @submit.prevent="passes(contactDetailsSubmit)" autocomplete="off">
                                        <div class="card card-top-line">
                                            <div class="card-body">
                                                <h2 class="page-title">Your Contact Details</h2>
                                                <div class="row">
                                                    <div class="col-md-6 col-12">
                                                        <ValidationProvider name="contact_email" rules="required|email" v-slot="{ errors}" vid="confirmation">
                                                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.contact_email || errors[0],'success':accountDetails.contact_email && !errors[0], 'mb-0':apiErrorCode == 'V0055-000'}">
                                                                <span class="float-label">
                                                                    <input type="email" v-model="accountDetails.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Email*" :disabled="true">
                                                                    <label for="contact_email" class="control-label">Email*</label>
                                                                    <!-- <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.contact_email || errors[0] "></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.contact_email && !errors[0]"></i> -->
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0055-000'">{{apiErrorMessage}}</span>
                                                        <ValidationProvider name="confirm_email" rules="required|email|confirmed:confirmation" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.confirm_email || errors[0],'success':accountDetails.confirm_email && !errors[0], 'mb-0':apiErrorCode == 'V0055-001'}">
                                                                <span class="float-label">
                                                                    <input type="email" v-model="accountDetails.confirm_email" class="form-control" name="email1" id="confirm_email" placeholder="Confirm Email*" :disabled="true">
                                                                    <label for="confirm_email" class="control-label">Confirm Email*</label>
                                                                    <!-- <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.confirm_email || errors[0] "></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.confirm_email && !errors[0]"></i> -->
                                                                </span>
                                                                <span v-if="accountDetails.displayConfirmPopup = accountDetails.confirm_email && !errors[0]"/>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0055-001'">{{apiErrorMessage}}</span>
                                                        <div class="country-dropdown">
                                                            <div class="form-group form-select single-multiselect">
                                                                <span class="float-label">
                                                                    <label for="Country">Country</label>
                                                                    <multiselect
                                                                        placeholder=""
                                                                        v-model="accountDetails.contact_country" 
                                                                        :show-labels="false" 
                                                                        :allow-empty="false"
                                                                        :options="countrys_options"  
                                                                        label="name" 
                                                                        track-by="name"
                                                                        @select="setPrimaryNumberCountryCode"
                                                                        :disabled="true">
                                                                        <template slot="option" slot-scope="props">
                                                                            <div class="option-title">
                                                                                <span class="mr-2" :class="props.option.flagClass"></span>
                                                                                <span>{{ props.option.name }}</span>
                                                                            </div>
                                                                        </template>
                                                                    </multiselect> 
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group" :class="{'errors': mobileInputError == false,'success':accountDetails.primaryNumber && mobileInputError}">
                                                            <div class="float-label" v-if="primaryMobileNumber.defaultCountry">
                                                                <label for="primary_number" class="control-label">Primary Phone Number*</label>
                                                                <vue-tel-input
                                                                    v-model="accountDetails.primaryNumber"
                                                                    v-bind="primaryMobileNumber"
                                                                    @open="onPrimaryDropdownOpen(true)"
                                                                    @close="onPrimaryDropdownOpen(false)"
                                                                    @validate ="mobileInputValidate"
                                                                    ref="telInput"
                                                                    @keydown.native="restrictSpecialCharacter($event)"
                                                                    :disabled="true"
                                                                >
                                                                <template v-slot:arrow-icon>
                                                                    <span class="vti__dropdown-arrow">{{ primaryDropdawnOpen ? '▲' : '▼' }}</span>
                                                                </template>
                                                                </vue-tel-input>
                                                                <!-- <div class="line"></div>
                                                                <i class="fa fa-times" v-if="mobileInputError == false"></i>
                                                                <i class="fa fa-check" v-if="mobileInputError && accountDetails.primaryNumber"></i> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-12">
                                                        <ValidationProvider name="address" rules="required" v-slot="{ errors}">
                                                            <div class="form-group address-picker" :class="{'errors': errors.length && !accountDetails.address,'success':accountDetails.address, 'mb-0':apiErrorCode == 'V0055-008'}">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="accountDetails.address" class="form-control" id="address" placeholder="Address*" v-on:keyup="loqateAddress($event,'address')" @input="capitalizeFirstLetter($event,'address')">
                                                                    <label for="address" class="control-label">Address*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.address"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.address"></i>
                                                                </span>
                                                                <span class="text-danger error-msg" v-if="fieldName == 'address'">{{fieldErrorMsg}}</span>
                                                                <ul v-if="addressData!='' && fieldType=='address'">
                                                                    <li class="" v-for="item in addressData" :key="item.Id" @click="SelectAddress(item.Id, item.Type)">{{ item.Text }}, {{ item.Description }}
                                                                        <i class="fa fa-angle-right" v-if="item.Type!='Address'"></i>
                                                                    </li>    
                                                                </ul>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0055-008'">{{apiErrorMessage}}</span>
                                                        
                                                        <ValidationProvider name="city" rules="required" v-slot="{ errors}">
                                                            <div class="form-group address-picker" :class="{'errors': errors.length && !accountDetails.city,'success':accountDetails.city, 'mb-0':apiErrorCode == 'V0055-005'}">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="accountDetails.city" class="form-control" id="city" placeholder="City*" v-on:keyup="loqateAddress($event,'city')" @input="capitalizeFirstLetter($event,'city')">
                                                                    <label for="city" class="control-label">City*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.city"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.city"></i>
                                                                </span>
                                                                <span class="text-danger error-msg" v-if="fieldName == 'city'">{{fieldErrorMsg}}</span>
                                                                <ul v-if="addressData!='' && fieldType=='city'">
                                                                    <li class="" v-for="item in addressData" :key="item.Id" @click="SelectAddress(item.Id, item.Type)">{{ item.Text }}, {{ item.Description }}
                                                                        <i class="fa fa-angle-right" v-if="item.Type!='Address'"></i>
                                                                    </li>    
                                                                </ul>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0055-005'">{{apiErrorMessage}}</span>
                                                        <ValidationProvider name="postCode" rules="required" v-slot="{ errors}">
                                                            <div class="form-group address-picker" :class="{'errors': errors.length && !accountDetails.postCode,'success':accountDetails.postCode, 'mb-0':apiErrorCode == 'V0055-010'}">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="accountDetails.postCode" class="form-control" id="postCode" placeholder="Post Code*" v-on:keyup="loqateAddress($event,'postcode')">
                                                                    <!-- maxlength="6"  @keydown="checkDecimals($event)"  @keyup="handleInput($event)"-->
                                                                    <label for="postCode" class="control-label">Post Code*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.postCode"></i>
                                                                    <i class="fa fa-check" v-if="accountDetails.postCode"></i>
                                                                </span>
                                                                <ul v-if="addressData!='' && fieldType=='postcode'">
                                                                    <li class="" v-for="item in addressData" :key="item.Id" @click="SelectAddress(item.Id, item.Type)">{{ item.Text }}, {{ item.Description }}
                                                                        <i class="fa fa-angle-right" v-if="item.Type!='Address'"></i>
                                                                    </li>    
                                                                </ul>
                                                            </div>
                                                        </ValidationProvider>
                                                        <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0055-010'">{{apiErrorMessage}}</span>
                                                        <div class="form-group">
                                                            <div class="float-label" v-if="secondaryMobileNumber.defaultCountry">
                                                                <label for="secondary_number" class="control-label">Secondary Phone Number</label>
                                                                <vue-tel-input
                                                                    v-model="accountDetails.secondaryNumber" 
                                                                    v-bind="secondaryMobileNumber"
                                                                    v-on:country-changed="countryChanged"
                                                                    @open="onSecondaryDropdownOpen(true)"
                                                                    @close="onSecondaryDropdownOpen(false)"
                                                                >
                                                                <!-- @keydown.native="restrictSpecialCharacter($event)" -->
                                                                <template v-slot:arrow-icon>
                                                                    <span class="vti__dropdown-arrow">{{ secondaryDropdownOpen ? '▲' : '▼' }}</span>
                                                                </template>
                                                                </vue-tel-input>
                                                                <div class="line"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-end flex-column flex-lg-row">
                                                    <div class="d-flex align-items-center justify-content-between flex-column flex-sm-row">
                                                        <router-link to="/open-bank-account/employment" class="btn btn-outline-danger btn-mw mr-sm-1 mr-0 my-sm-0 my-2 btn-lg" @click.native="removesummary()">Back</router-link>
                                                        <b-button class="btn-mw" type="submit" variant="primary" size="lg">Next</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </li>
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.inActiveTimeLines" :key="'inActive_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link"><span>{{ timeline.name }}</span>
                                <i :class="timeline.classList"></i></router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- logo footer -->
                <bank-account-logo-footer/>
                <!-- Expiry Token Modal -->
                <expiry-token-modal></expiry-token-modal>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </section>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
const BankAccountLogoFooter = require('@/components/admin/open_bank_account_v1/BankAccountLogoFooter.vue').default
const BankAccountFormWizard = require('@/components/admin/open_bank_account_v2/BankAccountFormWizard.vue').default
const { VueTelInput } = require('vue-tel-input')
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
const ExpiryTokenModal = require('@/components/admin/open_bank_modal/ExpiryTokenModal.vue').default
import parsePhoneNumber from 'libphonenumber-js'

export default {
    name:'ContactDetailsV2Component',
    components:{
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        'bank-account-logo-footer':BankAccountLogoFooter,
        'bank-account-wizard':BankAccountFormWizard,
        VueTelInput,
        'main-loader':LoaderComponent,
        'expiry-token-modal':ExpiryTokenModal
    },
    data(){
        return{
            accountDetails:{
                contact_email:'',
                confirm_email:'',
                contact_country:{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",primaryNumberCode:'IN'},
                city:'',
                address:'',
                postCode:'',
                primaryNumber:"",
                secondaryNumber:'',
                primary_iso_code:"",
                secondary_iso_code:""
            },
            searchText:'',
            addressData:[],
            fieldType:'',

            primaryDropdawnOpen:false,
            secondaryDropdownOpen:false,
            mobileInputError:'',
            secondaryContactISO:'',
            timeoutModalShow:false,
            
            apiErrorCode:'',
            apiErrorMessage:'',

            primaryMobileNumber: {
                // mode: "auto", 
                // defaultCountry: 'US',
                // autoFormat:false,
                // dropdownOptions:{
                //     showFlags: true,
                //     disabled:true,
                //     showDialCodeInSelection:true
                // },
            },

            secondaryMobileNumber: {
                // mode: "auto", 
                // defaultCountry: "GB",
                // autoFormat:false,
                // dropdownOptions:{
                //     showFlags: true,
                //     // disabled:true,
                //     showDialCodeInSelection:true
                // }
            },

            countrys_options:[
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round", primaryNumberCode:'GB'},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round", primaryNumberCode:'US'},
                { name: 'Germany', flagClass:"flag-icon flag-icon-de fi-xl fi-round", primaryNumberCode:'DE'},
                { name: 'Italy', flagClass: "flag-icon flag-icon-it fi-xl fi-round", primaryNumberCode:'IT'},
                { name: 'Australia', flagClass: "flag-icon flag-icon-au fi-xl fi-round", primaryNumberCode:'AU'},
                { name: 'Canada', flagClass: "flag-icon flag-icon-ca fi-xl fi-round", primaryNumberCode:'CA'},
                { name: 'Netherlands', flagClass: "flag-icon flag-icon-nl fi-xl fi-round", primaryNumberCode:'NL'},
                { name: 'Spain', flagClass: "flag-icon flag-icon-es fi-xl fi-round", primaryNumberCode:'ES'},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round", primaryNumberCode:'FR'}
            ],

            wizardTimeLines:[
                {
                    name:'Account Specification',
                    links:'/open-bank-account/account-specification',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Personal Information',
                    links:'/open-bank-account/personal-information',
                    filledClass:false,
                    activeClass:true
                },
                {
                    name:'Next of Kin',
                    links:'/open-bank-account/your-next-of-kin',
                    filledClass:false,
                    activeClass:false
                },
                {
                    name:'Required Documents',
                    links:'/open-bank-account/upload-passport-size-photo',
                    filledClass:false,
                    activeClass:false
                },
                {
                    name:'Confirm and Sign',
                    links:'/open-bank-account/declarations-and-signatures',
                    filledClass:false,
                    activeClass:false
                }
            ],

            mobileViewTimeLine:{
                activeTimeLines:[
                    {
                        name: 'Account Specification',
                        link: '/open-bank-account/account-specification',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Personal Information',
                        link: '/open-bank-account/personal-information',
                        classList: 'fa fa-chevron-down',
                        filledClass:false,
                        activeClass:true
                    },
                ],

                inActiveTimeLines:[
                    {
                        name: 'Next of Kin',
                        link: '/open-bank-account/your-next-of-kin',
                        classList: 'fa fa-chevron-right'
                    },
                    {
                        name: 'Required Documents',
                        link:'/open-bank-account/upload-passport-size-photo',
                        classList: 'fa fa-chevron-right'
                    },
                    {
                        name: 'Confirm and Sign',
                        link: '/open-bank-account/declarations-and-signatures',
                        classList: 'fa fa-chevron-right'
                    }
                ]
            }
        }
    },methods:{
        contactDetailsSubmit(){
            // if(typeof(this.mobileInputError) == 'undefined'){
            //     this.mobileInputError = false
            // }

            // if(this.mobileInputError){
                this.$refs.loader.show();
                // console.log('submit',this.accountDetails.contact_country.primaryNumberCode);
                const primaryNumber = parsePhoneNumber(this.accountDetails.primaryNumber, this.accountDetails.contact_country.primaryNumberCode);
                const secondaryNumber = this.accountDetails.secondaryNumber ? parsePhoneNumber(this.accountDetails.secondaryNumber, this.secondaryContactISO) : '';
                let contactDetails = {
                    application_id:sessionStorage.getItem("applicationId"),
                    email:this.accountDetails.contact_email,
                    confirm_email:this.accountDetails.confirm_email,
                    country:this.accountDetails.contact_country.name,
                    city:this.accountDetails.city,
                    address:this.accountDetails.address,
                    post_code:this.accountDetails.postCode,
                    primary_iso_code:this.accountDetails.contact_country.primaryNumberCode,
                    secondary_iso_code:this.secondaryContactISO,
                    secondary_phone: this.accountDetails.secondaryNumber ? secondaryNumber.number : '', //this.accountDetails.secondaryNumber,
                    primary_phone:primaryNumber.number, //this.accountDetails.primaryNumber
                }
                // console.log(contactDetails);
                this.$http.post(this.$baseurl+'bankAccount/contact-details/', contactDetails, {
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                    },
                })
                .then((res) => {
                    //Perform Success Action
                    // console.log(res.data);
                    
                    if(res.data.code == '0055-000') {
                        sessionStorage.removeItem('personalInfo');
                        this.$refs.loader.hide();
                        if(sessionStorage.getItem("pagename") && sessionStorage.getItem("pagename") == 'Summary'){
                            sessionStorage.removeItem("pagename")
                            this.$router.push({path:'/open-bank-account/summary'});
                        } else {
                            this.$router.push({path:'/open-bank-account/your-next-of-kin'})
                        }
                    } else {
                        this.$refs.loader.hide();
                        this.$swal('Error!', res.message, 'error', 'Close');
                    }
                })
                .catch((error) => {
                    // error.response.status Check status code
                    this.$refs.loader.hide();
                    this.apiErrorCode = error.response.data.code;
                    this.apiErrorMessage = error.response.data.message;
                });                 
            // }
        },

        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },

        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },

        onPrimaryDropdownOpen(primarynumber){
            this.primaryDropdawnOpen = primarynumber
        },

        onSecondaryDropdownOpen(secondarynumber){
            this.secondaryDropdownOpen = secondarynumber
        },

        setPrimaryNumberCountryCode(defaultCountry){
            this.$refs.telInput.choose(defaultCountry.primaryNumberCode)
        },

        countryChanged(country) {
            this.secondaryContactISO = country.iso2;
        },

        checkDecimals (e) {
            let stringValue = e.target.value.toString();
            let regex = /^[0-9]*(\.[0-9]{0,0})?$/
            if(!stringValue.match(regex) && e.key != "Backspace" && e.key != "Tab" && e.key != "Control" && e.key != "Shift") {
                e.preventDefault();
            }
        },
        handleInput (e) {
            let stringValue = e.target.value.toString();
            let regex = /^[0-9]*(\.[0-9]{0,0})?$/
            if(!stringValue.match(regex) && e.key != "Backspace" && e.key != "Tab" && e.key != "Control" && e.key != "Shift") {
                this.accountDetails.grossIncome = e.target.value = stringValue.slice(0, -1);
                e.preventDefault();
            }
        },

        async getContactdata() {
            this.$http.get(this.$baseurl+'bankAccount/bank-user-information/', { 
                params: {application_id: sessionStorage.getItem("applicationId")}, 
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                }
            }).then((res) => {
                //Perform Success Action
                if(res.data.code == '0059-000' && res.data.contact_details.contact_id != ''){
                    let contactData = res.data.contact_details;
                    const primaryphoneNumber = parsePhoneNumber(contactData.primary_phone, contactData.primary_iso_code);
                    const secondaryphoneNumber = parsePhoneNumber(contactData.secondary_phone, contactData.secondary_iso_code);
                    this.accountDetails.contact_email = contactData.email;
                    this.accountDetails.confirm_email = contactData.confirm_email;
                    this.accountDetails.contact_country = { name: contactData.country, primaryNumberCode:contactData.primary_iso_code };
                    this.accountDetails.city = contactData.city;
                    this.accountDetails.address = contactData.address;
                    this.accountDetails.postCode = contactData.post_code;
                    this.accountDetails.primaryNumber = primaryphoneNumber.nationalNumber, //contactData.primary_phone;
                    this.accountDetails.secondaryNumber = contactData.secondary_phone.length > 3 ? secondaryphoneNumber.nationalNumber : '', //contactData.secondary_phone;
                    this.accountDetails.primary_iso_code = contactData.primary_iso_code;
                    this.accountDetails.secondary_iso_code = contactData.secondary_iso_code;
                    this.primaryMobileNumber = {
                        mode: "auto", 
                        defaultCountry: contactData.primary_iso_code,
                        autoFormat:false,
                        dropdownOptions:{
                            showFlags: true,
                            disabled:true,
                            showDialCodeInSelection:true
                        },
                    };
                    this.secondaryMobileNumber = {
                        mode: "auto", 
                        defaultCountry: contactData.secondary_iso_code,
                        autoFormat:false,
                        dropdownOptions:{
                            showFlags: true,
                            // disabled:true,
                            showDialCodeInSelection:true
                        }
                    };
                } else {
                    if(res.data.specification_id == '' && res.data.personal_information.user_info_id == '' && res.data.employment.employment_id == '') {
                        this.$swal('Warning!', 'Please complete account specification first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/account-specification'});
                    } else if(res.data.personal_information.user_info_id == '' && res.data.employment.employment_id == '') {
                        this.$swal('Warning!', 'Please complete personal information first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/personal-information'});
                    } else if(res.data.employment.employment_id == '') {
                        this.$swal('Warning!', 'Please complete employment details first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/employment'});
                    } else {
                        let personalInformation = res.data.user_profile;//JSON.parse(sessionStorage.getItem("personalInfo"));
                        const phoneNumber = parsePhoneNumber(personalInformation.phone_number, personalInformation.primary_iso_code);
                        console.log(personalInformation.primary_iso_code);
                        this.accountDetails.contact_email = personalInformation.email;
                        this.accountDetails.confirm_email = personalInformation.email;
                        this.accountDetails.contact_country = { name: personalInformation.country, primaryNumberCode:personalInformation.primary_iso_code };
                        this.accountDetails.primary_iso_code = personalInformation.primary_iso_code;
                        this.accountDetails.primaryNumber = phoneNumber.nationalNumber, //personalInformation.phone_number,
                        this.defaultCountry = personalInformation.primary_iso_code;
                        this.primaryMobileNumber = {
                            mode: "auto", 
                            defaultCountry: personalInformation.primary_iso_code,
                            autoFormat:false,
                            dropdownOptions:{
                                showFlags: true,
                                disabled:true,
                                showDialCodeInSelection:true
                            }
                        };
                        this.secondaryMobileNumber = {
                            mode: "auto", 
                            defaultCountry: 'GB',
                            autoFormat:false,
                            dropdownOptions:{
                                showFlags: true,
                                // disabled:true,
                                showDialCodeInSelection:true
                            }
                        };
                        if(sessionStorage.getItem("customerDetail")) {
                            let jsonDetail = JSON.parse(sessionStorage.getItem("customerDetail"));
                            this.accountDetails.city = jsonDetail.city;
                            this.accountDetails.address = jsonDetail.address_line1;
                            this.accountDetails.postCode = jsonDetail.zip_code;
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                // this.apiErrorCode = error.response.data.code;
            })
        },

        loqateAddress: function($event,fieldType){
            let searchValue = $event.target.value;
            this.searchText = searchValue;
            this.fieldType = fieldType;
            var that = this;
            setTimeout(function() {
                if(searchValue == ''){
                    that.addressData = '';
                } else {
                    let findAddressData = {
                        address : searchValue,
                        country : that.accountDetails.primary_iso_code.toUpperCase(),
                        type : "Address",
                        container_id : ""
                    }
                    // console.log(findAddressData);
                    that.$http.post('https://stand-app.com/api/v2/find-address/', findAddressData).then((res) => {
                        if(res.data.code == '0021-000'){
                            that.addressData = res.data.Items;
                        } else {
                            that.addressData = '';
                        }
                    }).catch((error) => {
                        console.log(error);
                        that.addressData = '';
                        // this.apiErrorCode = error.response.data.code;
                    })
                }
            }, 2000);
        },
        SelectAddress(id,type){
            var that = this;
            if(type != 'Address') {
                setTimeout(function() {
                    let findAddressData = {
                        address : that.searchText,
                        country : that.accountDetails.primary_iso_code.toUpperCase(),
                        type : type,
                        container_id : id
                    }
                    // console.log(findAddressData);
                    that.$http.post('https://stand-app.com/api/v2/find-address/', findAddressData).then((res) => {
                        if(res.data.code == '0021-000'){
                            that.addressData = res.data.Items;
                        } else {
                            that.addressData = '';
                        }
                    }).catch((error) => {
                        console.log(error);
                        that.addressData = '';
                    })
                }, 2000);
            } else {
                let retrieveAddressData = {
                    id : id
                }
                that.$http.post('https://stand-app.com/api/v2/retrieve-address/', retrieveAddressData).then((res) => {
                    if(res.data.code == '0020-000'){
                        let selectedAddress = res.data.Items[0];
                        let line2 = '';
                        if(selectedAddress.Line2 != ''){
                            line2 = selectedAddress.Line2 +', ';
                        }
                        that.accountDetails.address = selectedAddress.Line1 +', '+ line2 + selectedAddress.City +', '+ selectedAddress.PostalCode +', '+ selectedAddress.CountryName; // selectedAddress.Label.split("\n").join(", ");
                        that.accountDetails.city = selectedAddress.City;
                        that.accountDetails.postCode = selectedAddress.PostalCode;
                        that.searchText = '';
                        that.fieldType = '';
                        that.addressData = '';
                    } 
                    // else {
                    //     that.addressData = '';
                    // }
                }).catch((error) => {
                    console.log(error);
                    // this.apiErrorCode = error.response.data.code;
                })
            }
        },

        capitalizeFirstLetter(event,fieldName){
            var splitStr = event.target.value.split(' ');
            if(splitStr[0] == ''){
                this.fieldName = fieldName;
                this.fieldErrorMsg = 'Please provide minimum two characters.';
            } else {
                this.fieldErrorMsg = '';
                this.fieldName = '';
                for (var i = 0; i < splitStr.length; i++) {
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                }
                // Directly return the joined string
                this.accountDetails[fieldName] = splitStr.join(' ');
            }
        },

        tokenExpired: function() {
            if(sessionStorage.getItem('tokenExpiryTime')){
                if(!this.timeoutModalShow) {
                    const expiryTime = sessionStorage.getItem('tokenExpiryTime').split('.');
                    const currentDateTime = new Date().toISOString().split('.');
                    if(expiryTime[0] == currentDateTime[0]){
                        this.$bvModal.show('expiry_token');
                        this.timeoutModalShow = true;
                    }
                }   
            }             
        },

        removesummary(){
            if(sessionStorage.getItem("pagename")){
                sessionStorage.removeItem("pagename")
            }
        }
    },created() {
        setInterval(this.tokenExpired, 1000);

        if(sessionStorage.getItem("applicationId")){
            this.getContactdata();
        } else {
            this.$swal('Warning!', 'Please start application here', 'warning', 'Close');
            this.$router.push({path:'/'});
        }
    }
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
