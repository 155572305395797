<template>
    <div>
        <HeaderComponent/>
        <SidebarComponent/>
        <div id="main-content" :class="[footerTypeIs,contentClass]">
            <slot></slot>
        </div>
        <AuthFooterComponent :footerType="footerTypeIs"/>
    </div>
</template>
<script>
import HeaderComponent from '@/components/admin/layouts/header/HeaderComponent.vue'
import SidebarComponent from '@/components/admin/layouts/sidebar/SidebarComponent.vue'
import AuthFooterComponent from '@/components/admin/layouts/footer/AuthFooterComponent.vue'

export default {
    name:'DefaultLayout',
    props: {
        props : String,
    },
    components: {
        HeaderComponent,
        SidebarComponent,
        AuthFooterComponent
    },computed: {
        footerTypeIs() {
            var currentfooterType = '';
            if(this.$route.name == 'cdashboard'){
                currentfooterType = 'client'
            }else{
                currentfooterType = 'admin'
            }
            return currentfooterType;
        },

        contentClass(){
            var currentcontentClass = '';
            if(this.$route.name == 'blog_list' ||  this.$route.name == 'blog_details'){
                currentcontentClass = 'blog-page'
            }else if(this.$route.name == 'typography' || this.$route.name == 'fdocuments' || this.$route.name == 'fimages' || this.$route.name == 'fmedia'){
                currentcontentClass = 'file_manager'
            }else if(this.$route.name == 'pgallery' || this.$route.name == 'ptimeline'){
                currentcontentClass = 'profilepage_1'
            }
            return currentcontentClass;
      }
    }
}
</script>
