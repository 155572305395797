<template>
    <b-modal id="joint_application_mobile_number_varify" title="Verify Mobile Number" hide-footer modal-class="modal-center customer-home-modal" no-stacking no-close-on-backdrop @shown="resetTimer">
        <div class="modal-body">
            <div class="header pb-3 text-center">
                <p class="lead px-3" style="color: green; font-weight: 500;">Ring Ring...</p>
            </div>
            <div class="body">
                <p class="text-center mb-3">Enter the verification code we just sent via text to your mobile phone 
                    <strong>{{mobileNumber}}</strong>
                    <!-- <strong>+44 233 2342 3432</strong> -->
                </p>
                <p class="text-center mb-3"><a class="mobile-number" href="javascript:void(0)" @click="changeNumber()">Change your Mobile Number</a></p>
                <div class="otp-form">
                    <div id="otp" class="d-flex justify-content-center">
                        <div class="form-group">
                            <v-otp-input
                                ref="otpInput"
                                input-classes="otp-input form-control m-2"
                                separator=" "
                                :num-inputs="5"
                                :should-auto-focus="true"
                                :is-input-num="false"
                                @on-complete="handleOnComplete"
                            />
                        </div>
                    </div>
                </div>
                <div class="otp-timer text-center" id="otp_timer">
                    <span v-if="showResendOtp">
                        <p>Code hasn't arrived? 
                            <a class="text-danger" href="javascript:void(0)" @click="resendOtp()">Resend OTP</a>
                        </p>
                        <p class="mt-3 mb-0"> 
                            You may be experiencing mobile network issues, check network settings on your phone
                        </p>
                    </span>
                    <!-- <router-link to="#" @click.native="resendOtp()" v-if="showResendOtp">Resend OTP</router-link> -->
                    <!-- v-if="showResendOtp"  -->
                    <p>
                        <span class="text-danger">{{ wrongOtpMsg }}</span>
                    </p>
                    <p class="mt-3 mb-0" v-if="!showResendOtp"> 
                        You can request a new code if it does not arrive in: 
                        <label class="timer-text text-danger" id="timer_countdown">02:00</label>
                        <!-- <label class="timer-text text-danger" id="timer_countdown"></label> -->
                    </p>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </b-modal>
    
</template>
<script>
const OtpInput = require("@bachdgvn/vue-otp-input").default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
export default {
    name:'JointApplicationOtpModal',
    components:{
        'v-otp-input':OtpInput,
        'main-loader':LoaderComponent,
    },data(){
        return{
            clearTimer:0,
            wrongOtpMsg:'',
            mobileNumber: '',
            personalInfo:'',
            apiErrorCode:'',
            showResendOtp:false,
            applicationId:''
        }
    },methods: {
        //timer counter
        countDownTimer() {
            var duration = 2 * 60
            var timer = duration, minutes, seconds;
            var that = this;
            this.clearTimer = setInterval(function () {
                if(timer > 0) {
                    if(document.getElementById("timer_countdown")){
                        minutes = parseInt((timer / 60)%60, 10)
                        seconds = parseInt(timer % 60, 10);

                        minutes = minutes < 10 ? "0" + minutes : minutes;
                        seconds = seconds < 10 ? "0" + seconds : seconds;
                        document.getElementById("timer_countdown").innerHTML = minutes + ":" + seconds;
                    }
                    --timer;
                    // if(timer <= 0){
                    //     that.showResendOtp = true;
                    // } else {
                    //     that.showResendOtp = false;
                    // }
                } else {
                    document.getElementById("timer_countdown").innerHTML = "00:00";
                    clearInterval(this.clearTimer);
                    this.clearTimer = 0;
                    if(timer <= 0){
                        that.showResendOtp = true;
                    } else {
                        that.showResendOtp = false;
                    }
                }
            }, 1000);
        },
        
        //reset timer 
        resetTimer() {
            clearInterval(this.clearTimer)
            this.countDownTimer();
            if(sessionStorage.getItem("personalInfo")){
                let personalinfo = JSON.parse(sessionStorage.getItem("personalInfo"));
                this.mobileNumber = personalinfo.phone_number;
            } else {
                this.mobileNumber = '';
            }
        },

        resendOtp(){
            // this.showResendOtp = false;
            // clearInterval(this.clearTimer);
            // return this.countDownTimer();
            let personalinfo = JSON.parse(sessionStorage.getItem("personalInfo"));
            let resendOtpData = {
                phone_number:personalinfo.phone_number,
                first_name:personalinfo.first_name
            }
            this.$http.post(this.$baseurl+'user/send-otp/', resendOtpData).then((res) => {
                //Perform Success Action
                if(res.data.code == '0061-000') {
                    this.showResendOtp = false;
                    clearInterval(this.clearTimer);
                    return this.countDownTimer();
                } else {
                    this.$swal('Error!', res.data.message, 'error', 'Close');
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$swal('Error!', error.response.data.message, 'error', 'Close');
            });
        },

        handleOnComplete(value) {
            if(value == '11111'){
                this.wrongOtpMsg = 'Please Enter Valid OTP'
            } else{
                this.mobileVarificationFormSubmit(value);
            }
        },
        
        mobileVarificationFormSubmit: function(value) {
            this.$refs.loader.show();
            let personalInformation = JSON.parse(sessionStorage.getItem("personalInfo"));
            let verifyData = {
                phone_number: personalInformation.phone_number, 
                otp: value 
            }
            console.log(verifyData);
            // this.$http.post(this.$baseurl+'user/verify-otp/', verifyData).then((res) => {
            //     // Perform Success Action
            //     if(res.data.code == '0060-000') {
                    let personalInfo = {
                        email: personalInformation.email,
                        first_name: personalInformation.first_name,
                        last_name: personalInformation.last_name,
                        phone_number: personalInformation.phone_number,
                        country: personalInformation.country,
                        primary_iso_code:personalInformation.primary_iso_code,
                        is_joint: false
                    }
                    this.$http.post(this.$baseurl+'user/users/', personalInfo).then((res) => {
                        // console.log(res.data.application_id);
                        //Perform Success Action
                        if(res.data.code == '0051-000') {
                            sessionStorage.setItem("jwtAccessToken", res.data.access);
                            sessionStorage.setItem("refreshToken", res.data.refresh);
                            sessionStorage.setItem("applicationId", res.data.application_id);
                            sessionStorage.setItem("tokenExpiryTime", res.data.expiry_time);
                            this.$bvModal.show('second_applicant_info');
                        } else {
                            this.$refs.loader.hide();
                            this.$swal('Error!', res.data.message, 'error', 'Close');
                        }
                    })
                    .catch((error) => {
                        this.$refs.loader.hide();
                        this.$swal('Error!', error.response.data.message, 'error', 'Close');
                    });
            //     } else {
            //         this.$swal('Error!', res.data.message, 'error', 'Close');
            //     }
            // })
            // .catch((error) => {
            //     // error.response.status Check status code
            //     // console.log(error.response);
            //     this.$swal('Error!', error.response.data.message, 'error', 'Close');
            // });
        },

        changeNumber(){
            this.$bvModal.show('personal_info');
        }
    },created() {
        clearInterval(this.clearTimer)
        // this.countDownTimer()
        
    },beforeDestroy(){
        clearInterval(this.clearTimer)
    }
}
</script>