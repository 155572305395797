<template>
   <div id="left-sidebar" class="sidebar">
      <div class="">
        <div class="navbar-brand">
            <router-link to="/dashboard/index" v-if="toggleSidebarTheme == ''"><img src="@/assets/payangel_logo.png" alt="PayAngel Logo" class="img-responsive logo"></router-link>
            <router-link to="/dashboard/index" v-if="toggleSidebarTheme == 'full-dark'"><img src="@/assets/payangel_logo_w.png" alt="PayAngel Logo" class="img-responsive logo"></router-link>
            <button type="button" class="btn-toggle-offcanvas btn btn-sm btn-default" v-on:click="backSideMenu()"><i class="lnr lnr-menu fa fa-chevron-circle-left"></i></button>
        </div>
        <div class="user-account">
            <div class="user_div">
                <img  :src="require('@/assets/user.png')" class="rounded-circle user-photo" alt="User Profile Picture">
            </div>
            <div class="dropdown" v-bind:class="{ 'show': dropActive}"  @click="dropActive = !dropActive" v-click-outside="outside">
                <a href="javascript:void(0);" class="dropdown-toggle user-name" data-toggle="dropdown" :aria-expanded="dropActive ? 'true' : 'false'"><strong>Alizee Thomas</strong></a>
                <ul class="dropdown-menu dropdown-menu-right account" v-bind:class="{ 'show': dropActive }" :style="dropActive ? 'top: 100%; position: absolute; transform: translate3d(-52px, 42px, 0px); left: 0px; will-change: transform;' : 'top: 80%; position: absolute; transform: translate3d(-52px, 42px, 0px); left: 0px; will-change: transform;'">
                    <li><router-link to="/setting/profile"><i class="icon-user"></i>My Profile</router-link></li>
                    <li><router-link to="/app/app-inbox"><i class="icon-envelope-open"></i>Messages</router-link></li>
                    <li><a href="javascript:void(0);"><i class="icon-settings"></i>Settings</a></li>
                    <li class="divider"></li>
                    <li><router-link to="/authentication/page-login"><i class="icon-power"></i>Logout</router-link></li>
                </ul>
            </div>
             <span>Admin</span>
        </div> 
          <!-- Nav tabs -->
        <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link"  v-on:click="setActiveNavtab('Menu')" :class="{ 'active show': openNavtab('Menu')}" data-toggle="tab">Menu</a></li>
            <li class="nav-item"><a class="nav-link"  v-on:click="setActiveNavtab('Setting')" :class="{ 'active show': openNavtab('Setting')}" data-toggle="tab"><i class="icon-settings"></i></a></li>                
        </ul>  
          <!-- Tab panes -->
        <div class="tab-content p-l-0 p-r-0">
            <div class="tab-pane" :class="{ 'active show': openNavtab('Menu')}" id="menu">
                <nav id="left-sidebar-nav" class="sidebar-nav">
                    <ul id="main-menu" class="metismenu">
                        <li v-bind:class="{ 'active': currentActiveMenu == 'account' }">
                            <router-link to="/account"><i class="icon-wallet"></i> <span>Accounts</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'pay-transfer' }">
                            <router-link to="/pay-transfer/pay-transfer-details"><i class="fa fa-money"></i> <span>Pay or Transfer</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'transactions' }">
                            <router-link to="/transactions"><i class="fa fa-money"></i> <span>Transactions</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'transactions-v2' }">
                            <router-link to="/transactions-v2"><i class="fa fa-money"></i>Transactions <span class="badge badge-default float-right">v2</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'dashboard-v1' }">
                            <router-link to="/dashboard-v1"><i class="icon-home"></i>Dashboard <span class="badge badge-warning float-right">v1</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'client-dashboard' }">
                            <router-link to="/client-dashboard"><i class="icon-home"></i> <span>Client Dashboard</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'members' }">
                            <router-link to="/members"><i class="fa fa-users"></i><span>Members</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'members-v2' }">
                            <router-link to="/members-v2"><i class="fa fa-users"></i>Members <span class="badge badge-default float-right">v2</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'members-v3' }">
                            <router-link to="/members-v3"><i class="fa fa-users"></i>Members <span class="badge badge-warning float-right">v3</span></router-link>
                        </li>
                        <li :class="{ 'active': currentActiveMenu === 'service'}">
                            <a class="has-arrow" v-on:click="setActive('service')"><i class="fa fa-cog"></i> <span>Services</span></a>
                            <ul v-bind:class="currentActiveMenu == 'service' ? 'collapse in' : 'collapse'">
                                <li v-bind:class="{ 'active':currentRoutePath == '/service/bank-deposits'}"><router-link to="/service/bank-deposits">Bank Deposits</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/service/mobile-money'}"><router-link to="/service/mobile-money">Mobile Money</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/service/airtime'}"><router-link to="/service/airtime">Airtime</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/service/sms'}"><router-link to="/service/sms">SMS</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/service/short-code'}"><router-link to="/service/short-code">USSD Short Code</router-link></li>
                            </ul>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'reconciliations' }">
                            <router-link to="/reconciliations"><i class="fa fa-file"></i> <span>Reconciliations</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'fx-rates' }">
                            <router-link to="/fx-rates"><i class="fa fa-exchange"></i> <span>Fx Rates</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'business' }">
                            <router-link to="/business/list"><i class="fa fa-tasks"></i> <span>Business</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'risk' }">
                            <router-link to="/risk"><i class="fa fa-exclamation-triangle"></i> <span>Risk</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'pricing' }">
                            <router-link to="/pricing"><i class="fa fa-money"></i> <span>Pricing</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'invoice' }">
                            <router-link to="/invoice/list"><i class="fa fa-files-o"></i>Invoices</router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'event' }">
                            <router-link to="/event/list"><i class="fa fa-navicon"></i>Event</router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'crowdfunding' }">
                            <router-link to="/crowdfunding/list"><i class="fa fa-bitcoin"></i>Crowdfunding</router-link>
                        </li>
                        <li :class="{ 'active': currentActiveMenu === 'setting'}">
                            <a class="has-arrow" v-on:click="setActive('setting')"><i class="fa fa-cog"></i> <span>Settings</span></a>
                            <ul v-bind:class="currentActiveMenu == 'setting' ? 'collapse in' : 'collapse'">
                                <li v-bind:class="{ 'active':currentRoutePath == '/setting/user-list'}"><router-link to="/setting/user-list">User Management</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/setting/profile'}"><router-link to="/setting/profile">My Profile</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/setting/setup-profile'}"><router-link to="/setting/setup-profile">Set Up Your Profile</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/setting/channels'}"><router-link to="/setting/channels">Channels</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/setting/preferences'}"><router-link to="/setting/preferences">My Preferences</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/setting/limits'}"><router-link to="/setting/limits">My Limits</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/setting/payouts'}"><router-link to="/setting/payouts">Payouts</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/setting/compliance'}"><router-link to="/setting/compliance">Compliance</router-link></li>
                            </ul>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'cms' }">
                            <router-link to="/cms/list"><i class="fa fa-tasks"></i> <span>CMS</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'page-404' }">
                            <router-link to="/page-404"><i class="fa fa-ban"></i> <span>Not Found</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu == 'report-bug' }">
                            <router-link to="/report-bug"><i class="fa fa-file"></i> <span>Report Bug</span></router-link>
                        </li>
                        <li>
                            <router-link to="/authentication/page-login"><i class="fa fa-sign-out"></i> <span>Logout</span></router-link>
                        </li>
                        <li v-bind:class="{ 'active': currentActiveMenu === 'uikit' }">
                            <a class="has-arrow" v-on:click="setActive('uikit')"><i class="icon-home"></i> <span>UI Kit</span></a>
                            <ul v-bind:class="currentActiveMenu == 'uikit' ? 'collapse in' : 'collapse'">
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/index'}"><router-link to="/uikit/index" >Analytical</router-link></li>   
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/iot'}"><router-link to="/uikit/iot" exact>Iot</router-link></li>                                    
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/app-inbox'}"><router-link to="/uikit/app-inbox">Inbox</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/app-chat'}"><router-link to="/uikit/app-chat">Chat</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/app-calendar'}"><router-link to="/uikit/app-calendar">Calendar</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/app-contact-grid'}"><router-link to="/uikit/app-contact-grid">Contact Card <span class="badge badge-warning float-right">New</span></router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/file-documents'}" ><router-link to="/uikit/file-documents">Documents</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/file-media'}" ><router-link to="/uikit/file-media">Media</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/sweet-alert'}" ><router-link to="/uikit/sweet-alert">Sweet Alert</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/file-images'}" ><router-link to="/uikit/file-images">Images</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/blog-post'}" ><router-link to="/uikit/blog-post">New Post</router-link></li>                                
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/blog-list'}" ><router-link to="/uikit/blog-list">Blog List</router-link></li>                                
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/blog-details'}" ><router-link to="/uikit/blog-details">Blog Detail</router-link></li>                                
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/ui-typography'}" ><router-link to="/uikit/ui-typography">Typography</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/ui-tabs'}" ><router-link to="/uikit/ui-tabs">Tabs</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/ui-buttons'}" ><router-link to="/uikit/ui-buttons">Buttons</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/ui-bootstrap'}" ><router-link to="/uikit/ui-bootstrap">Bootstrap UI</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/ui-icons'}" ><router-link to="/uikit/ui-icons">Icons</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/ui-notifications'}" ><router-link to="/uikit/ui-notifications">Notifications</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/ui-colors'}" ><router-link to="/uikit/ui-colors">Colors</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/ui-listgroup'}" ><router-link to="/uikit/ui-listgroup">List Group</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/ui-media'}" ><router-link to="/uikit/ui-media">Media Object</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/ui-modals'}" ><router-link to="/uikit/ui-modals">Modals</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/ui-progressbars'}" ><router-link to="/uikit/ui-progressbars">Progress Bars</router-link></li>                               
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/widgets-data'}" ><router-link to="/uikit/widgets-data">Data</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/widgets-weather'}" ><router-link to="/uikit/widgets-weather">Weather</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/widgets-blog'}" ><router-link to="/uikit/widgets-blog">Blog</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/widgets-ecommerce'}" ><router-link to="/uikit/widgets-ecommerce">eCommerce</router-link></li>                                 
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-lockscreen'}" ><router-link to="/uikit/page-lockscreen">Lockscreen</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-404'}" ><router-link to="/uikit/page-404">Page 404</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-403'}" ><router-link to="/uikit/page-403">Page 403</router-link></li>                                                                                                                                         
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-500'}" ><router-link to="/uikit/page-500">Page 500</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-503'}" ><router-link to="/uikit/page-503">Page 503</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-blank'}" ><router-link to="/uikit/page-blank">Blank Page</router-link></li>                                                            
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-profile'}" ><router-link to="/uikit/page-profile">Profile  <span class="badge badge-default float-right">v2</span></router-link></li>   
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-profile2'}" ><router-link to="/uikit/page-profile2">Profile  <span class="badge badge-warning float-right">v2</span></router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-gallery'}" ><router-link to="/uikit/page-gallery">Image Gallery</router-link></li>     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-timeline'}" ><router-link to="/uikit/page-timeline">Timeline</router-link></li>                                                                                                                                         
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-pricing'}" ><router-link to="/uikit/page-pricing">Pricing</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-invoices'}" ><router-link to="/uikit/page-invoices">Invoices</router-link></li>                                                                                                                                                                                                                                          
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-search-results'}" ><router-link to="/uikit/page-search-results">Search Results</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-helper-class'}" ><router-link to="/uikit/page-helper-class">Helper Classes</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-teams-board'}" ><router-link to="/uikit/page-teams-board">Teams Board</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-projects-list'}" ><router-link to="/uikit/page-projects-list">Projects List</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-maintanance'}" ><router-link to="/uikit/page-maintanance">Maintenance</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-testimonials'}" ><router-link to="/uikit/page-testimonials">Testimonials</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/page-faq'}" ><router-link to="/uikit/page-faq">FAQ</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/forms-validation'}" ><router-link to="/uikit/forms-validation">Form Validation</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/forms-basic'}" ><router-link to="/uikit/forms-basic">Basic Elements</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/table-normal'}" ><router-link to="/uikit/table-normal">Normal Tables</router-link></li>                                                                                                                     
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/chart-echarts'}" ><router-link to="/uikit/chart-echarts">E-Charts</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/uikit/map-leaflet'}" ><router-link to="/uikit/map-leaflet">Leaflet</router-link></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="tab-pane p-l-15 p-r-15" :class="{ 'active show': openNavtab('Setting')}" id="setting">
                <h6>Choose Mode</h6>
                <ul class="choose-skin list-unstyled">
                    <li data-theme="white" :class="{ 'active': data_theme === ''}"  v-on:click="setactiveTheme('')">
                        <div class="white"></div>
                        <span>Light</span>
                    </li>
                    <li data-theme="black" :class="{ 'active': data_theme === 'full-dark'}"  v-on:click="setactiveTheme('full-dark')">
                        <div class="black"></div>
                        <span>Dark</span>
                    </li>
                </ul>
                <hr>
                <h6>Choose Skin</h6>
                <ul class="choose-skin list-unstyled">
                    <li data-theme="radical-red"  v-bind:class="{ 'active': activeColor === 'radical-red'}" v-on:click="changeBodycolor('radical-red')">
                        <div class="radical-red"></div>
                        <span>Radical Red</span>
                    </li>                   
                    <li data-theme="turquoise"  v-bind:class="{ 'active': activeColor === 'turquoise'}" v-on:click="changeBodycolor('turquoise')">
                        <div class="turquoise"></div>
                        <span>Turquoise</span>
                    </li>
                    <li data-theme="midnight-express" v-bind:class="{ 'active': activeColor === 'midnight-express'}" v-on:click="changeBodycolor('midnight-express')">
                        <div class="midnight-express"></div>
                        <span>Midnight Express</span>
                    </li>
                    <li data-theme="morning-glory"  v-bind:class="{ 'active': activeColor === 'morning-glory'}" v-on:click="changeBodycolor('morning-glory')">
                        <div class="morning-glory"></div>
                        <span>Morning Glory</span>
                    </li>
                    <li data-theme="dark-cyan"  v-bind:class="{ 'active': activeColor === 'dark-cyan'}" v-on:click="changeBodycolor('dark-cyan')">
                        <div class="dark-cyan"></div>
                        <span>Dark Cyan</span>
                    </li>
                </ul>
            </div>             
        </div>
      </div>
    </div>    
</template>
<script>
import { EventBus } from '@/plugins/event-bus.js';
export default {
    name: 'SidebarComponent',
    methods:{
        changeBodycolor(color){
            this.activeColor = color
            document.body.className = 'theme-'+color+' '+ this.data_theme;
        },

        outside: function() {
            this.dropActive = false
        },

        setActive: function (menuItem) {
            if (this.currentActiveMenu === menuItem) {
                this.currentActiveMenu = null;
            } else {
                this.currentActiveMenu = menuItem;
            }
        },

        openNavtab: function (tab) {
            return this.currentMenuTab === tab
        },
        setActiveNavtab: function (tab) {
            this.currentMenuTab = tab 
        },
        setactiveTheme: function (theme) {
            this.data_theme = theme
            document.body.className = 'theme-'+this.activeColor +' '+ theme;
            EventBus.$emit('theme',theme) 
        },
        backSideMenu: function () {
            document.body.classList.toggle('offcanvas-active')
        }
    },data() {
        return {
            currentActiveMenu:  this.$route.matched[0].path.replace(/\//,''),
            //currentActiveMenu: window.location.pathname.split("/")[1],
            currentMenuTab: 'Menu',
            activeColor: 'turquoise',
            dropActive: '',
            data_theme:'',
            toggleSidebarClass: false,
            toggleSidebarTheme: '',
        }
    },directives: {
        'click-outside': {
            bind: function(el, binding) {
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                if (bubble || (!el.contains(e.target) && el !== e.target)) {
                    binding.value(e)
                }
                }
                el.__vueClickOutside__ = handler

                // add Event Listeners
                document.addEventListener('click', handler)
            },
        }
    },computed: {
        currentRoutePath() {
            return this.$route.path;
        },
    },watch:{
        $route (){
            //this.currentActiveMenu = to.path.split("/")[1];
            this.currentActiveMenu = this.$route.matched[0].path.replace(/\//,'');
            document.body.className = 'theme-'+this.activeColor +' '+ this.data_theme; 
        }
    },mounted() {
        EventBus.$on('theme', (theme) => {
            this.toggleSidebarTheme = theme
        })
    }
}
</script>

