<template>
    <div class="right-content mx-auto my-auto">
        <ValidationObserver v-slot="{ passes }">
            <div class="header pb-3 text-center">
                <p class="lead px-3">Password to your account</p>
            </div>
            <div class="body">
                <form class="form-auth-small" @submit.prevent="passes(registerSubmit)" id="registration" ref="registration">
                    <ValidationProvider name="password"  rules="required" v-slot="{ errors }" vid="confirmation">
                        <div class="form-group set-password" :class="{'errors': (passwordformdata.passwords != '' && !errorsDisplay) || errors.length > 0,'success': passwordformdata.passwords && errorsDisplay}">
                            <span class="float-label">
                                <input :type="passwordformdata.password_type" @input="password_check" v-model="passwordformdata.passwords" class="form-control" name="password" id="signup-password" placeholder="Password*">
                                <label for="signup-password" class="control-label">Password*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="(passwordformdata.passwords != ''  && !errorsDisplay) || errors.length > 0"></i>
                                <i class="fa fa-check" v-if="passwordformdata.passwords && errorsDisplay"></i>
                                <i class="fa fa-eye" :class="passwordformdata.password_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword('type_1')"></i>
                            </span>
                            <div class="password-validation" :class="{'d-none':passwordformdata.passwords && errorsDisplay}">
                                <p :class="{'error':passwordformdata.passwords.length < 8}"><i :class="passwordformdata.passwords.length > 7 ? 'fa fa-check' : 'fa fa-times'"></i> Longer than 7 characters</p>
                                <p :class="{'error':!has_uppercase}"><i :class="has_uppercase ? 'fa fa-check' : 'fa fa-times'"></i> Has a capital letter</p>
                                <p :class="{'error':!has_lowercase}"><i :class="has_lowercase ? 'fa fa-check' : 'fa fa-times'"></i> Has a lowercase letter</p>
                                <p :class="{'error':!has_number}"><i :class="has_number ? 'fa fa-check' : 'fa fa-times'"></i> Has a number</p>
                                <p :class="{'error':!has_special}"><i :class="has_special ? 'fa fa-check' : 'fa fa-times'"></i> Has a special character</p>
                            </div>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="confirm_password" rules="required|confirmed:confirmation" v-slot="{ errors}">
                        <div class="form-group set-password" :class="{'errors': errors.length && !passwordformdata.confirm_passwords || errors[0],'success':passwordformdata.confirm_passwords && !errors[0]}">
                            <span class="float-label">
                                <input :type="passwordformdata.confirm_type" v-model="passwordformdata.confirm_passwords" class="form-control" name="confirmation_password" id="confirm_password" placeholder="Confirm Password*">
                                <label for="confirm_password" class="control-label">Confirm Password*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !passwordformdata.confirm_passwords || errors[0] "></i>
                                <i class="fa fa-check" v-if="passwordformdata.confirm_passwords && !errors[0]"></i>
                                <i class="fa fa-eye" :class="passwordformdata.confirm_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword('type_2')"></i>
                            </span>
                        </div>
                    </ValidationProvider>
                    <b-button type="submit" block variant="primary" size="lg">CONTINUE</b-button>
                </form>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
export default {
    name:'PasswordComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        registerSubmit() {
            if(this.passwordformdata.passwords.length > 7 && this.has_number && this.has_lowercase &&  this.has_uppercase && this.has_special){
                this.$router.push({ path: '/authentication/page-otp' })
            }
        },

        showPassword(field_name) {
            if(field_name == 'type_1'){
                this.passwordformdata.password_type = this.passwordformdata.password_type === 'password' ? 'text' : 'password';
            }else if(field_name == 'type_2'){
                this.passwordformdata.confirm_type = this.passwordformdata.confirm_type === 'password' ? 'text' : 'password';
            }
        },

        password_check: function () {
            this.has_number    = /\d/.test(this.passwordformdata.passwords)
            this.has_lowercase = /[a-z]/.test(this.passwordformdata.passwords)
            this.has_uppercase = /[A-Z]/.test(this.passwordformdata.passwords)
            this.has_special   = /[^A-Za-z0-9]/.test(this.passwordformdata.passwords)
            if(this.passwordformdata.passwords.length > 7 && this.has_number && this.has_lowercase &&  this.has_uppercase && this.has_special){
                return this.errorsDisplay = true
            }else{
                return this.errorsDisplay = false
            }
        }
    },data(){
        return{
            errorsDisplay:false,
            passwordformdata: {
                robot: false,
                passwords: '',
                confirm_passwords:'',
                password_type: 'password',
                confirm_type: 'password',
            },
            
            has_number:    false,
            has_lowercase: false,
            has_uppercase: false,
            has_special:   false,
        }
    }
}
</script>
<style>

</style>