<template>
    <div class="col-xl-8 col-lg-9 col-md-10 col-sm-12 d-none d-lg-block">
        <div class="wizard-arrow">
            <ul class="nav">
                <li class="nav-item" v-for="(timeline,i) in wizardTimeLines" :key="i">
                    <router-link class="nav-link" :to="timeline.links" :class="{'filled':timeline.filledClass,'active':timeline.activeClass}">
                        {{ timeline.name }}
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name:'BankAccountFormWizard',
    props:{
        wizardTimeLines:Array
    }
}
</script>
