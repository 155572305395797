<template>
<div class="row justify-content-center align-items-center">
    <div class="col-xl-8 col-lg-9 col-md-10 col-sm-12">
        <div class="d-flex justify-content-end align-items-stretch">
            <img class="img-fluid" width="150" src="@/assets/payangel-logo.svg" alt="">
            <span class="border-right ml-3 m-2"></span>
            <img class="img-fluid" width="150" src="https://www.wrraptheme.com/demo/payangel/img/Powered_by_StandApp.559e4fb5.svg" alt="">
        </div>
    </div>
</div>
</template>
<script>
export default {
    name:'BankAccountLogoFooter'
}
</script>