<template>
    <b-modal id="terms_condition" size="lg" title="Terms and Conditions" hide-footer modal-class="modal-center" no-stacking no-close-on-backdrop>
        <div class="modal-body"  style="height: 500px; overflow-y: auto;">
            <p>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITEs (<a href="http://www.payangel.com/">www.payangel.com</a>)</p>
            <p>This agreement is between you and PayInc Group Limited (“PayAngel”, “Us”, “We”, “Our”). These terms and conditions govern your access to and use of PayAngel’s transactional website (referred to hereafter as “our website” for Money Remittance and Associated Services (together referred to hereafter as “our service(s)) that are available on our website.</p>
            <p>By accessing, registering with, and using our service, you agree to be bound by these terms and conditions. It is therefore important that you read and understand our terms and conditions before you accept them. They contain limitations on the scope of our obligations to you, and limitations on and exemptions from our liability to you in the event of any losses suffered as a result of using our service or website. By accepting these terms and conditions, you agree to use our website and service for bona fide and lawful purposes only. You or the Receiver will not use our website and service for any gambling or illegal activities (together referred to as “Prohibited Activities”). We reserve the right to terminate your PayAngel Account and stop any pending transaction if we have reasonable believe that our website is being used for such Prohibited Activities.</p>
            <p>Our Agreement to you under these Terms and Conditions is governed by English Law and the parties submit to the exclusive jurisdiction of English Courts. We both agree that any dispute, claim or other material relating to the PayAngel website or service will be dealt with by only the English Courts.</p>

            <h5 class="content-title">INFORMATION ABOUT US</h5>
            <p><a class="text-decoration-underline" href="http://www.payangel.com/">www.payangel.com</a> is a site operated by PAYINC GROUP LIMITED. We are registered in England and Wales under company number 8126178 and have our registered office and main trading address Regus House, Victory Way, Admirals Park, Dartford, Kent, DA2 6QD, United Kingdom.</p>
            <p>We are a payment institution registered with the Financial Conduct Authority ("FCA") in the United Kingdom as a Payment Services Firm. Our FCA Firm Reference Number is 608108. The FCA Register is available at <a href="www.fca.org.uk/register">www.fca.org.uk/register</a></p>

            <h5 class="content-title">OTHER APPLICABLE TERMS</h5>
            <p>These terms of use refer to the following additional terms, which also apply to your use of our site:</p>
            <p><span class="text-decoration-underline">Privacy Policy</span>, which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using our site, you consent to such processing and you warrant that all data provided by you is accurate.</p>
            <p><span class="text-decoration-underline">Acceptable Use Policy</span>, which sets out the permitted uses and prohibited uses of our site. When using our site, you must comply with this Acceptable Use Policy.</p>
            <p><span class="text-decoration-underline">Cookies Policy</span>, which sets out information about the cookies on our site.</p>

            <h5 class="content-title">DEFINITIONS:</h5>
            <p class="mb-1"><strong>In this terms and conditions:</strong></p>
            <ul>
                <li><strong>"Bank card"</strong> refers to any debit, credit or pre-paid card issued by or through a licensed financial institution</li>
                <li><strong>“Card issuer”</strong> refers to a bank, financial institution, the issuer and owner of a bank card.</li>
                <li><strong>“Customer”</strong> refers to senders and receivers of money transfer and others who make use of our service.</li>
                <li><strong>“Other agreed channels”</strong> refers to remittance instructions via mobile or telephone transfer.</li>
                <li><strong>“Originating Currency”</strong> refers to the currency used to originate the transaction on our website.</li>
                <li><strong>“Our website”</strong> refers to PAYANGEL’s transactional online platform <span class="text-decoration-underline"><a href="http://www.payangel.com/">www.payangel.com</a></span></li>
                <li><strong>“PAYANGEL”,</strong> “<strong>we</strong>”, “<strong>our</strong>” <strong>or</strong> “<strong>us</strong>” refers to PAYINC GROUP LIMITED and associated companies operating under the PAYANGEL trademark.</li>
                <li><strong>“Receiver”</strong> refers to the person(s) identified as the beneficiary(ies) of a money transfer (whether that money transfer is initiated by Sender using our website or by a person using another PAYANGEL transmission channel).</li>
                <li><strong>“Sender”</strong> refers to the person(s) who initiate(s) the money transfer by using our website or other agreed channels.</li>
                <li><strong>“Transaction”</strong> refers to each money transfer or other associated service that initiated on our website or other agreed channels.</li>
                <li><strong>“You”</strong> or "your" refers to any person who uses our Website or our Service, whether as a Sender or as a Receiver.</li>
            </ul>

            <h5 class="content-title">ABOUT OUR SERVICE</h5>
            <p>Our service is offered by PAYANGEL (trading name for PAYINC GROUP LIMITED)</p>
            <p>We offer fast, easy, secure and convenient online money transfer and other associated services both domestically and internationally. You must be at least 18 years old at the time of the transaction to use our service. To use our service to remit funds, you are required to register to open an online account with us. Once you have your login details, there are 3 simple and easy steps to complete your order: create or select your beneficiaries (Receiver) complete payment instructions; and confirm transfer details and make payment.</p>
            <p>By completing the information required to originate a money transfer or other associated service, by providing access to the funds to be sent as well as identification as required and by accepting the Terms and Conditions, the Sender consents to the execution of the money transfer or the other associated service. It is the responsibility of the Sender to inform the Receiver about the money transfer and provide them with the relevant authentication (or verification) details.</p>
            <p>Transfers are usually made available to the receiver via the Sender’s chosen pay-out channel on the same day or on “next day” basis depending on the time of the transfer and the pay-out channel selected. Where longer service is available, funds will be deposited in the Receiver’s account or the prepaid card within minutes after the specified date subject to completion of transaction (i.e. when transfer funds have been received by us).</p>
            <p>For collection of funds in cash, all Receivers will be required to show documentary evidence of their identity and provide any of the following relevant details about the money transfer as required by us, including but not limited to “name of Sender”, “country of origination”, “name of Receiver”, “approximate amount” and any other conditions or requirements applicable at the collection point. Collections points will be advised on your transaction receipt.</p>
            <p>Applicable laws prohibit money remittance companies from doing business with certain individuals and countries; therefore we are required to screen all transactions against lists of names provided by the governments of the countries and territories in which we do business, including the US Treasury Department’s Office of Foreign Assets Control (OFAC) and the European Union. If a potential match is identified, we will research the transaction to determine if the name matched is the individual on the relevant list. On occasion, we may require customers to provide additional identification and other information, thus delaying transactions. These are legal requirements that govern all Transactions processed by us.</p>
            <p><strong>Transfer fees and charges:</strong> We have made available information on our website setting out the fees and / or charges we levy on you, the Sender, for initiating a money transfer through our website or other agreed channels. The fees and / or charges are shown to you, the Sender, prior to completion of the payment order. Unless we are otherwise required by laws in the destination country, the Sender will bear all fees for the money transfer. In certain cases, payment of money transferred may be subject to local taxes and service charges. For Mobile Money transfers, the Receiver may incur service charges depending on how they choose to spend their funds. These merchant or pay-out agent charges vary and do not always apply. It is the responsibility of the Sender to ensure that s/he understands these pay-out charges and make the necessary arrangements to send the receiver enough to cover the charges, if applicable. PayAngel will accept no responsibility for any direct or consequential losses suffered by either the Sender or the receiver as a result of these charges. All transfers are settled in a local (receiver) currency.</p>
            <p><strong>Direct Bank Account Credit:</strong> In the event of an inconsistency between the name associated with account number and name of the Receiver, the transfer may be credited to the account number provided by the Sender otherwise the transfer will be rejected (see also cancellation and refunds for further details).</p>
            <p><strong>Transfer limits:</strong> There are limits on how much you can send. We may decline to send the money or allow it to be collected if we reasonably believe that: (a) by doing so we might break any law, regulation, code or other duty that applies to us; (b) doing so may expose us to action from any government or regulator; or (c) it may be linked with fraudulent or illegal activity.</p>
            <p><strong>Transfer Refusal or Rejection:</strong> To the extent permitted by law or any applicable local regulations, we will endeavour to make all payments whenever possible. If for any reason this is not possible, we will if permitted by law to inform you on the reasons for the rejection of the transaction. We may also return/refund your transaction (see also cancellation and refunds for further details).</p>
            <p class="mb-1">Foreign Exchange:</p>
            <ul>
                <li>All money transfer transactions will be settled in the local (Receiver) currency using our then current rate of exchange. Most rates of exchange may be adjusted several times daily in line with the relevant closing rate of the global financial markets.</li>
                <li>The originating currency will be converted at the time of transfer and the Receiver will receive the local currency amount shown on the transaction confirmation receipt. However, in a few countries local regulations require money transfers to be converted only when they are paid at the destination country. If the Sender is sending to one of these countries, the exchange rate noted on the transaction confirmation receipt will only be an estimate, and the actual exchange rate will be determined at the time of payment. There may be a difference between the exchange rate at which we convert transaction from the originating currency to the local currency and some publicly reported commercial exchange rates used in transactions between banks and other financial institutions.</li>
            </ul>

            <h5 class="content-title">ACCESSING OUR webSITE</h5>
            <p>Our Website is made available free of charge.</p>
            <p>We do not guarantee that our Website, or any content on it, will always be available or be uninterrupted. Access to our Site is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of our Website without notice. We will not be liable to you if for any reason our Website is unavailable at any time or for any period.</p>
            <p>You are responsible for making all arrangements necessary for you to have access to our Website. You are also responsible for ensuring that all persons who access our Website through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</p>

            <h5 class="content-title">REGISTRATION</h5>
            <p>You will be required to register with us by creating a PAYANGEL Account in order to use our website to transfer money or use our other associated Services. Registration is subject to these Terms and Conditions.</p>
            <p>If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</p>
            <p>We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these Terms of Use.</p>
            <p>If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at <a href="customer.experience@payangel.com">customer.experience@payangel.com</a></p>
            
            <h5 class="content-title">COLLECTION OF INFORMATION</h5>
            <p><strong>Customer Identification:</strong> We are required by laws, rules and regulations in the jurisdictions within which we operate to obtain, verify and record identifying information about all our customers to assist in the fight against money laundering activities and terrorist financing.</p>
            <p><strong>Verification and Checks:</strong> Data provided by you to us including (but not limited to) your residential address and personal details will be electronically verified by our relevant partner institutions in order to confirm your identity. We may pass on your personal details to credit reference agencies and they may keep a record of that information. By accepting these terms and conditions, you grant us permission to undertake any enquiries we consider necessary to validate the information you supplied to us.</p>
            <p><strong>Government Disclosures:</strong> We reserve the right to supply information about you and your transaction to government authorities and law enforcement agencies if required by such government authorities and law enforcement agencies.</p>
            <p><strong>Data Privacy:</strong> PAYINC GROUP LIMITED of Regus House, Victory Way, Admirals Park, Dartford, Kent, DA2 6QD, United Kingdom is the data controller for the purpose of the Data Protection Act 1998. By proceeding to use our service you consent that we may process the personal data (including sensitive personal data) that we collect from you in accordance with our Privacy Policy. In particular, we may use information we hold about you for communicating with you, and for statutory, accounting and archival purposes. The data that we collect from you may be transferred to, and stored or processed at, a destination outside the European Economic Area ("EEA"). By submitting your personal data, you agree to this transfer, storing or processing. Please address any questions, comments and requests regarding our data processing practices to <a class="text-decoration-underline" href="admin@payangel.com">admin@payangel.com</a></p>

            <h5 class="content-title">YOUR RESPONSIBILITY TO US</h5>
            <p>You must let us know as soon as possible if you or anyone affiliated to your account change name, address, telephone number, mobile number or e-mail address. If we have to send any refund by say cheque, we will use the most recent contact details you have provided to us. Any e-mail, text message sent to you or anyone affiliated to your account will be treated as being received as soon as it is sent by us. We will not be liable to you or any other person you are affiliated with if the contact details have changed and we have not been informed.</p>
            <p>You are not allowed to use our website or service in violation of this terms and conditions or applicable laws rules and regulations. It is a violation of this terms and conditions to use our website for activities including (but not limited to) gambling; fraud; terrorist financing; money laundering; sending money to receivers who are involved in prohibited and other criminal activities; and any criminal activities.</p>
            <p>You agree to pay our fees and /or charges for each money transfer or other transaction which you initiate or request under our service. Information on our charges can be found on our website, which provides the estimated cost of using our service.</p>
            <p>You must pay for the total amount of the funds remitted and our fees and/or charges by using a bank card, our e-wallet facility or direct bank transfer from your bank account. Each time you use our service, you agree that we are authorised to charge your card for the total funds remitted, our relevant fees and / or charges relating to the transaction and any charitable donation you added before we complete the money transfer or other transaction. You will be notified of the total amount charged prior to your final authorisation of the transaction.</p>
            <p>You agree to ensure that information you supply to us are up to date, true, accurate and complete.</p>
            <p>You agree not to use our Website or Service for any unlawful or criminal activities.</p>
            <p>You are fully responsible for the security of your login details.</p>
            <p>You acknowledge and agree that information about you, and the Service we provide to you, may be provided by us from time to time to regulatory or governmental authorities, bodies or agencies where required by law to do so; or if we deem such disclosure necessary to help fight crime and other unlawful activities.</p>

            <h5 class="content-title">OUR OBLIGATIONS AND LIMITATIONS</h5>
            <p>Subject to these terms and conditions, we undertake to take reasonable care in providing our Service. We agree to provide you with the Service on our Website. Our Service may not be available in whole or in part in some countries, jurisdictions or regions.</p>
            <p>We will endeavour to provide up to date information on our service from time to time to our customers via our website. However, we shall not be responsible for any consequential losses which may result from inaccurate or incomplete information that may appear in our updates.</p>
            <p>We will endeavour to process transactions promptly. However some transactions may be delayed or cancelled in the course of our attempt to: verify your identity; validate your transaction instruction or payment instruments; contact you or locate the receiver, and otherwise comply with applicable law.</p>
            <p>We may, at any time and at our sole discretion, refuse any Transaction or limit the amount to be transferred, either on a single Transaction or on an aggregated basis without prior notice. Any such limits may be imposed on individual accounts, linked accounts, or on related accounts or households, in our sole discretion. We reserve the right at any time, and from time to time, to modify or discontinue the Service (or any part thereof) with or without notice.</p>
            <p>We work with local banks, card providers, postcode verification service providers, identity verification service providers and other third party service providers (each a “Partner Institution”) to process and promptly pay out funds to receivers. We are not responsible for delays which may occur due to availability of our Partner Institutions or variations in business hours. We do not accept any liability for damages resulting from non-payment or delay in payment of a money transfer to a Recipient or failure to perform a Transaction under our service.</p>
            <p>We will generally rely without further enquiry on messages that are transmitted through our Website and actually received by us, unless we have actual notice that any such message is incorrect, unauthorised or forged.</p>
            <p>We will not accept responsibility for any fees or charges you may incur by the use of a particular payment instrument to fund a Transaction including (but are not limited to) unauthorised overdraft fees imposed by banks if you do not have sufficient funds in your bank account or cash advance fees.</p>
            <p>We do not accept any responsibility to you for: viruses caused by third parties; other goods or service which you pay for as a result of advertisement placed on our Website; any losses or delays in transmission of messages arising out of the use of any Internet access service provider or caused by any browser or other software which is not under our control; malfunctions in communications facilities which cannot reasonably be considered to be under our control and that may affect the accuracy or timeliness of messages you send to us; errors on the site or with our Service caused by incomplete or incorrect information provided to us by you or a third party; any unauthorised use or interception of any message or information before it reaches our Website or us; or any unauthorised use of or access to data relating to you or your transactions which is held by us (unless such use or access is caused by our negligence, fraud or failure to comply with laws relating to the protection of your data).</p>
            <p>We have no obligation to you to execute your Transaction if: we are unable to obtain satisfactory evidence of your identity; we have reason to believe that the Transaction instruction is incorrect, unauthorized or forged; you provide us with incorrect or incomplete information or if your Transaction message is not given to us sufficiently in advance to allow for timely provision of the requested transaction; or your card issuer does not authorise your use of your bank card for payment of the Transaction and our related charges; and we do not accept any liability for damages resulting from non-payment or delay in payment of a money transfer to a receiver or failure to perform a transaction under our Service by reason of any of these matters.</p>
            <p>We may refuse to provide you with any of our Service (in whole or in part) to you if in doing so we may breach any applicable law, court order or requirement of any regulatory or government authority, body or agency who has jurisdiction over us, or if we consider such action necessary to protect our interests. However, if we refuse to provide our Service (in whole or in part) for any of the above reasons, we will, if practicable, notify you of our decision unless we are prevented from doing so legal reasons.</p>
            <p>We reserve the right to suspend the operation of our Website or Service in whole or in part, at our sole discretion, if or when we consider it appropriate to do so by reason of any circumstances beyond our control. We undertake that if our Website or Service is disrupted (whether by us, any third party service provider or otherwise) for any reason, we will endeavour to minimise the duration of any disruption. We shall not be liable to you for any loss or liability which may be suffered or incurred by you as a result of any such disruption, even if caused by our negligence (except where any such disruption is caused by our fraud) provided we satisfy this undertaking.</p>
            <p>We reserve the right to change our Service without notice. We may refuse to provide our Service to any person without giving a reason, in particular to prevent fraud, money laundering or terrorist financing or to comply with any applicable law, court order or requirement of any regulatory or government authority.</p>
            <p>We will generally communicate with you through notice on our Website, or by phone or by e-mail.</p>
            <p>We may revise our Terms and Conditions from time to time. We will give you notice of such revisions to our Terms and Conditions by highlighting a readily accessible notice of revision on our website.</p>
            <p>We have the right to transfer, assign or delegate our rights and/or responsibilities (in whole or in part) under our agreement with you to an affiliated company, or any third party, at any time without your prior consent unless such transfer would reduce your rights and/or remedies or increase your responsibilities and/or liabilities under your agreement with us. You may not transfer your rights and/or responsibilities under your agreement with us without our prior written consent.</p>

            <h5 class="content-title">DISCLOSURE OF ACCOUNT INFORMATION TO THIRD PARTIES</h5>
            <p>By accepting these terms you declare and certify the authenticity, accuracy and completeness of the information supplied in the application and/or contract signed, as well as all documentation and photocopies of documents supplied with this application and/or contract. To this end, you authorise us to conduct such checks and measures that may be appropriate before anybody, entity or person, public or private, in order to confirm the truthfulness of the information and/or document supplied.</p>
            <p>By accepting these terms you authorise us to disclose your personal information to third parties including but not limited to Credit Reference Agencies, in order to examine the truthfulness and accuracy of the data supplied. PAYANGEL reserves the right to reject any application for an account; cancel an existing account and/ or cancel any pending transfers without accepting any liability if information you supply turns out to be untrue or inaccurate.</p>

            <h5 class="content-title">SECURITY</h5>
            <p>Your security is important to us and we work hard to ensure that the information you supply to us is secured. We are a safe and convenient way to send money to friends and family that you trust. You will choose an account login email, password and a passcode when registering with us.</p>
            <p>You are responsible for maintaining the confidentiality of your account login, password and passcode (together your “Account Login Details”), and we will hold you fully responsible for all activities that occur under your account login details.</p>
            <p>You agree to immediately notify us of any unauthorized use of your account login details or any other breach of security. Once we are informed that there has been an unauthorized use of your account login details, we will take immediate steps to try and stop the transaction if not already completed but we shall not be liable for any loss or damage arising from your failure to comply with your obligation under these terms and conditions.</p>
            <p>You may have a right against your card issuer to have sums refunded or re-credited to you if fraudulent use is made of your bank card.</p>

            <h5 class="content-title">COMPLAINTS</h5>
            <p class="mb-1">PayAngel is committed to providing excellent service to our customers. In the unlikely event that you are dissatisfied with our service, please Contact us: </p>
            <ul>
                <li>Via our online contact form – “Contact Us”</li>
                <li>Via Email: <span class="text-decoration-underline">customer.exeprience@payangel.com</span></li>
                <li>Via Phone: +44 203 747 6765</li>
                <li>Via Post: Regus House, Victory Way, Admirals Park, Dartford Kent, DA2 6QD. UK</li>
            </ul>

            <h5 class="content-title mb-1">Information required</h5>
            <ul>
                <li>Your full name, address and preferred contact details (including your telephone number and email address)</li>
                <li>Full details about the complaint (including times, dates, forms of communication, reference numbers if available)</li>
                <li>Documentary evidence to support your complaint (if available)</li>
            </ul>
            <p>We will endeavor to provide a response to any outstanding issues within 10 business days of receiving the initial notice.</p>
            <p>If you do not receive our final response after 21 business days or you are unhappy with our final response, you can write to The Financial Ombudsman Service, South Quay Plaza, 183 Marsh Wall, London E14 9SR. Phone: 0845 080 1800; Website: <a class="text-decoration-underline" href="https://www.financial-ombudsman.org.uk/">www.financial-ombudsman.org.uk</a>; Email: <a class="text-decoration-underline" href="mailto:complaint.info@financial-ombudsman.org.uk">complaint.info@financial-ombudsman.org.uk.</a> You should do so within 6 months from the date of the final response.</p>

            <h5 class="content-title">CANCELLATION AND REFUNDS</h5>
            <p>You can call us, if you wish to cancel a transfer and request a refund of the transfer amount. We will require you to provide your transaction reference number or receipt. We aim to process such requests promptly, no longer than 7 business days.</p>
            <p>If you cancel a transfer, we will generally reimburse any payments which you made to us before exercising your right of cancellation, however:</p>
            <ul>
                <li>We will not reimburse the Transaction Fee and / or charges as a reflection of the costs already incurred by us except when the cancellation is due to an error by us.</li>
                <li>We will not reimburse you if we have paid the Transaction amount to the recipient before we get your notice of cancellation.</li>
                <li>If you make any charitable donations and request this to be refunded we will process the refund to the extent that the request is made within 7 business days of the original transaction, provided that the funds have not yet been disbursed to the noted Charity.</li>
            </ul>

            <h5 class="content-title">INTELLECTUAL PROPERTY RIGHT</h5>
            <p>You agree that our Service, our Website and its content including but not limited to text, graphics, links, buttons, logos, and images, as well as all other PAYANGEL copyrights, trademarks and service marks (together the "PAYANGEL Intellectual Property") are exclusively owned by PAYANGEL. You agree not to display, use, copy, or modify the PAYANGEL Intellectual Property in any manner.</p>
            <p>You are authorized solely to view and retain a copy of the pages of our Websites for your own personal, non-commercial use.</p>

            <h5 class="content-title mb-1">You are not allowed to:</h5>
            <ul>
                <li>use any robot, spider, scraper or other automated device to access our service;</li>
                <li>remove or alter any copyright, trademark or other proprietary notice or legend displayed on our Website (or printed pages of our Website); or</li>
                <li>infringe on PAYANGEL's or any third party's copyright, patent, trademark or other intellectual property rights, or rights of publicity or privacy.</li>
            </ul>

            <h5 class="content-title">TERMINATION OF YOUR ACCOUNT/PROFILE</h5>
            <p class="mb-1">We may, without notice (except as required by law) and without liability to you, terminate or suspend your access to our Website at any time, including without limitation, if:</p>
            <ul>
                <li>you attempt to transfer or charge funds from an account that does not belong to you or from an account which does not have sufficient available funds, or has expired, or if you are paying by credit card and there is an insufficient available line of credit;</li>
                <li>your financial institution attempts to charge back a transfer on the basis of a dispute related to a transfer.</li>
                <li>you provide incorrect or false information about yourself, your debit or credit card details or about a Recipient.</li>
                <li>you attempt to tamper, hack, modify or otherwise corrupt the security or functionality of our Website.</li>
                <li>we receive conflicting claims regarding ownership of, or the right to withdraw funds from a debit or credit card account.</li>
                <li>you have breached any term or condition of this Agreement, or any representation or warranty that you make under this Agreement is false.</li>
                <li>we determine that your account/profile is inactive.</li>
                <li>we believe that the transfer may expose us to action from any government or regulator. We may without liability to you, terminate or suspend your access to our Website at any time and for any reason by giving you 30 days’ notice.</li>
            </ul>
            <p>If we close your account/profile for any reason, we may, at our sole discretion, cancel your pending transfer requests.</p>

            <h5 class="content-title">LIMITATION OF OUR LIABILITY</h5>
            <p>We will refund to you any benefit which we receive as a result of any breach of our agreement with you or other wrongdoing (this means that, for example, where a money transfer has failed in such circumstances we will refund to you the principal sum and the service charge).</p>
            <p>You must call us or write to us as soon as you reasonably can if you think a transfer was not made properly or never arrived. We will not refund money to you if you unduly delayed telling us about the problem and in any event told us more than 13 months after sending the money.</p>
            <p>We will not be liable if we break this agreement because of: (a) abnormal and unforeseeable circumstances outside our control where we could not avoid breaking this agreement despite all efforts to the contrary - this may include, for example, delays or failures caused by industrial action, problems with another system or network, mechanical breakdown or data-processing failures; or (b) our obligations under English or European Community law.</p>
            <p>We are not liable to you for more than the transfer amount and Transaction charge. We will not be liable for any incidental, indirect, special or consequential losses or costs you suffer or, as this agreement is made with you as a consumer, any business losses or costs (such as loss of business profits or opportunities).</p>
            <p>Our Remittance Service is for you to send money to a private individual known by you rather than to make a commercial payment that directly or indirectly facilitates the purchase of goods and services from any third party, and you must not use it for those types of transaction – Your Beneficiary for a Money Transfer transaction should be a known, identifiable individual and not a business or commercial enterprise. For payments to commercial entities or business, our Bill Pay Service is the recommended mode for making such payments.</p>
            <p>You must also follow the fraud warnings on the online form. If you ask us to pay someone who turns out to have defrauded you or who fails to meet their obligations to you, we will not be liable as a result.</p>
            <p>A person who is not a party to this agreement shall not have any rights under the Contracts (Rights of Third Parties) Act 1999 or otherwise to enforce this agreement.</p>
            <p>Nothing in this Agreement limits our liability for death, personal injury arising from our negligence, fraud or fraudulent misrepresentation or any other liability that cannot be excluded or limited by English law.</p>
            <p>To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our website or any content therein, whether express or implied.</p>
            <p class="mb-1">We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</p>
            <ul>
                <li>use of, or inability to use, our site; or</li>
                <li>use of or reliance on any content displayed on our site.</li>
            </ul>
            <p class="mb-1">If you are a business user, please note that in particular, we will not be liable for:</p>
            <ul>
                <li>loss of profits, sales, business, or revenue;</li>
                <li>business interruption;</li>
                <li>loss of anticipated savings;</li>
                <li>loss of business opportunity, goodwill or reputation; or</li>
                <li>any indirect or consequential loss or damage.</li>
            </ul>
            <p>We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our site or to your downloading of any content on it, or on any website linked to it.</p>

            <h5 class="content-title">ASSIGNMENT</h5>
            <p>You may not assign this Agreement to any other party. We may assign this Agreement within our group or delegate certain of our rights and responsibilities under this Agreement to third parties without notice to you.</p>

            <h5 class="content-title">THIRD PARTY LINKS AND RESOURCES ON OUR WEBSITE</h5>
            <p>Where our Website contains links to other sites and resources provided by third parties, these links are provided for your information only. We assume no responsibility for the content of such links. Such links should not be interpreted as an endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of them.</p>

            <h5 class="content-title">SEVERABILITY</h5>
            <p>In the event any one or more of the provisions of these Terms and Conditions shall for any reason be held to be invalid, illegal or unenforceable, the remaining provisions shall remain valid and enforceable.</p>
            
            <h5 class="content-title">CHANGES TO TERMS AND CONDITIONS</h5>
            <p>We may change these terms and conditions at any time by notifying you by e-mail or other agreed means. Whenever possible we will notify you at least one month before the change is due to take effect. The change will automatically take effect and you will be taken to have accepted the notified change unless you tell us that you do not agree to the change. In that event, we will treat that notice as notification that you wish to immediately terminate your agreement with us in relation to the use of our service.</p>

            <p><em>This policy was last updated 25 May 2018</em></p>

            <div class="bottom">
                <b-button variant="gradiunt" size="lg" block @click="ok()" :disabled="btnDesabled">OK</b-button> 
            </div>  
        </div>
    </b-modal>
</template>
<script>
export default {
    name:'TermsConditionModal',
    components: { },
    methods:{
        ok(){
            this.$bvModal.hide('terms_condition');
        }
    }
}
</script>