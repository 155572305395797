<template>
    <b-modal id="login_register" title="Login" hide-footer modal-class="modal-center" no-stacking no-close-on-backdrop>
        <div class="modal-body">
            <ValidationObserver v-slot="{ passes }">
                <form class="contact-detail" @submit.prevent="passes(loginOrRegisterFormSubmit)">
                    <div class="login-card card-mobile">
                        <div class="form-group" :class="{'errors': mobileNumberError == false,'success':loginformdata.primaryNumber && mobileNumberError}">
                            <div class="float-label">
                                <label for="primary_number" class="control-label">Mobile Number*</label>
                                <vue-tel-input
                                    class="default-country"
                                    v-model="loginformdata.primaryNumber"
                                    v-bind="mobileNumber"
                                    @validate ="mobileInputValidate"
                                    ref="telInput"
                                    v-on:country-changed="countryChanged"
                                >
                                <!-- @keydown.native="restrictSpecialCharacter($event)" -->
                                <template v-slot:arrow-icon>
                                    <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                </template>
                                </vue-tel-input>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="mobileNumberError == false"></i>
                                <i class="fa fa-check" v-if="mobileNumberError && loginformdata.primaryNumber"></i>
                            </div>
                            <!-- <center><span>Enter phone number without 0 in front</span></center> -->
                        </div>
                        <ValidationProvider name="password" rules="required" v-slot="{ errors}">
                            <div class="form-group set-password" :class="{'errors': errors.length && !loginformdata.passwords,'success':loginformdata.passwords}">
                                <span class="float-label">
                                    <input :type="loginformdata.password_type" class="form-control" id="signin-password" v-model="loginformdata.passwords" placeholder="Password*">
                                    <!-- <input type="password" v-model="loginformdata.passwords" class="form-control" id="signin-password" placeholder="Password*"> -->
                                    <label for="signin-password" class="control-label">Password*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !loginformdata.passwords"></i>
                                    <i class="fa fa-check" v-if="loginformdata.passwords"></i>
                                    <i class="fa fa-eye" :class="loginformdata.password_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword()"></i>
                                </span>
                            </div>
                        </ValidationProvider> 
                        <div class="bottom">
                            <b-button variant="gradiunt" size="lg" block @click="loginOrRegisterFormSubmit()" :disabled="btnDesabled">NEXT</b-button> 
                        </div>  
                    </div> 
                </form>
            </ValidationObserver>
        </div>
    </b-modal>
</template>
<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
import { sha256 } from 'js-sha256';
import parsePhoneNumber from 'libphonenumber-js'
const { VueTelInput } = require('vue-tel-input')
export default {
    name:'LoginModal',
    components: {
        ValidationObserver,
        ValidationProvider,
        VueTelInput
    },data(){
        return{
            loginformdata:{
                primaryNumber:'',
                passwords:'',
                password_type: 'password',
            },
            primaryDropdawnOpen:false,
            btnDesabled:false,
            primaryContactISO:'',

            mobileNumberError:'',
            mobileNumber: {
                mode: "auto", 
                defaultCountry: "GB",
                autoFormat:false,
                dropdownOptions:{
                    showFlags: true,
                    // disabled:true,
                    showDialCodeInSelection:true
                },
                // mode: "international",
                // defaultCountry: "GB",
                // enabledCountryCode: true,
                // enabledFlags: true,
                // name: "mobileNumber",
                // validCharactersOnly:true,
                // inputOptions:{ showDialCode: true, tabindex: 0,id:"primary_number"},
                // dropdownOptions:{
                //     showFlags: true,
                //     showDialCodeInSelection: false,
                //     // disabled:true
                // },   
            },
        }
    },methods:{
        loginOrRegisterFormSubmit(){
            if(typeof(this.mobileNumberError) == 'undefined'){
                this.mobileNumberError = false
            }
            
            if(this.mobileNumberError == true){
                this.btnDesabled = true;
                const primaryNumber = parsePhoneNumber(this.loginformdata.primaryNumber, this.primaryContactISO);
                // console.log(sha256('Testreg1'));
                let loginData = {
                    UserLogin: {
                        RequestHeader: {
                            TransactionID: "ASDF-987-ADFKHJ-A987ASDF-98NKM",
                            ComChannel: "IOS",
                            SerialNo: "00000000001",
                            SessionIdentity: {
                                Username: "iosuser",
                                password: "5f5606f140aa9cafe3c34ce3bd6f2753de91e3ede6fa64cee908f2274655c7cb",
                                ApplicationCode: "APP001"
                            }
                        },
                        RequestBody: {
                            Username : primaryNumber.number, //this.loginformdata.primaryNumber.split(" ").join(""),
                            Password : sha256(this.loginformdata.passwords)                             
                        }
                    }
                }
                // console.log(loginData);
                this.$http.post('https://dev.payangel.com/payangel_mobile_app/Login', loginData).then((res) => {
                    //Perform Success Action
                    let loginResponse = res.data.UserLogin;
                    if(loginResponse){
                        if(loginResponse.ResponseHeader.ResultCode == '00000001') {
                            let customerData = loginResponse.ResponseBody.customer_data;
                            sessionStorage.setItem("customerDetail", JSON.stringify(customerData));

                            let personalInformation = {
                                email: customerData.email,
                                first_name: customerData.first_name,
                                last_name: customerData.last_name,
                                primary_iso_code: customerData.country.toUpperCase(),
                                phone_number: customerData.mobile,
                                country: customerData.country_name,
                                is_joint: false
                            }
                            sessionStorage.setItem("personalInfo", JSON.stringify(personalInformation));

                            this.$http.post(this.$baseurl+'user/users/', personalInformation).then((res) => {
                                //Perform Success Action
                                if(res.data.code == '0051-000') {
                                    sessionStorage.setItem("jwtAccessToken", res.data.access);
                                    sessionStorage.setItem("refreshToken", res.data.refresh);
                                    sessionStorage.setItem("applicationId", res.data.application_id);
                                    sessionStorage.setItem("tokenExpiryTime", res.data.expiry_time);
                                    this.$bvModal.hide('login_register');
                                    this.loginformdata.primaryNumber='';
                                    this.loginformdata.passwords='';

                                    const swalWithBootstrapButtons = this.$swal.mixin({
                                        customClass: {
                                            confirmButton: 'btn btn-primary btn-lg my-3',
                                            cancelButton: 'btn btn-outline-danger btn-lg',
                                        },
                                        buttonsStyling: false
                                    })

                                    swalWithBootstrapButtons.fire({
                                        icon: 'question',
                                        width:550,
                                        title: 'Is this a Joint Account Application?',
                                        showCancelButton: true,
                                        confirmButtonText: 'YES',
                                        cancelButtonText: 'NO',
                                        buttonsStyling: false,
                                        allowOutsideClick: false,
                                        showCloseButton: true
                                    }).then((result) => {
                                        if(result.dismiss == 'close'){
                                            this.$swal.close();
                                        } else {
                                            if (result.isConfirmed) {
                                                this.$bvModal.show('second_applicant_info');
                                            } else {
                                                sessionStorage.setItem("accountType", 'personal');
                                                this.$router.push({ path: '/open-bank-account/account-specification' })
                                            }
                                        }
                                    })
                                    
                                } else {
                                    this.btnDesabled = false;
                                    this.$swal('Error!', res.data.message, 'error', 'Close');
                                }
                            })
                            .catch((error) => {
                                this.btnDesabled = false;
                                console.log(error);
                            });                            
                            // this.$root.$refs.openNewAccount.governmentIdModal();
                        } else if(loginResponse.ResponseHeader.ResultCode == '00000002') {
                            this.btnDesabled = false;
                            this.$swal('Error!', loginResponse.ResponseHeader.ResultDescription, 'error', 'Close');
                        }
                    } else {
                        this.btnDesabled = false;
                        // this.$swal('Error!', 'Incorrect username and password', 'error', 'Close');
                        this.$swal('Error!', res.data.message, 'error', 'Close');
                    }
                })
                .catch((error) => {
                    // error.response.status Check status code
                    console.log('Login',error);
                    this.btnDesabled = false;
                    this.$swal('Error!', error.response.data.message, 'error', 'Close');
                });
            }
        },

        mobileInputValidate(phoneObject){
            this.mobileNumberError = phoneObject.valid
        },

        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },
        
        setPrimaryNumberCountryCode(defaultCountry){
            this.$refs.telInput.choose(defaultCountry.primaryNumberCode)
            this.mobileNumber.defaultCountry = defaultCountry.primaryNumberCode
        },

        showPassword() {
            this.loginformdata.password_type = this.loginformdata.password_type === 'password' ? 'text' : 'password';
        },

        countryChanged(country) {
            this.primaryContactISO = country.iso2;
        },
    }
}
</script>
<style>
.btn-gradiunt {
    background: linear-gradient(103.59deg, #009396 0%, #6cb945 100%);
    transition: 0.5s;
    color: #fff;
}
</style>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>