// initial state
const state = () => ({
    accountDetail: {
      countryName: '',
      bankName: '',
      accountName: '',
      accountInfo : [],
      accountService : [],
      preference : '',
      frequency : ''
    }
})

// getters
const getters = {
  getAccountInfo: state => {
    return state.accountInfo.map((info) => {
      return {
        type: info.type,
        currency: info.currency,
        purpose: info.purpose
      }
    })
  }
}

// actions
const actions = {
  addAccountInfo ({ commit }, userAccountDetails) {
    commit('setAccountInfo', userAccountDetails)
  }
}

// mutations
const mutations = {
  setAccountInfo (state, accountDetails) {
    state.accountDetail.countryName = accountDetails.country,
    state.accountDetail.bankName = accountDetails.bankname,
    state.accountDetail.accountName = accountDetails.accountname,
    state.accountDetail.accountInfo.push(accountDetails.info),
    state.accountDetail.accountService.push(accountDetails.service),
    state.accountDetail.preference = accountDetails.preference,
    state.accountDetail.frequency = accountDetails.frequency
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}