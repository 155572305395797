<template>
    <section class="main-content">
        <div class="banner-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="banner-left">
                            <div class="left-top">
                                <div class="mobile-top">
                                    <h2 v-html="welcome_messages.heading"></h2>
                                    <h4 class="banner-title">{{ welcome_messages.sub_heading }}</h4>
                                    <div class="banner-list-container">
                                        <template v-if="show_banner">
                                            <vue-typed-js :strings="welcome_messages.banner_li" :loop="true" :typeSpeed="10">
                                                <ul class="list-unstyled banner-list typing"></ul>
                                            </vue-typed-js>
                                        </template>
                                    </div>
                                    <ul class="list-unstyled banner-list">
                                        <li v-for="(list,index) in welcome_messages.banner_lists" :key="index">
                                            <p class="mb-0 d-flex align-items-center"><img :src="list.image" alt=""><span v-html="list.innertext"></span></p>
                                        </li>
                                    </ul>
                                </div>
                                <router-link class="btn banner-sign-up" to="/authentication/page-register">{{ welcome_messages.button_text }}</router-link>
                            </div>
                            <div class="left-bot">
                                <div class="d-flex justify-content-between align-items-end">
                                    <div class="partner-slider">
                                        <div class="our-partner-title">
                                            <h5>Our Partners</h5>
                                        </div>                               
                                        <ul class="d-flex flex-wrap our-patner list-unstyled">
                                            <li>
                                                <div class="slider vertical-slider">
                                                    <VueSlickCarousel :speed='2000' v-bind="settings">
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-2.png" alt="partner" />
                                                        </div>
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-1.png" alt="partner" />
                                                        </div>
                                                    </VueSlickCarousel>
                                                </div>
                                            </li>
                                            <li>
                                                <div id="vertical-1" class="slider vertical-slider-2">
                                                    <VueSlickCarousel :speed='3000' v-bind="settings">
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-3.png" alt="partner" />
                                                        </div>
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-4.png" alt="partner" />
                                                        </div>
                                                    </VueSlickCarousel>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="slider vertical-slider-3">
                                                    <VueSlickCarousel :speed='1000' v-bind="settings">
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-5.png" alt="partner" />
                                                        </div>
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-6.png" alt="partner" />
                                                        </div>
                                                    </VueSlickCarousel>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="slider vertical-slider-4">
                                                    <VueSlickCarousel :speed='500' v-bind="settings">
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-7.png" alt="partner" />
                                                        </div>
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-8.png" alt="partner" />
                                                        </div>
                                                    </VueSlickCarousel>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="slider vertical-slider-5">
                                                    <VueSlickCarousel :speed='2000' v-bind="settings">
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-9.png" alt="partner" />
                                                        </div>
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-10.png" alt="partner" />
                                                        </div>
                                                    </VueSlickCarousel>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="slider vertical-slider-6">
                                                    <VueSlickCarousel :speed='1000' v-bind="settings">
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-11.png" alt="partner" />
                                                        </div>
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-12.png" alt="partner" />
                                                        </div>
                                                    </VueSlickCarousel>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="slider vertical-slider-7">
                                                    <VueSlickCarousel :speed='3000' v-bind="settings">
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-13.png" alt="partner" />
                                                        </div>
                                                        <div class="item">
                                                            <img src="@/assets/partner/partner-14.png" alt="partner" />
                                                        </div>
                                                    </VueSlickCarousel>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img :src="welcome_messages.banner_image" class="banner-img" alt="">
        </div>
    </section>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    name:'HomeComponent',
    components: { VueSlickCarousel },
    methods: {
        onLoad: function() {
            var v = this;
            setTimeout(function(){
                v.show_banner = true
            },3000)
        }
    },
    data(){
        return{
            show_banner: false,
            welcome_messages:{
                heading:'Welcome to <span class="text-uppercase">One World</span>',
                sub_heading:'Manage all your business receipts and payments in one place',
                banner_li: [
                    '<li><p class="mb-0 d-flex align-items-center"><span><strong>One </strong> Account</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span><strong>Global </strong> Capability</p></li> <li><p class="mb-0 d-flex align-items-center"> <span><strong>Superior </strong> Technology</span></p></li>'
                ],
                button_text:'Sign up for free in 3 minutes',
                banner_image: require(`@/assets/home_image/banner.svg`),
                standApp_image:  require(`@/assets/home_image/Powered_by_StandApp.svg`),
            },
            settings:{
                "infinite": true,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "vertical": true,
                "verticalSwiping": true,
                "autoplay": true,
                "arrows": false,
                "dots": false,
                "adaptiveHeight": true
            }
        }
    },
    mounted () {
        this.onLoad();
    }
}
</script>
<style scoped>
</style>