<template>
    <header id="header" class="main-header">
        <div class="top-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <ul class="list-unstyled d-flex justify-content-end">
                            <li v-for="(detail,index) in client_details" :key="index">
                                <a href="javascript:void(0);">
                                    <img v-for="(image,index) in detail.images" :key="index" :src="image" width="18" alt=""> 
                                    <span>{{ detail.number }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-menu">
            <div class="container-fluid">
                <b-navbar toggleable="lg" class="bg-light p-0">
                    <b-navbar-brand class="header-name" to="/standapp-home"><img :src="navbar_details.brand_logo" height="58" alt="Payangel Business"></b-navbar-brand>
                    <b-navbar-toggle target="navbarColor01"></b-navbar-toggle>
                    <b-collapse id="navbarColor01" is-nav>
                        <b-nav class="navbar-nav ml-auto">
                            <b-nav-item v-for="(menu,index) in navbar_details.navbar_menus" :key="index">{{ menu }}</b-nav-item>
                            <b-nav-item-dropdown variant="link" class="country-dropdown">
                                <template #button-content>
                                   <span :class="navbar_details.navbar_dropdawn.imageClass"></span>{{ navbar_details.navbar_dropdawn.text_title }}
                                </template>
                                <b-dropdown-item  href="#" v-for="(city,index) in navbar_details.navbar_dropdawn.citys" :key="index" @click="activeCountry(index)" :active="index == navbar_details.navbar_dropdawn.active_index">
                                    <span :class="city.imageClass" alt=""></span>{{ city.name }}
                                </b-dropdown-item>
                            </b-nav-item-dropdown>
                            <b-nav-item-dropdown variant="link" class="lang-dropdown">
                                <template #button-content>
                                  {{ navbar_details.language_dropdawn.text_title }}
                                </template>
                                <b-dropdown-item  href="#" v-for="(city,index) in navbar_details.language_dropdawn.citys" :key="index" @click="activeLang(index)" :active="index == navbar_details.language_dropdawn.active_index">
                                    {{ city.name }}
                                </b-dropdown-item>
                            </b-nav-item-dropdown>
                            <b-nav-form v-if="displayLoginButton">
                                <a href="#" target="_self" class="nav-link btn btn-outline" type="submit">Log in to Personal</a>
                                <router-link to="/standapp-login" class="nav-link btn btn-login">Log in</router-link>
                            </b-nav-form>
                        </b-nav>
                    </b-collapse>
                </b-navbar>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    name: 'StandappHeaderV2Component',
    components: {
    },data() {
        return {
            client_details:[
                {
                    images:[
                        require(`@/assets/home_image/outgoing-call-w.svg`),
                    ],
                    number: '+44 2037-476-765'
                },
                {
                    images:[
                        require(`@/assets/home_image/outgoing-call-w.svg`),
                        require(`@/assets/home_image/whatsapp-call-w.svg`),
                    ],
                    number: '+44 790-806-1086'
                }
            ],

            navbar_details:{
                brand_logo: require(`@/assets/standapp/standapp_main.svg`),
                navbar_menus:[
                    'Contact Us'
                ],
                navbar_dropdawn:{
                    text_title: 'United Kingdom',
                    imageClass: "flag-icon flag-icon-gb fs-20 mr-2",
                    active_index: 1,
                    citys:[
                        {
                            imageClass: "flag-icon flag-icon-ca fs-20 mr-2",
                            name:'Canada'
                        },
                        {
                            imageClass: "flag-icon flag-icon-gb fs-20 mr-2",
                            name:'United Kingdom'
                        }
                    ]
                },

                language_dropdawn:{
                    text_title: 'EN',
                    active_index: 0,
                    citys:[
                        {
                            name:'EN'
                        },
                        {
                            name:'FR'
                        },
                        {
                            name:'ES'
                        }
                    ]
                },
            },
        }
    },methods:{
        activeCountry(index){
            this.navbar_details.navbar_dropdawn.active_index = index;
            this.navbar_details.navbar_dropdawn.text_title = this.navbar_details.navbar_dropdawn.citys[index].name;
            this.navbar_details.navbar_dropdawn.imageClass = this.navbar_details.navbar_dropdawn.citys[index].imageClass;
        },

        activeLang(index){
            this.navbar_details.language_dropdawn.active_index = index;
            this.navbar_details.language_dropdawn.text_title = this.navbar_details.language_dropdawn.citys[index].name;
        }
    },computed:{
        displayLoginButton(){
            let authStandappClass = ['shome'].includes(this.$route.name)
            return authStandappClass
        }
    }
}
</script>

