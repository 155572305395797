<template>
    <div class="right-content mx-auto my-auto w-50 otp-card">
        <div class="header pb-1 text-center">
            <p class="lead px-3">Passcode OTP</p>
        </div>
        <div class="body pt-1">
            <p class="text-center mb-3">Enter the verification code You will receive on your registered email address</p>
            <div class="otp-form">
                <div id="otp" class="d-flex justify-content-center">
                    <div class="form-group m-2"><input class="form-control" type="text" id="first" maxlength="1" onkeypress="return isNumberKey(event)"/></div>
                    <div class="form-group m-2"><input class="form-control" type="text" id="second" maxlength="1" /></div>
                    <div class="form-group m-2"><input class="form-control" type="text" id="third" maxlength="1" /></div>
                    <div class="form-group m-2"><input class="form-control" type="text" id="fourth" maxlength="1" /></div>
                    <div class="form-group m-2"><input class="form-control" type="text" id="fifth" maxlength="1" /></div>
                </div>
            </div>
            <div class="otp-timer text-center" id="otp_timer">
                <router-link to="#" @click.native="passcodeOtp()">Passcode OTP</router-link>
                <p class="mt-3 mb-0"> 
                    You can request a new code if it does not arrive in: 
                    <label class="timer-text">{{ countDown  }}sec(s)</label>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'PasscodeOtpComponent',
    data(){
        return{
            countDown : 60,
            clearTimer:0,
        }
    },methods: {
        redirect_login_page: function() {
           this.$router.push({ path: '/authentication/page-login' })
        },

        //timer counter
        countDownTimer() {
            if(this.countDown > 0) {
               this.clearTimer = setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
        },
        
        //reset timer 
        passcodeOtp(){
            clearTimeout(this.clearTimer);
            this.countDown = 60
            return this.countDownTimer();
        }
    },mounted(){
        let that = this;

        //OTP Input Form Code
        const inputs = document.querySelectorAll('#otp *[id]');
        for (let i = 0; i < inputs.length; i++) {
            inputs[0].focus()
            inputs[i].addEventListener('keydown', function(event) {
                if (event.key === "Backspace") {
                    if(i == 4) {
                        if(inputs[i].value != "") {
                            inputs[i].focus();    
                        } else {
                            inputs[i-1].focus();
                        }
                    } else if (i !== 0){
                        inputs[i-1].focus();
                    }
                }else {
                    if(inputs[i].value != "") {
                        event.preventDefault();
                        return 0;
                    }
                    if (i == inputs.length - 1) {
                        const inputFeilds = document.querySelectorAll("input");
                        setTimeout(function(){
                            const validInputs = Array.from(inputFeilds).filter( input => input.value !== "");
                            if(validInputs.length >= 5){
                                //console.log(validInputs);
                               that.redirect_login_page();
                            }
                        },500);
                    } 
                    // Check for Alphanumeric key press and allow to enter the value if valid
                    if (/^[a-z]$/i.test(event.key) || /^[0-9]$/i.test(event.key)) {
                        inputs[i].value = event.key;
                        if (i !== inputs.length - 1){
                            inputs[i + 1].focus();
                            event.preventDefault();
                        }
                    } 
                }
            });
        }
    },created() {
        //OTP Timer
        this.countDownTimer()
    }
}
</script>
