<template>
    <div class="right-content mx-auto my-auto">
        <div class="header pb-3 text-center">
            <p class="lead">Login to your account</p>
        </div>
        <div class="body">
            <ValidationObserver v-slot="{ passes }">
                <form class="form-auth-small" @submit.prevent="passes(loginSubmit)" id="login">
                    <!-- Email -->
                    <ValidationProvider name="signin_email" rules="required|email" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !loginformdata.signin_email || errors[0],'success':loginformdata.signin_email && !errors[0]}">
                            <span class="float-label">
                                <input type="email" v-model="loginformdata.signin_email" class="form-control" name="email1" id="signin_email" placeholder="Email*">
                                <label for="signin_email" class="control-label">Email*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !loginformdata.signin_email || errors[0] "></i>
                                <i class="fa fa-check" v-if="loginformdata.signin_email && !errors[0]"></i>
                            </span>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="password" rules="required" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !loginformdata.passwords,'success':loginformdata.passwords}">
                            <span class="float-label">
                                <input type="password" v-model="loginformdata.passwords" class="form-control" id="signin-password" placeholder="Password*">
                                <label for="signin-password" class="control-label">Password*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !loginformdata.passwords"></i>
                                <i class="fa fa-check" v-if="loginformdata.passwords"></i>
                            </span>
                        </div>
                    </ValidationProvider>                          
                    <div class="form-group clearfix"><label class="fancy-checkbox element-left"><input type="checkbox"><span>Remember me</span></label></div>
                    <button type="submit" class="btn btn-primary btn-lg btn-block">LOGIN</button>
                    <div class="bottom"><span class="helper-text m-b-10"><i class="fa fa-lock"></i> <router-link to="/authentication/page-forgot-password">Forgot password?</router-link></span><span>Don't have an account? <router-link to="/authentication/page-register">Register</router-link></span></div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
export default {
    name:'LoginV2Component',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        loginSubmit: function() {
           this.$router.push({ path: '/account'})
        },
    },
    data(){
        return{
            loginformdata:{
                signin_email:'',
                passwords:''
            }
        }
    }
}
</script>

