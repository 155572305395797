import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

//layouts file
Vue.component(
  "auth-layout",
  require("@/components/admin/layouts/main_layout/AuthLayout.vue").default,
);
Vue.component(
  "default-layout",
  require("@/components/admin/layouts/main_layout/DefaultLayout.vue").default,
);
Vue.component(
  "standapp-layout",
  require("@/components/admin/layouts/main_layout/Standapp.vue").default,
);
Vue.component(
  "auth-v2-layout",
  require("@/components/admin/layouts/main_layout/AuthV2Layout.vue").default,
);
Vue.component(
  "standapp-v2-layout",
  require("@/components/admin/layouts/main_layout/StandappV2.vue").default,
);
Vue.component(
  "customer-layout",
  require("@/components/admin/layouts/main_layout/CustomerHomeLayout.vue")
    .default,
);

//BootstrapVue
import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

//axios
import axios from "axios";
Vue.prototype.$http = axios;

import IdleVue from "idle-vue";
import store from "./store";

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 300000, // 5 min
  startAtIdle: false,
});

// Declare base url
Vue.prototype.$baseurl = "https://nrba.payangel.com/api/v3/";

const routes = [
  {
    path: "",
    redirect: "/open-bank-account",
  },
  {
    path: "/home",
    name: "home",
    component: require("@/components/admin/home/HomeComponent.vue").default,
    meta: { title: "Home Page", layout: "auth-v2" },
  },
  {
    path: "/terms-and-conditions",
    component:
      require("@/components/admin/cms_frontend/TermsConditionsFrontComponent.vue")
        .default,
    meta: { title: "Terms & Conditions", layout: "auth-v2" },
  },

  //variant 2
  {
    path: "/open-bank-account",
    name: "obavariant2",
    component:
      require("@/components/admin/open_bank_account_v2/OpenAccountVariant2Component.vue")
        .default,
    meta: { title: "Open Bank Account", layout: "auth-v2" },
  },
  {
    path: "/open-bank-account",
    component: require("@/components/admin/uikit/RouterView.vue").default,
    redirect: "/open-bank-account/account-specification",
    children: [
      {
        path: "account-specification",
        component:
          require("@/components/admin/open_bank_account_v2/AccountSpecificationV2Component.vue")
            .default,
        meta: { title: "Account Specification V2", layout: "auth-v2" },
      },
      {
        path: "personal-information",
        component:
          require("@/components/admin/open_bank_account_v2/PersonalInformationV2Component.vue")
            .default,
        meta: { title: "Personal Information V2", layout: "auth-v2" },
      },
      {
        path: "employment",
        component:
          require("@/components/admin/open_bank_account_v2/EmploymentV2Component.vue")
            .default,
        meta: { title: "Employment V2", layout: "auth-v2" },
      },
      {
        path: "contact-details",
        component:
          require("@/components/admin/open_bank_account_v2/ContactDetailsV2Component.vue")
            .default,
        meta: { title: "Contact Details V2", layout: "auth-v2" },
      },
      {
        path: "your-next-of-kin",
        component:
          require("@/components/admin/open_bank_account_v2/NextofKinV2Component.vue")
            .default,
        meta: { title: "Your Next of Kin V2", layout: "auth-v2" },
      },
      {
        path: "upload-passport-size-photo",
        component:
          require("@/components/admin/open_bank_account_v2/PassportPhotoV2Component.vue")
            .default,
        meta: { title: "Upload Passport Size Photo V2", layout: "auth-v2" },
      },
      {
        path: "your-id",
        component:
          require("@/components/admin/open_bank_account_v2/YourIDV2Component.vue")
            .default,
        meta: { title: "YourID V2", layout: "auth-v2" },
      },
      {
        path: "summary",
        component:
          require("@/components/admin/open_bank_account_v2/SummaryV2Component.vue")
            .default,
        meta: { title: "Summary V2", layout: "auth-v2" },
      },
      {
        path: "declarations-and-signatures",
        component:
          require("@/components/admin/open_bank_account_v2/DeclarationsV2Component.vue")
            .default,
        meta: { title: "Declarations and Signatures V2", layout: "auth-v2" },
      },
      {
        path: ":applicationId",
        component:
          require("@/components/admin/open_bank_account_v2/ApplicationIdComponent.vue")
            .default,
        meta: { title: "Application ID", layout: "auth-v2" },
      },
      {
        path: "joint-application/:applicationID",
        component:
          require("@/components/admin/open_bank_account_v2/JointApplicantOtpComponent.vue")
            .default,
        meta: { title: "Joint Application", layout: "auth-v2" },
      },
    ],
  },

  //ui-kit routes
  {
    path: "/uikit",
    component: require("@/components/admin/uikit/RouterView.vue").default,
    meta: { breadCrumb: "Ui Kit" },
    children: [
      {
        path: "index",
        component: require("@/components/admin/uikit/IndexComponent.vue")
          .default,
        meta: { breadCrumb: "Index", title: "Dashboard" },
      },
      {
        path: "iot",
        component: require("@/components/admin/uikit/IotComponent.vue").default,
        meta: { breadCrumb: "Iot Dashboard", title: "Iot Dashboard" },
      },
      {
        path: "app-inbox",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: { breadCrumb: "Inbox" },
        children: [
          {
            path: "",
            component: require("@/components/admin/uikit/InboxComponent.vue")
              .default,
            meta: { title: "Inbox" },
          },
          {
            path: "compose",
            component: require("@/components/admin/uikit/ComposeComponent.vue")
              .default,
            meta: { breadCrumb: "Compose", title: "Compose" },
          },
        ],
      },
      {
        path: "app-chat",
        component: require("@/components/admin/uikit/ChatComponent.vue")
          .default,
        meta: { breadCrumb: "Chat", title: "Chat" },
      },
      {
        path: "app-calendar",
        component: require("@/components/admin/uikit/CalendarComponent.vue")
          .default,
        meta: { breadCrumb: "Calendar", title: "Calendar" },
      },
      {
        path: "app-contact-grid",
        component: require("@/components/admin/uikit/ContactGrideComponent.vue")
          .default,
        meta: { breadCrumb: "Contact Card", title: "Contact Card" },
      },
      {
        path: "file-documents",
        component: require("@/components/admin/uikit/DocumentsComponent.vue")
          .default,
        name: "fdocuments",
        meta: { breadCrumb: "File Documents", title: "File Documents" },
      },
      {
        path: "file-media",
        component: require("@/components/admin/uikit/FileMediaComponent.vue")
          .default,
        name: "fmedia",
        meta: { breadCrumb: "File Media", title: "File Media" },
      },
      {
        path: "sweet-alert",
        component: require("@/components/admin/uikit/SweetAlertComponent.vue")
          .default,
        name: "sweetalert",
        meta: { breadCrumb: "Sweet Alert", title: "Sweet Alert" },
      },
      {
        path: "file-images",
        component: require("@/components/admin/uikit/FileImageComponent.vue")
          .default,
        name: "fimages",
        meta: { breadCrumb: "File Images", title: "File Images" },
      },
      {
        path: "blog-post",
        component: require("@/components/admin/uikit/PostComponent.vue")
          .default,
        name: "blog_post",
        meta: { breadCrumb: "Blog Post", title: "Blog Post" },
      },
      {
        path: "blog-list",
        component: require("@/components/admin/uikit/ListComponent.vue")
          .default,
        name: "blog_list",
        meta: { breadCrumb: "Blog List", title: "Blog List" },
      },
      {
        path: "page-lockscreen",
        component: require("@/components/admin/uikit/LockscreenComponent.vue")
          .default,
        name: "lockscreen",
        meta: { title: "Lockscreen", layout: "auth" },
      },
      {
        path: "page-404",
        component: require("@/components/admin/uikit/FourZeroFourComponent.vue")
          .default,
        name: "404",
        meta: { title: "404 Page", layout: "auth" },
      },
      {
        path: "page-403",
        component:
          require("@/components/admin/uikit/FourZeroThreeComponent.vue")
            .default,
        name: "403",
        meta: { title: "403 Page", layout: "auth" },
      },
      {
        path: "page-500",
        component: require("@/components/admin/uikit/FiveZeroZeroComponent.vue")
          .default,
        name: "500",
        meta: { title: "500 Page", layout: "auth" },
      },
      {
        path: "page-503",
        component:
          require("@/components/admin/uikit/FiveZeroThreeComponent.vue")
            .default,
        name: "503",
        meta: { title: "503 Page", layout: "auth" },
      },
      {
        path: "blog-details",
        component: require("@/components/admin/uikit/DetailsComponent.vue")
          .default,
        name: "blog_details",
        meta: { breadCrumb: "Blog Details", title: "Blog Details" },
      },
      {
        path: "ui-typography",
        component: require("@/components/admin/uikit/TypographyComponent.vue")
          .default,
        name: "typography",
        meta: { breadCrumb: "Typography", title: "Typography" },
      },
      {
        path: "ui-tabs",
        component: require("@/components/admin/uikit/TabsComponent.vue")
          .default,
        meta: { breadCrumb: "Tabs", title: "Tabs" },
      },
      {
        path: "ui-buttons",
        component: require("@/components/admin/uikit/ButtonsComponent.vue")
          .default,
        meta: { breadCrumb: "Buttons", title: "Buttons" },
      },
      {
        path: "ui-bootstrap",
        component: require("@/components/admin/uikit/BootstrapComponent.vue")
          .default,
        meta: { breadCrumb: "Bootstrap UI", title: "Bootstrap UI" },
      },
      {
        path: "ui-icons",
        component: require("@/components/admin/uikit/IconComponent.vue")
          .default,
        meta: { breadCrumb: "Icons", title: "Icons" },
      },
      {
        path: "ui-notifications",
        component: require("@/components/admin/uikit/NotificationComponent.vue")
          .default,
        meta: { breadCrumb: "Notifications", title: "Notifications" },
      },
      {
        path: "ui-colors",
        component: require("@/components/admin/uikit/ColorComponent.vue")
          .default,
        meta: { breadCrumb: "Colors", title: "Colors" },
      },
      {
        path: "ui-listgroup",
        component: require("@/components/admin/uikit/ListGroupComponent.vue")
          .default,
        meta: { breadCrumb: "List Group", title: "List Group" },
      },
      {
        path: "ui-media",
        component: require("@/components/admin/uikit/MediaComponent.vue")
          .default,
        meta: { breadCrumb: "Media Object", title: "Media Object" },
      },
      {
        path: "ui-modals",
        component: require("@/components/admin/uikit/ModalComponent.vue")
          .default,
        meta: { breadCrumb: "Modals", title: "Modals" },
      },
      {
        path: "ui-progressbars",
        component: require("@/components/admin/uikit/ProgressbarComponent.vue")
          .default,
        meta: { breadCrumb: "Progressbar", title: "Progressbar" },
      },
      {
        path: "widgets-data",
        component: require("@/components/admin/uikit/DataComponent.vue")
          .default,
        meta: { breadCrumb: "Widgets Data", title: "Widgets Data" },
      },
      {
        path: "widgets-weather",
        component: require("@/components/admin/uikit/WeatherComponent.vue")
          .default,
        meta: { breadCrumb: "Widgets Weather", title: "Widgets Weather" },
      },
      {
        path: "widgets-blog",
        component: require("@/components/admin/uikit/BlogComponent.vue")
          .default,
        meta: { breadCrumb: "Widgets Blog", title: "Widgets Blog" },
      },
      {
        path: "widgets-ecommerce",
        component: require("@/components/admin/uikit/EcommerceComponent.vue")
          .default,
        meta: { breadCrumb: "Widgets eCommerce", title: "Widgets eCommerce" },
      },
      {
        path: "page-blank",
        component: require("@/components/admin/uikit/BlankComponent.vue")
          .default,
        meta: { breadCrumb: "Page Blank", title: "Page Blank" },
      },
      {
        path: "page-profile",
        component: require("@/components/admin/uikit/ProfileComponent.vue")
          .default,
        meta: { breadCrumb: "User Profile", title: "User Profile" },
      },
      {
        path: "page-profile2",
        component: require("@/components/admin/uikit/ProfiletwoComponent.vue")
          .default,
        meta: { breadCrumb: "User Profile", title: "User Profile v2" },
      },
      {
        path: "page-gallery",
        component: require("@/components/admin/uikit/GalleryComponent.vue")
          .default,
        name: "pgallery",
        meta: { breadCrumb: "Gallery", title: "Gallery" },
      },
      {
        path: "page-timeline",
        component: require("@/components/admin/uikit/TimelineComponent.vue")
          .default,
        name: "ptimeline",
        meta: { breadCrumb: "Timeline", title: "Timeline" },
      },
      //{ path: 'page-pricing', component: PricingComponent, meta: { breadCrumb: "Pricing",title: "Pricing" },},
      {
        path: "page-invoices",
        component: require("@/components/admin/uikit/InvoicesComponent.vue")
          .default,
        meta: { breadCrumb: "Invoices", title: "Invoices" },
      },
      {
        path: "page-search-results",
        component: require("@/components/admin/uikit/SearchComponent.vue")
          .default,
        meta: { breadCrumb: "Search Results", title: "Search Results" },
      },
      {
        path: "page-helper-class",
        component: require("@/components/admin/uikit/HelperClassComponent.vue")
          .default,
        meta: { breadCrumb: "Helper Class", title: "Helper Class" },
      },
      {
        path: "page-teams-board",
        component: require("@/components/admin/uikit/TeamsBoardComponent.vue")
          .default,
        meta: { breadCrumb: "Teams Board", title: "Teams Board" },
      },
      {
        path: "page-projects-list",
        component: require("@/components/admin/uikit/ProjectsListComponent.vue")
          .default,
        meta: { breadCrumb: "Projects List", title: "Projects List" },
      },
      {
        path: "page-maintanance",
        component: require("@/components/admin/uikit/MaintananceComponent.vue")
          .default,
        name: "maintanance",
        meta: { title: "Maintanance" },
      },
      {
        path: "page-testimonials",
        component: require("@/components/admin/uikit/TestimonialsComponent.vue")
          .default,
        meta: { breadCrumb: "Testimonials", title: "Testimonials" },
      },
      {
        path: "page-faq",
        component: require("@/components/admin/uikit/FaqComponent.vue").default,
        meta: { breadCrumb: "FAQs", title: "FAQs" },
      },
      {
        path: "forms-validation",
        component: require("@/components/admin/uikit/ValidationComponent.vue")
          .default,
        meta: { breadCrumb: "Form Validation", title: "Form Validation" },
      },
      {
        path: "forms-basic",
        component: require("@/components/admin/uikit/BasicFormComponent.vue")
          .default,
        meta: {
          breadCrumb: "Basic Form Elements",
          title: "Basic Form Elements",
        },
      },
      {
        path: "table-normal",
        component: require("@/components/admin/uikit/NormalComponent.vue")
          .default,
        meta: { breadCrumb: "Table Normal", title: "Table Normal" },
      },
      {
        path: "chart-echarts",
        component: require("@/components/admin/uikit/EChartsComponent.vue")
          .default,
        meta: { breadCrumb: "E-Charts", title: "E-Charts" },
      },
      {
        path: "map-leaflet",
        component: require("@/components/admin/uikit/LeafletComponent.vue")
          .default,
        meta: { breadCrumb: "Leaflet Map", title: "Leaflet Map" },
      },
    ],
  },

  //Auth routes
  {
    path: "/authentication",
    redirect: "/page-*",
    component: require("@/components/admin/uikit/RouterView.vue").default,
    children: [
      {
        path: "page-login",
        component:
          require("@/components/admin/authentication/LoginComponent.vue")
            .default,
        name: "login",
        meta: { title: "Login", layout: "auth" },
      },
      {
        path: "page-register",
        component:
          require("@/components/admin/authentication/RegisterComponent.vue")
            .default,
        name: "register",
        meta: { title: "Register", layout: "auth" },
      },
      {
        path: "page-personaldetail",
        component:
          require("@/components/admin/authentication/PersonaldetailComponent.vue")
            .default,
        name: "pdetail",
        meta: { title: "Personal Detail", layout: "auth" },
      },
      {
        path: "page-password",
        component:
          require("@/components/admin/authentication/PasswordComponent.vue")
            .default,
        name: "pwd",
        meta: { title: "Password", layout: "auth" },
      },
      {
        path: "page-reset-password",
        component:
          require("@/components/admin/authentication/ResetPasswordComponent.vue")
            .default,
        name: "resetpwd",
        meta: { title: "Reset Password", layout: "auth" },
      },
      {
        path: "page-otp",
        component: require("@/components/admin/authentication/OtpComponent.vue")
          .default,
        name: "otp",
        meta: { title: "OTP", layout: "auth" },
      },
      {
        path: "page-passcode",
        component:
          require("@/components/admin/authentication/PasscodeOtpComponent.vue")
            .default,
        name: "passcode",
        meta: { title: "Passcode OTP", layout: "auth" },
      },
      {
        path: "page-forgot-password",
        component:
          require("@/components/admin/authentication/ForgotPasswordComponent.vue")
            .default,
        name: "fpwd",
        meta: { title: "Forgot Password", layout: "auth" },
      },
      {
        path: "page-recover-password",
        component:
          require("@/components/admin/authentication/RecoverPasswordComponent.vue")
            .default,
        name: "recoverpwd",
        meta: { title: "Recover Password", layout: "auth" },
      },
    ],
  },

  {
    path: "*",
    redirect: "/open-bank-account",
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
  base: "",
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = ":: PayAngel :: " + to.meta.title;
  });
});
Vue.config.productionTip = false;
new Vue({
  router: router,
  store,
  render: (h) => h(App),
}).$mount("#app");
