<template>
    <section class="main-content">
        <div class="banner-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6 col-sm-10">
                        <div class="card register-form-card">
                            <ValidationObserver v-slot="{ passes }">
                              <div class="header pb-1 text-center">
                                  <p class="lead px-3">Recover Password</p>
                              </div>
                              <div class="body">
                                <form class="form-auth-small" @submit.prevent="passes(recoverPasswordSubmit)" id="recover_password">
                                  <ValidationProvider name="password"  rules="required" v-slot="{ errors }" vid="confirmation">
                                      <div class="form-group set-password" :class="{'errors': (passwordformdata.passwords != '' && !errorsDisplay) || errors.length > 0,'success': passwordformdata.passwords && errorsDisplay}">
                                          <span class="float-label">
                                              <input :type="passwordformdata.password_type" @input="password_check" v-model="passwordformdata.passwords" class="form-control" name="password" id="signup-password" placeholder="Password*">
                                              <label for="signup-password" class="control-label">Password*</label>
                                              <div class="line"></div>
                                              <i class="fa fa-times" v-if="(passwordformdata.passwords != ''  && !errorsDisplay) || errors.length > 0"></i>
                                              <i class="fa fa-check" v-if="passwordformdata.passwords && errorsDisplay"></i>
                                              <i class="fa fa-eye" :class="passwordformdata.password_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword('type_1')"></i>
                                          </span>
                                          <div class="password-validation" :class="{'d-none':passwordformdata.passwords && errorsDisplay}">
                                              <p :class="{'error':passwordformdata.passwords.length < 8}"><i :class="passwordformdata.passwords.length > 7 ? 'fa fa-check' : 'fa fa-times'"></i> Longer than 7 characters</p>
                                              <p :class="{'error':!has_uppercase}"><i :class="has_uppercase ? 'fa fa-check' : 'fa fa-times'"></i> Has a capital letter</p>
                                              <p :class="{'error':!has_lowercase}"><i :class="has_lowercase ? 'fa fa-check' : 'fa fa-times'"></i> Has a lowercase letter</p>
                                              <p :class="{'error':!has_number}"><i :class="has_number ? 'fa fa-check' : 'fa fa-times'"></i> Has a number</p>
                                              <p :class="{'error':!has_special}"><i :class="has_special ? 'fa fa-check' : 'fa fa-times'"></i> Has a special character</p>
                                          </div>
                                      </div>
                                  </ValidationProvider>
                                  <ValidationProvider name="confirm_password" rules="required|confirmed:confirmation" v-slot="{ errors}">
                                      <div class="form-group set-password" :class="{'errors': errors.length && !passwordformdata.confirm_passwords || errors[0],'success':passwordformdata.confirm_passwords && !errors[0]}">
                                          <span class="float-label">
                                              <input :type="passwordformdata.confirm_type" v-model="passwordformdata.confirm_passwords" class="form-control" name="confirmation_password" id="confirm_password" placeholder="Confirm Password*">
                                              <label for="confirm_password" class="control-label">Confirm Password*</label>
                                              <div class="line"></div>
                                              <i class="fa fa-times" v-if="errors.length && !passwordformdata.confirm_passwords || errors[0] "></i>
                                              <i class="fa fa-check" v-if="passwordformdata.confirm_passwords && !errors[0]"></i>
                                              <i class="fa fa-eye" :class="passwordformdata.confirm_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword('type_2')"></i>
                                          </span>
                                      </div>
                                  </ValidationProvider>
                                  <b-button type="submit" block variant="primary" size="lg">CONTINUE</b-button>
                                  <div class="bottom">
                                    <span class="helper-text">Know your password? <router-link to="/authentication/page-login">Login</router-link></span>
                                  </div>
                                </form>
                              </div>
                            </ValidationObserver>
                        </div>
                        <div class="body text-center pb-4">
                            <img :src="welcome_messages.standApp_image" class="banner-img-powered" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <img :src="welcome_messages.banner_image" class="banner-img" alt=""> -->
        </div>
    </section>
</template>


<script>
  import { ValidationObserver,ValidationProvider } from "vee-validate";
  export default  {
    name: 'recover-password-component',
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    mounted () {
      this.onLoad();
    },
    data () {
      return {
        show_banner: false,
        welcome_messages:{
            heading:'Welcome to ONE WORLD',
            sub_heading:'The one Account you need for',
            banner_li: [
                '<li><p class="mb-0 d-flex align-items-center"><span>All your payments</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span>All your collections</p></li> <li><p class="mb-0 d-flex align-items-center"> <span>Your invoicing</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span>All the ways your customers pay</span></p></li>'
            ],
            button_text:'Sign up for free in 3 minutes',
            banner_image: require(`@/assets/home_image/banner.svg`),
            standApp_image:  require(`@/assets/home_image/Powered_by_StandApp.svg`),
        },

        passwordformdata: {
            robot: false,
            passwords: '',
            confirm_passwords:'',
            password_type: 'password',
            confirm_type: 'password',
        },
        
        has_number:    false,
        has_lowercase: false,
        has_uppercase: false,
        has_special:   false,
      }
    },
    methods: {
      recoverPasswordSubmit() {
        let recoverPasswordData = {
            newPassword: this.passwordformdata.passwords
        }
        this.$http.post('https://stand-app.com/api/v1/business/auth/recoverpassword', recoverPasswordData, {
          headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("authorizationToken") //`Basic ${token}`
          },
        })
        .then((res) => {
            //Perform Success Action
            // console.log(res);
            if(res.data.code == '009-000') {
                sessionStorage.removeItem('authorizationToken');
                let resMessage = res.data.message//'Your password is successfully updated'
                this.$swal('Success!', resMessage, 'success', 'OK');
                this.$router.push({ path: '/authentication/page-login' })
            } else {
                this.$swal('Error!', res.message, 'error', 'Close');
            }
        })
        .catch((error) => {
            // error.response.status Check status code
            // console.log('forgotPassword',error.response.data.code);
            this.$swal('Error!', error.response.data.message, 'error', 'Close');
        });
      },

      onLoad: function() {
        var v = this;
        setTimeout(function(){
            v.show_banner = true
        },1000)
      },

      showPassword(field_name) {
        if(field_name == 'type_1'){
            this.passwordformdata.password_type = this.passwordformdata.password_type === 'password' ? 'text' : 'password';
        }else if(field_name == 'type_2'){
            this.passwordformdata.confirm_type = this.passwordformdata.confirm_type === 'password' ? 'text' : 'password';
        }
      },

      password_check: function () {
        this.has_number    = /\d/.test(this.passwordformdata.passwords)
        this.has_lowercase = /[a-z]/.test(this.passwordformdata.passwords)
        this.has_uppercase = /[A-Z]/.test(this.passwordformdata.passwords)
        this.has_special   = /[^A-Za-z0-9]/.test(this.passwordformdata.passwords)
        if(this.passwordformdata.passwords.length > 7 && this.has_number && this.has_lowercase &&  this.has_uppercase && this.has_special){
            return this.errorsDisplay = true
        }else{
            return this.errorsDisplay = false
        }
      }

    }
}


</script>

<style>
</style>
