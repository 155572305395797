<template>
    <b-modal id="modal_idle" centered hide-footer hide-header no-stacking no-close-on-backdrop @shown="resetTimer">
        <div class="modal-area">
            <ul class="list-unstyled text-center mb-0">
                <li class="d-flex align-items-center flex-column mb-3">
                    <i class="fa fa-5x fa-warning text-warning mr-3 blink"></i>
                    <h5 class="mb-0">Your session will time out in 2 minutes</h5>
                </li>
                <li>
                    <p class="text-center">
                        For your security, your account will log out if there has been no activity for 2 minutes.
                        Need more time? Select 'Stay connected' to continue managing your account online.
                    </p>
                </li>
                <li class="my-5">
                    <div class="base-timer">
                        <svg class="base-timer__svg" viewBox="0 0 100 100">
                            <g class="base-timer__circle">
                                <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                                <path :stroke-dasharray="circleDasharray" class="base-timer__path-remaining primary" 
                                d="
                                    M 50, 50
                                    m -45, 0
                                    a 45,45 0 1,0 90,0
                                    a 45,45 0 1,0 -90,0
                                "></path>
                            </g>
                        </svg>
                        <strong class="base-timer__label flex-column">{{ formattedTimeLeft }} <small>Sec</small></strong>
                    </div>
                </li>
                <li class="d-flex align-items-center flex-column">
                    <b-button class="btn-lg mb-2 w-50" size="lg" variant="primary" @click="generateToken()">Stay connected</b-button>
                    <b-button class="btn btn-outline-danger mr-1 btn-lg w-50" @click="exitProcess()">End session</b-button>
                </li>
            </ul>
        </div>
    </b-modal>
</template>
<script>
const FULL_DASH_ARRAY = 283;
const TIME_LIMIT = 120;
export default {
    name:'ModalIdle',
    data() {
        return {
            timePassed: 0,
            timerInterval: null
        };
    },methods: {
        exitProcess(){
            if(sessionStorage.getItem('applicationId')) {
                sessionStorage.removeItem('applicationId');
                sessionStorage.removeItem('jwtAccessToken');
                sessionStorage.removeItem('refreshToken');
                sessionStorage.removeItem('tokenExpiryTime');
                sessionStorage.removeItem('customerDetail');
                sessionStorage.removeItem('applicantType');
                sessionStorage.removeItem('personalInfo');
                sessionStorage.removeItem('accountType');
                sessionStorage.removeItem('secondApplicantEmail');
            }
            this.$router.push({path:'/'});
        },

        generateToken() {
            if(sessionStorage.getItem('refreshToken')){
                let refreshTokenData = {
                    refresh:sessionStorage.getItem('refreshToken')
                }
                this.$http.post(this.$baseurl+'auth/token/refresh/', refreshTokenData).then((res) => {
                    //Perform Success Action
                    var expirydate = new Date();
                    expirydate.setMinutes(expirydate.getMinutes() + 58);
                    const expiryDateTime = expirydate.toISOString();

                    this.$bvModal.hide('expiry_token');
                    sessionStorage.setItem("jwtAccessToken", res.data.access);
                    sessionStorage.setItem("tokenExpiryTime", expiryDateTime);
                })
                .catch((error) => {
                    // error.response.status Check status code
                    this.$swal('Error!', error.response.data.message, 'error', 'Close');
                }); 
            }
        },

        onTimesUp() {
            clearInterval(this.timerInterval);
        },

        startTimer() {
            this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
        },

        //reset timer 
        resetTimer() {
            this.startTimer();
        },
    },computed: {
        circleDasharray() {
            return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
        },

        formattedTimeLeft() {
            const timeLeft = this.timeLeft;
            const minutes = Math.floor(timeLeft / 60);
            let seconds = timeLeft % 60;

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }
            
            if( `${minutes}:${seconds}` == '0:00'){
               this.exitProcess()
               this.$bvModal.hide('modal_idle')
            }
            return `${minutes}:${seconds}`;
        },

        timeLeft() {
            return TIME_LIMIT - this.timePassed;
        },

        timeFraction() {
            const rawTimeFraction = this.timeLeft / TIME_LIMIT;
            return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
        }
    },watch: {
        timeLeft(newValue) {
            if (newValue === 0) {
                this.onTimesUp();
            }
        }
    },mounted() {
        if(sessionStorage.getItem("applicationId")){
            this.$bvModal.show('modal_idle')
        }
    }
}
</script>