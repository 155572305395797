<template>
    <b-modal id="first_applicant_info" title="Personal Information" hide-footer modal-class="modal-center" no-stacking no-close-on-backdrop>
        <h6>First Applicant</h6>
        <div class="modal-body">
            <ValidationObserver v-slot="{ passes }">
                <form class="contact-detail" @submit.prevent="passes(personalInfoFormSubmit)" autocomplete="off">
                    <div class="login-card card-mobile">
                        <ValidationProvider name="first_name" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.first_name,'success':accountDetails.first_name}">
                                <span class="float-label">
                                    <input type="text" v-model="accountDetails.first_name" class="form-control" id="first_name" placeholder="First Name*" @input="validateName($event,'First Name','first_name')">
                                    <label for="first_name" class="control-label">First Name*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.first_name"></i>
                                    <i class="fa fa-check" v-if="accountDetails.first_name"></i>
                                </span>
                                <span class="text-danger error-msg" v-if="fieldName == 'First Name'">{{fieldErrorMsg}}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="last_name" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.last_name,'success':accountDetails.last_name}">
                                <span class="float-label">
                                    <input type="text" v-model="accountDetails.last_name" class="form-control" id="last_name" placeholder="Last Name*" @input="validateName($event,'Last Name','last_name')">
                                    <label for="last_name" class="control-label">Last Name*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.last_name"></i>
                                    <i class="fa fa-check" v-if="accountDetails.last_name"></i>
                                </span>
                                <span class="text-danger error-msg" v-if="fieldName == 'Last Name'">{{fieldErrorMsg}}</span>
                            </div>
                        </ValidationProvider>
                        <div class="country-dropdown">
                            <div class="form-group form-select single-multiselect">
                                <span class="float-label">
                                    <label for="Country">Country</label>
                                    <multiselect
                                        placeholder=""
                                        v-model="accountDetails.contact_country" 
                                        :show-labels="false" 
                                        :allow-empty="false"
                                        :options="countrys_options"  
                                        label="name" 
                                        track-by="name"
                                        @select="setPrimaryNumberCountryCode">
                                        <template slot="option" slot-scope="props">
                                            <div class="option-title">
                                                <span class="mr-2" :class="props.option.flagClass"></span>
                                                <span>{{ props.option.name }}</span>
                                            </div>
                                        </template>
                                    </multiselect> 
                                </span>
                            </div>
                        </div>
                        <div class="form-group" :class="{'errors': mobileInputError == false,'success':accountDetails.primaryNumber && mobileInputError}">
                            <div class="float-label">
                                <label for="primary_number" class="control-label">Primary Phone Number*</label>
                                <vue-tel-input
                                    v-model="accountDetails.primaryNumber"
                                    v-bind="primaryMobileNumber"
                                    @open="onPrimaryDropdownOpen(true)"
                                    @close="onPrimaryDropdownOpen(false)"
                                    @validate ="mobileInputValidate"
                                    ref="telInput"
                                    @keydown.native="restrictSpecialCharacter($event)"
                                >
                                <template v-slot:arrow-icon>
                                    <span class="vti__dropdown-arrow">{{ primaryDropdawnOpen ? '▲' : '▼' }}</span>
                                </template>
                                </vue-tel-input>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="mobileInputError == false"></i>
                                <i class="fa fa-check" v-if="mobileInputError && accountDetails.primaryNumber"></i>
                            </div>
                        </div>
                        <ValidationProvider name="contact_email" rules="required|email" v-slot="{ errors}" vid="confirmation">
                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.contact_email || errors[0],'success':accountDetails.contact_email && !errors[0]}">
                                <span class="float-label">
                                    <input type="email" v-model="accountDetails.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Email*" @blue="checkEmail()">
                                    <label for="contact_email" class="control-label">Email*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.contact_email || errors[0] "></i>
                                    <i class="fa fa-check" v-if="accountDetails.contact_email && !errors[0]"></i>
                                </span>
                                <span class="text-danger error-msg" v-if="emailExistMsg != ''">{{emailExistMsg}}</span>
                            </div>
                        </ValidationProvider>
                        <div class="bottom pull-right">
                            <b-button class="btn-mw mt-sm-0 mt-3" variant="primary" type="submit" size="lg" v-if="!isDesabled">Submit</b-button>
                            <b-button class="btn-mw mt-sm-0 mt-3" variant="primary" type="submit" size="lg" v-if="isDesabled" disabled>Please Wait</b-button>
                        </div>
                    </div> 
                </form>
            </ValidationObserver>
        </div>
    </b-modal>
</template>
<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
import Multiselect from 'vue-multiselect'
import parsePhoneNumber from 'libphonenumber-js'
const { VueTelInput } = require('vue-tel-input')
export default {
    name:'PersonalInfoModal',
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        VueTelInput
    },data(){
        return{
            accountDetails:{
                first_name:'',
                last_name:'',
                contact_email:'',
                contact_country:{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",primaryNumberCode:'GB'},
                primaryNumber:'',
                primary_iso_code:""
            },
            primaryDropdawnOpen:false,
            mobileInputError:'',

            fieldName:'',
            fieldErrorMsg:'',
            isDesabled:false,
            emailExistMsg:'',

            primaryMobileNumber: {
                mode: "auto", 
                defaultCountry: "GB",
                autoFormat:false,
                dropdownOptions:{
                    showFlags: true,
                    disabled:true,
                    showDialCodeInSelection:true
                },
            },

            // countrys_options:[],

            countrys_options:[
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round", primaryNumberCode:'GB'},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round", primaryNumberCode:'US'},
                { name: 'Germany', flagClass:"flag-icon flag-icon-de fi-xl fi-round", primaryNumberCode:'DE'},
                { name: 'Italy', flagClass: "flag-icon flag-icon-it fi-xl fi-round", primaryNumberCode:'IT'},
                { name: 'Australia', flagClass: "flag-icon flag-icon-au fi-xl fi-round", primaryNumberCode:'AU'},
                { name: 'Canada', flagClass: "flag-icon flag-icon-ca fi-xl fi-round", primaryNumberCode:'CA'},
                { name: 'Netherlands', flagClass: "flag-icon flag-icon-nl fi-xl fi-round", primaryNumberCode:'NL'},
                { name: 'Spain', flagClass: "flag-icon flag-icon-es fi-xl fi-round", primaryNumberCode:'ES'},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round", primaryNumberCode:'FR'}
            ],
            toggleEmailMobileTitle: 'email',
        }
    },
  methods:{
        checkEmail(){
            if (!this.accountDetails.contact_email) {
              return;
            }

            let jointEmail = {
                email: this.accountDetails.contact_email
            }

            this.$http.post(this.$baseurl+'user/check-email/', jointEmail).then((res) => {
                //Perform Success Action
                if(res.data.code == '0073-000' && res.data.is_exists) {
                    this.isDesabled = true;
                    this.emailExistMsg = res.data.message; //"Please provide unique Email"
                } else {
                    this.isDesabled = false;
                    this.emailExistMsg = '';
                }
            })
            .catch((error) => {
                this.isDesabled = false;
                this.emailExistMsg = '';
                this.$swal('Error!', error.response.data.message, 'error', 'Close');
            });
        },

        personalInfoFormSubmit(){
            if(typeof(this.mobileInputError) == 'undefined'){
                this.mobileInputError = false
            }
            if(this.mobileInputError){
                this.isDesabled = true;
                const phoneNumber = parsePhoneNumber(this.accountDetails.primaryNumber, this.accountDetails.contact_country.primaryNumberCode);
                if(phoneNumber.isValid() === true){
                    let personalInfoData = {
                        first_name:this.accountDetails.first_name,
                        last_name:this.accountDetails.last_name,
                        email:this.accountDetails.contact_email,
                        country:this.accountDetails.contact_country.name,
                        phone_number:phoneNumber.number, //this.accountDetails.primaryNumber,
                        primary_iso_code:this.accountDetails.contact_country.primaryNumberCode,
                    }
                    // console.log(personalInfoData);
                    // sessionStorage.setItem("personalInfo", JSON.stringify(personalInfoData));
                    // this.$bvModal.show('mobile_number_varify');

                    let sendOtpData = {
                        phone_number:phoneNumber.number,
                        first_name:this.accountDetails.first_name
                    }

                    this.$http.post(this.$baseurl+'user/send-otp/', sendOtpData).then((res) => {
                        //Perform Success Action
                        if(res.data.code == '0061-000') {
                            this.isDesabled = false;
                            sessionStorage.setItem("personalInfo", JSON.stringify(personalInfoData));
                            this.$bvModal.show('joint_application_mobile_number_varify');
                        } else {
                            // console.log('elsepart',res);
                            this.isDesabled = false;
                            this.$swal('Error!', res.data.message, 'error', 'Close');
                        }
                    })
                    .catch((error) => {
                        // error.response.status Check status code
                        // console.log('catchpart',error.response.data.message);
                        this.isDesabled = false;
                        this.$swal('Error!', error.response.data.message, 'error', 'Close');
                    }); 
                } else {
                    this.isDesabled = false;
                    this.$swal('Error!', 'Please Provide valid number', 'error', 'Close');
                }                
            }
            
        },

        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },

        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },

        onPrimaryDropdownOpen(primarynumber){
            this.primaryDropdawnOpen = primarynumber
        },

        setPrimaryNumberCountryCode(defaultCountry){
            this.$refs.telInput.choose(defaultCountry.primaryNumberCode)
        },

        validateName(e,fieldType,fieldName) {
            let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
            let charLength = e.target.value.length;
            this.accountDetails[fieldName] = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1); //Capitalize first character
            if(/^[A-Za-z'-]+$/.test(char)) {
                if(charLength >= 2 && charLength <= 35) {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
                    return true; // Match with regex 
                } else {
                    this.fieldName = fieldType;
                    this.fieldErrorMsg = 'Please provide minimum two characters.';
                }
            } else {
                this.fieldName = fieldType;
                this.fieldErrorMsg = 'Please provide valid '+fieldType;
                e.preventDefault(); // If not match, don't add to input text
            }
        },

        async getCountryData() {
            try {
                const response = await this.$http.get("https://stand-app.com/api/v1/countries");
                // JSON responses are automatically parsed.
                if(response.status == '200') {
                    response.data.map((item) => {
                        let countryData = {
                            name: item.countryname, 
                            flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                            primaryNumberCode:item.isocode
                        }
                        this.countrys_options.push(countryData);
                    })
                } else {
                    this.countrys_options.push({ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",primaryNumberCode:'GB'});
                }
                
            } catch (error) {
                this.$swal('Error!', error.responce.message, 'error', 'Close');
            }
        },
    }
    // ,created() {
    //     this.getCountryData();
    // }
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
