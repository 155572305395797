<template>
    <b-modal id="application_id" title="Personal Information" hide-footer modal-class="modal-center" no-stacking no-close-on-backdrop>
        <div class="modal-body">
            <ValidationObserver v-slot="{ passes }">
                <form class="contact-detail" @submit.prevent="passes(personalInfoFormSubmit)" autocomplete="off">
                    <div class="login-card card-mobile">
                        <ValidationProvider name="first_name" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !accountDetails.application_id,'success':accountDetails.application_id}">
                                <span class="float-label">
                                    <input type="text" v-model="accountDetails.application_id" class="form-control" id="application_id" placeholder="Application ID*">
                                    <label for="application_id" class="control-label">Application ID*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !accountDetails.application_id"></i>
                                    <i class="fa fa-check" v-if="accountDetails.application_id"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="bottom">
                            <b-button class="btn-mw mt-sm-0 mt-3" variant="primary" type="submit" size="lg">Submit</b-button>
                        </div>
                    </div> 
                </form>
            </ValidationObserver>
        </div>
    </b-modal>
</template>
<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
export default {
    name:'ApplicationIdModal',
    components: {
        ValidationObserver,
        ValidationProvider
    },data(){
        return{
            accountDetails:{
                application_id:'',
            }
        }
    },methods:{
        personalInfoFormSubmit(){
            console.log('submit');
        }
    }
}
</script>