<template>
     <section class="main-content">
        <div class="cms-content">
            <div class="container">
                <div class="row justify-content-center">
                    <!-- smartwizard -->
                    <bank-account-wizard :wizardTimeLines="wizardTimeLines"/>

                    <div class="col-xl-8 col-lg-10 col-md-12">
                        <ul class="wizard-mobile list-unstyled">
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.activeTimeLines" :key="'active_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link" :class="{'filled':timeline.filledClass,'active':timeline.activeClass}">
                                    <span>{{ timeline.name }}</span>
                                    <i :class="timeline.classList"></i>
                                </router-link>
                            </li>
                            <li class="main-form-area">
                                <form class="summery" @submit.prevent="summarySubmit">
                                    <div class="card card-top-line">
                                        <div class="card-body">
                                            <!-- Page Title -->
                                            <div class="page-title-grp mb-3 d-flex justify-content-between align-items-center">
                                                <h2 class="page-title mb-0">Account Specification</h2>
                                                <div class="icon-grp">
                                                    <router-link to="/open-bank-account/account-specification" class="font-20" @click.native="setsummary()"><i class="fa fa-edit"></i></router-link>
                                                </div>
                                            </div>
                                            <ul class="list-unstyled summary-list">
                                                <li class="row" v-for="(accountdetail,accountIndex) in accountSpecification" :key="accountIndex">
                                                    <label class="col-md-6">{{ accountdetail.key }}</label>
                                                    <p class="col-md-6">{{ accountdetail.value | summaryValueIsEmpty }}</p>
                                                </li>
                                            </ul>
                                        </div>  
                                    </div>    
                                    <div class="card card-top-line">                                         
                                        <div class="card-body">
                                            <!-- Page Title -->
                                            <div class="page-title-grp mb-3 d-flex justify-content-between align-items-center">
                                                <h2 class="page-title mb-0">Account Type</h2>
                                                <div class="icon-grp">
                                                    <router-link to="/open-bank-account/account-specification" class="font-20" @click.native="setsummary()"><i class="fa fa-edit"></i></router-link>
                                                </div>
                                            </div>
                                            <ul class="list-unstyled summary-list">
                                                <li class="row" v-for="(type,typeIndex) in accountType" :key="typeIndex">
                                                    <label class="col-md-6">{{ type.key }}</label>
                                                    <p class="col-md-6">{{ type.value | summaryValueIsEmpty}}</p>
                                                </li>
                                            </ul>                       
                                        </div>
                                    </div>
                                    <div class="card card-top-line">                                         
                                        <div class="card-body">
                                            <!-- Page Title -->
                                            <div class="page-title-grp mb-3 d-flex justify-content-between align-items-center">
                                                <h2 class="page-title mb-0">Personal Information</h2>
                                                <div class="icon-grp">
                                                    <router-link to="/open-bank-account/personal-information" class="font-20" @click.native="setsummary()"><i class="fa fa-edit"></i></router-link>
                                                </div>
                                            </div>
                                            <ul class="list-unstyled summary-list">
                                                <li class="row" v-for="(info,infoIndex) in personalInformation" :key="infoIndex">
                                                    <label class="col-md-6">{{ info.key }}</label>
                                                    <p class="col-md-6">{{ info.value | summaryValueIsEmpty }}</p>
                                                </li>
                                            </ul>                       
                                        </div>
                                    </div>
                                    <div class="card card-top-line">                                         
                                        <div class="card-body">
                                            <!-- Page Title -->
                                            <div class="page-title-grp mb-3 d-flex justify-content-between align-items-center">
                                                <h2 class="page-title mb-0">Place of Birth</h2>
                                                <div class="icon-grp">
                                                    <router-link to="/open-bank-account/personal-information" class="font-20" @click.native="setsummary()"><i class="fa fa-edit"></i></router-link>
                                                </div>
                                            </div>
                                            <ul class="list-unstyled summary-list">
                                                <li class="row" v-for="(birthInfo,birthIndex) in birthPlace" :key="birthIndex">
                                                    <label class="col-md-6">{{ birthInfo.key }}</label>
                                                    <p class="col-md-6">{{ birthInfo.value | summaryValueIsEmpty}}</p>
                                                </li>
                                            </ul>                       
                                        </div>
                                    </div>
                                    <div class="card card-top-line">                                         
                                        <div class="card-body">
                                            <!-- Page Title -->
                                            <div class="page-title-grp mb-3 d-flex justify-content-between align-items-center">
                                                <h2 class="page-title mb-0">Employment</h2>
                                                <div class="icon-grp">
                                                    <router-link to="/open-bank-account/employment" class="font-20" @click.native="setsummary()"><i class="fa fa-edit"></i></router-link>
                                                </div>
                                            </div>
                                            <ul class="list-unstyled summary-list">
                                                <li class="row" v-for="(employment,employmentIndex) in employments" :key="employmentIndex">
                                                    <label class="col-md-6">{{ employment.key }}</label>
                                                    <p class="col-md-6">{{ employment.value | summaryValueIsEmpty}}</p>
                                                </li>
                                            </ul>                       
                                        </div>
                                    </div>
                                    <div class="card card-top-line">                                         
                                        <div class="card-body">
                                            <!-- Page Title -->
                                            <div class="page-title-grp mb-3 d-flex justify-content-between align-items-center">
                                                <h2 class="page-title mb-0">Your Contact Details</h2>
                                                <div class="icon-grp">
                                                    <router-link to="/open-bank-account/contact-details" class="font-20" @click.native="setsummary()"><i class="fa fa-edit"></i></router-link>
                                                </div>
                                            </div>
                                            <ul class="list-unstyled summary-list">
                                                <li class="row" v-for="(contact,contactIndex) in contactDetails" :key="contactIndex">
                                                    <label class="col-md-6">{{ contact.key }}</label>
                                                    <p class="col-md-6">{{ contact.value | summaryValueIsEmpty }}</p>
                                                </li>
                                            </ul>                       
                                        </div>
                                    </div>
                                    <div class="card card-top-line">                                         
                                        <div class="card-body">
                                            <!-- Page Title -->
                                            <div class="page-title-grp mb-3 d-flex justify-content-between align-items-center">
                                                <h2 class="page-title mb-0">Your Next of Kin</h2>
                                                <div class="icon-grp">
                                                    <router-link to="/open-bank-account/your-next-of-kin" class="font-20" @click.native="setsummary()"><i class="fa fa-edit"></i></router-link>
                                                </div>
                                            </div>
                                            <ul class="list-unstyled summary-list">
                                                <li class="row" v-for="(kin,kinIndex) in kinInformation" :key="kinIndex">
                                                    <label class="col-md-6">{{ kin.key }}</label>
                                                    <p class="col-md-6">{{ kin.value | summaryValueIsEmpty}}</p>
                                                </li>
                                            </ul>                       
                                        </div>
                                    </div>
                                    <div class="card card-top-line">                                         
                                        <div class="card-body">
                                            <!-- Page Title -->
                                            <div class="page-title-grp mb-3 d-flex justify-content-between align-items-center">
                                                <h2 class="page-title mb-0">Upload Passport Size Photo</h2>
                                                <div class="icon-grp">
                                                    <router-link to="/open-bank-account/upload-passport-size-photo" class="font-20" @click.native="setsummary()"><i class="fa fa-edit"></i></router-link>
                                                </div>
                                            </div>
                                            <ul class="list-unstyled summary-list">
                                                <li class="row border-bottom-0" v-for="(photoInfo,photoInfoIndex) in passportSizePhoto" :key="photoInfoIndex">
                                                    <label class="col-md-6">{{ photoInfo.key }}</label>
                                                    <p class="col-md-6"><img class="img-fluid" :src="photoInfo.value"></p>
                                                </li>
                                            </ul>                       
                                        </div>
                                    </div>
                                    <div class="card card-top-line">                                         
                                        <div class="card-body">
                                            <!-- Page Title -->
                                            <div class="page-title-grp mb-3 d-flex justify-content-between align-items-center">
                                                <h2 class="page-title mb-0">Your ID</h2>
                                                <div class="icon-grp">
                                                    <router-link to="/open-bank-account/your-id" class="font-20"><i class="fa fa-edit"></i></router-link>
                                                </div>
                                            </div>
                                            <ul class="list-unstyled summary-list">
                                                <li class="row border-bottom-0" v-for="(identification,identificationIndex) in identifications" :key="identificationIndex">
                                                    <label class="col-md-6">{{ identification.key }}</label>
                                                    <p class="col-md-6"><img class="img-fluid" :src="identification.value"></p>
                                                </li>
                                            </ul>  
                                            <div class="d-flex align-items-center justify-content-end flex-column flex-lg-row mt-3">
                                                <div class="d-flex align-items-center justify-content-between flex-column flex-sm-row">
                                                    <router-link to="/open-bank-account/your-id" class="btn btn-outline-danger btn-mw mr-sm-1 mr-0 my-sm-0 my-2 btn-lg">Back</router-link>
                                                    <b-button class="btn-mw" type="submit" variant="primary" size="lg">Next</b-button>
                                                </div>
                                            </div>                     
                                        </div>
                                    </div>
                                </form>
                            </li>
                            <li class="d-lg-none d-flex accordion-link" v-for="(timeline,i) in mobileViewTimeLine.inActiveTimeLines" :key="'inActive_'+i">
                                <router-link class="d-flex justify-content-between align-items-center w-100" :to="timeline.link"><span>{{ timeline.name }}</span>
                                <i :class="timeline.classList"></i></router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- logo footer -->
                <bank-account-logo-footer/>
                <!-- Expiry Token Modal -->
                <expiry-token-modal></expiry-token-modal>
            </div>
        </div>
    </section>
</template>
<script>
const BankAccountLogoFooter = require('@/components/admin/open_bank_account_v1/BankAccountLogoFooter.vue').default
const BankAccountFormWizard = require('@/components/admin/open_bank_account_v2/BankAccountFormWizard.vue').default
const ExpiryTokenModal = require('@/components/admin/open_bank_modal/ExpiryTokenModal.vue').default
import moment from 'moment'
export default {
    name:'SummaryV2Component',
    components: {
        'bank-account-logo-footer':BankAccountLogoFooter,
        'bank-account-wizard':BankAccountFormWizard,
        'expiry-token-modal':ExpiryTokenModal
    },
    data(){
        return{
            accountSpecification:[],
            accountType:[],
            personalInformation:[],
            birthPlace:[],
            employments:[],
            contactDetails:[],
            kinInformation:[],
            passportSizePhoto:[],
            identifications:[],
            timeoutModalShow:false,

            wizardTimeLines:[
                {
                    name:'Account Specification',
                    links:'/open-bank-account/account-specification',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Personal Information',
                    links:'/open-bank-account/personal-information',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Next of Kin',
                    links:'/open-bank-account/your-next-of-kin',
                    filledClass:true,
                    activeClass:false
                },
                {
                    name:'Required Documents',
                    links:'/open-bank-account/upload-passport-size-photo',
                    filledClass:false,
                    activeClass:true
                },
                {
                    name:'Confirm and Sign',
                    links:'/open-bank-account/declarations-and-signatures',
                    filledClass:false,
                    activeClass:false
                }
            ],

            mobileViewTimeLine:{
                activeTimeLines:[
                    {
                        name: 'Account Specification',
                        link: '/open-bank-account/account-specification',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Personal Information',
                        link: '/open-bank-account/personal-information',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Next of Kin',
                        link: '/open-bank-account/your-next-of-kin',
                        classList: 'fa fa-chevron-right',
                        filledClass:true,
                        activeClass:false
                    },
                    {
                        name: 'Required Documents',
                        link:'/open-bank-account/upload-passport-size-photo',
                        classList: 'fa fa-chevron-down',
                        filledClass:false,
                        activeClass:true
                    },
                ],

                inActiveTimeLines:[
                    {
                        name: 'Confirm and Sign',
                        link: '/open-bank-account/declarations-and-signatures',
                        classList: 'fa fa-chevron-right'
                    }
                ]
            }
        }
    },methods:{
        summarySubmit(){
            this.$router.push({path:'/open-bank-account/declarations-and-signatures'});
        },

        setsummary(){
            sessionStorage.setItem("pagename",'Summary');
        },

        async getAllData() {
            this.$http.get(this.$baseurl+'bankAccount/bank-user-information/', { 
                params: {application_id: sessionStorage.getItem("applicationId")}, 
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtAccessToken")
                }
            }).then((res) => {
                //Perform Success Action
                if(res.data.code == '0059-000' && res.data.specification_id != '' && res.data.personal_information.user_info_id != '' && res.data.employment.employment_id != '' && res.data.contact_details.contact_id != '' && res.data.documents.user_proof_id != ''){
                    if(res.data.documents.profile_image == '' && res.data.documents.goverment_id == ''){
                        this.$swal('Warning!', 'Please provide passport photo first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/upload-passport-size-photo'});
                    } else if(res.data.documents.goverment_id == ''){
                        this.$swal('Warning!', 'Please provide id details first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/your-id'});
                    } else {
                        var preferences = '';
                        if(res.data.statement_preferences == 'post'){
                            preferences = 'Post';
                        } else if(res.data.statement_preferences == 'hold'){
                            preferences = 'Hold';
                        } else {
                            preferences = 'Special Delivery';
                        }
                        var frequency = '';
                        if(res.data.statement_frequency == 'monthly'){
                            frequency = 'Monthly';
                        } else if(res.data.statement_frequency == 'yearly'){
                            frequency = 'Yearly';
                        } else {
                            frequency = 'Quarterly';
                        }
                        this.accountSpecification = [
                            {key:'Open Bank Account in',value:res.data.bank_account_in},
                            {key:'Open Bank Account With',value:res.data.bank_account_with},
                            {key:'Services',value:res.data.account_services.join(", ")},
                            {key:'Dispatch mood for correspondence and statements',value:preferences},
                            {key:'Statement frequency',value:frequency},
                        ];
                        var typeofaccount = '';
                        if(res.data.account_category == 'personal'){
                            typeofaccount = 'Personal/individual Account';
                        } else if(res.data.account_category == 'joint'){
                            typeofaccount = 'Joint Account';
                        } else {
                            typeofaccount = 'Business Account';
                        }                    

                        this.accountType = [
                            {key:'Type of account',value:typeofaccount},
                        ];
                        var that = this;
                        res.data.account_details.forEach(element => {
                            that.accountType.push({key:'Account type',value:element.account_type});
                            that.accountType.push({key:'Account Currency',value:element.account_currency});
                            that.accountType.push({key:'Purpose of Account',value:element.purpose_of_account});
                        });

                        let personalInfo = res.data.personal_information;
                        this.personalInformation = [
                            {key:'Title',value:personalInfo.name_title},  
                            {key:'First Name',value:personalInfo.first_name},  
                            {key:'Middle Name',value:personalInfo.middle_name},  
                            {key:'Last Name',value:personalInfo.last_name},  
                            {key:'Previous Name (if applicable)',value:personalInfo.previous_name},  
                            {key:`Mother's Maiden Name`,value:personalInfo.mother_maiden_name},  
                            {key:'Marital Status',value:personalInfo.marital_status},  
                            {key:'Gender',value:personalInfo.gender},  
                        ];
                        this.birthPlace = [
                            {key:'Date of Birth',value:moment(personalInfo.date_of_birth).format('DD MMM YYYY')},
                            {key:'Nationality',value:personalInfo.nationality},
                            {key:'City',value:personalInfo.city},   
                        ];

                        let employmentInfo = res.data.employment;
                        this.employments = [
                            {key:'Occupation',value:employmentInfo.occupation},
                            {key:'Gross Income',value:employmentInfo.currency_type+' '+employmentInfo.gross_income},
                            {key:'Name of Employer',value:employmentInfo.name_of_employer},
                            {key:'Income Period',value:employmentInfo.income_period},
                        ];
                        
                        let contactInfo = res.data.contact_details;
                        let secondaryNumber = '';
                        if(contactInfo.secondary_phone.length > 3) {
                            secondaryNumber = contactInfo.secondary_phone;
                        } else {
                            secondaryNumber = '';
                        }
                        this.contactDetails = [
                            {key:'Email',value:contactInfo.email},
                            {key:'Confirm Email',value:contactInfo.confirm_email},
                            {key:'Country',value:contactInfo.country},
                            {key:'Primary Phone Number',value:contactInfo.primary_phone},
                            {key:'Address',value:contactInfo.address},
                            {key:'City',value:contactInfo.city},
                            {key:'Post Code',value:contactInfo.post_code},
                            {key:'Secondary Phone Number',value:secondaryNumber},
                        ];

                        let nextofkinInfo = res.data.next_of_kin;
                        this.kinInformation = [
                            {key:'First Name',value:nextofkinInfo.first_name},
                            {key:'Last Name',value:nextofkinInfo.last_name},
                            {key:'Email',value:nextofkinInfo.email},
                            {key:'Relationship',value:nextofkinInfo.relationship},
                            {key:'Country',value:nextofkinInfo.country},
                            {key:'City',value:nextofkinInfo.city},
                            {key:'Address',value:nextofkinInfo.address},
                            {key:'Post Code',value:nextofkinInfo.post_code},
                        ];

                        let documents = res.data.documents;
                        this.passportSizePhoto = [
                            {key:'Passport Size Photo',value:'https://nrba.payangel.com'+documents.profile_image},
                        ];

                        this.identifications = [
                            {key:'Document',value:'https://nrba.payangel.com'+documents.goverment_id},
                        ];
                    }
                } else {
                    if(res.data.specification_id == '' && res.data.personal_information.user_info_id == '' && res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '' && res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please complete account specification first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/account-specification'});
                    } else if(res.data.personal_information.user_info_id == '' && res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '' && res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please complete personal information first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/personal-information'});
                    } else if(res.data.employment.employment_id == '' && res.data.contact_details.contact_id == '' && res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please complete employment details first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/employment'});
                    } else if(res.data.contact_details.contact_id == '' && res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please complete contact details first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/contact-details'});
                    } else if(res.data.documents.user_proof_id == '') {
                        this.$swal('Warning!', 'Please provide passport photo first', 'warning', 'Close');
                        this.$router.push({path:'/open-bank-account/upload-passport-size-photo'});
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                // this.apiErrorCode = error.response.data.code;
            })
        },
        
        tokenExpired: function() {
            if(sessionStorage.getItem('tokenExpiryTime')){
                if(!this.timeoutModalShow) {
                    const expiryTime = sessionStorage.getItem('tokenExpiryTime').split('.');
                    const currentDateTime = new Date().toISOString().split('.');
                    if(expiryTime[0] == currentDateTime[0]){
                        this.$bvModal.show('expiry_token');
                        this.timeoutModalShow = true;
                    }
                }   
            }            
        }
    },filters: {
        summaryValueIsEmpty: function (value) {
            return value ? value : '-'
        }
    },created() {
        setInterval(this.tokenExpired, 1000);
        
        // console.log(sessionStorage.getItem("jwtAccessToken"));
        // console.log(sessionStorage.getItem("applicationId"));
        if(sessionStorage.getItem("applicationId")){
            this.getAllData();
        } else {
            this.$swal('Warning!', 'Please start application here', 'warning', 'Close');
            this.$router.push({path:'/'});
        }
    }
}
</script>
