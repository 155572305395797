<template>
    <section class="main-content">
        <div class="cms-content">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-8 col-lg-9 col-md-10 col-sm-12">
                        <div class="card card-top-line">
                            <div class="card-body">
                                <h2 class="page-title">About This Service</h2>
                                <p>Thanks for choosing our Non-Resident Account Opening Service</p>
                                <p>This service allows you to open a bank account in Ghana. The process is seamless and takes less than 5 minutes to complete. Your account will be opened with one of the participating banks below: </p>
                                <ul class="list-unstyled d-flex flex-md-row flex-column my-5">
                                    <li class="mr-0 mr-md-5 mb-5 mb-md-0"><img src="@/assets/bank/bank_cbg.png" alt="cbg bank" height="40"></li>
                                     <li class="mr-0 mr-md-5 mb-5 mb-md-0"><img src="@/assets/bank/bank_fidelity.png" alt="fidelity bank" height="59"></li>
                                    <li><img src="@/assets/bank/bank_access.png" alt="access bank" height="59"></li> 
                                </ul>
                                <div class="step-1-footer d-flex align-items-center justify-content-end flex-column flex-lg-row mt-4">
                                    <b-button class="btn-mw mt-sm-0 mt-3" variant="primary" size="lg">Start Application</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Second Applicant Otp Modal for Joint Account -->
                <second-application-otp></second-application-otp>
                <!-- logo footer -->
                <bank-account-logo-footer/>
            </div>
        </div>
    </section>
</template>
<script>
const SecondApplicantOtpModal = require('@/components/admin/open_bank_modal/SecondApplicantOtpModal.vue').default
export default {
    name:'JointApplicantOtpComponent',
    components:{
        'second-application-otp':SecondApplicantOtpModal
    }
}
</script>