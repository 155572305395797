import Vue from "vue";
import Vuex from "vuex";
import accountDetail from './modules/accountDetail';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        accountDetail,
    },
    // strict: debug,
    // plugins: debug ? [createLogger()] : []
})